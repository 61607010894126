import axios from "axios";
import { getCookie } from "../utils/cookie";
import commonHeaders from "./commonHeaders";

const request = axios.create({
  baseURL: "https://xwpo-c4qw-zxn1.m2.xano.io/",
  headers: {
    ...commonHeaders,
  },
});

request.interceptors.request.use(
  async (config) => {
    const token = getCookie("AUTH-TOKEN");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (err) => {
    console.log("err.response.status1", err);

    return Promise.reject(err);
  }
);

export default request;
