import React, { useContext, useState } from "react";
import { EventContext } from "../../context/EventContext";
import DefaultEventImage from "../../assets/images/Event.png";
import { get } from "lodash";
import { Banner } from "../../components/Banner";

const Section = () => {
  const { event } = useContext(EventContext);
  const banners = get(event, "cover_media", []);

  return <div className="banner-imager-wrapper mt-4"><Banner banners={banners} defaultImage={DefaultEventImage} /></div>;
};

export default Section;
