import React, { useEffect, useState } from "react";
const parse = require("html-react-parser");

const ParseHtml = ({ text }) => {
  const [html, setHtml] = useState(null);

  useEffect(() => {
    try {
      setHtml(parse(text));
    } catch (error) {
      setHtml(text);
    }
  }, [text]);

  return html;
};


export default ParseHtml;