import request from "../config/request";

export const getUserByUsername = (username) => {
  return request(`/api:FzfbHYCg/user/${username}`);
};

export const updateUserName = ({ full_name, token, username, email }, config) => {
  return request.post(
    "/api:Hdw516uD/username/web",
    {
      full_name,
      token,
      username,
      email
    },
    config
  );
};


export const updateEmail = (email) =>{ 
  return request.get('/api:_dz3UmOR/user/update-email', {params: {email}})
}
