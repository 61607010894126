export const AnalyticsConstant = {
  Community: 'Community',
  Profile: 'Profile',
  Event: 'Event',
  Live_Event: 'live event',
  User: 'User',
  NOTIFICATION: 'Notification',
  CREATE_EVENT: 'create event',
  CREATE_COMMUNITY: 'create community',
  INVITE: 'invite',

  CHANNEL: 'channel',
  OPENED: ' opened',
  CLOSED: ' closed',
  TAPPED: ' tapped',
  SIGN_IN_SCREEN: 'signin screen',
  WELCOME_SCREEN: 'welcome screen',
  OTP_SCREEN: 'otp screen',
  SIGNUP_SCREEN: 'signup screen',
  ONBOARDING_INTEREST: 'onboarding interest',
  BROWSE: 'browse',
  BROWSE_VIEW_ALL: 'browse view all',
  COMMUNITY_DETAIL: 'community detail',
  PROFILE_OTHER: 'profile other',
  PROFILE_SELF: 'profile self',
  WEBVIEW: 'webview',
  APP_LAUNCHED: 'app launched',
  JOURNEY_STARTED: 'journey started',
  WELCOME_SLIDER_MOVED: 'welcome slider moved',
  COUNTRYCODE_BOTTOM_SHEET_OPEN: 'country code bottom sheet open',
  COUNTRYCODE_SELECTED: 'country code selected',
  COUNTRYCODE_SEARCHED: 'country code searched',
  VERIFY_MOBILE_NUMBER: 'verify mobile number',
  TERMS_AND_CONDITION_PRESSED: 'terms and condition pressed',
  TERMS_AND_CONDITION_VIEWED: 'terms and condition viewed',
  MOBILE_NUMBER_VALIDATION_FAILED: 'mobile number validation failed',
  MOBILE_NUMBER_VALIDATION_ERROR: 'mobile number validation error',
  OTP_SENT: 'otp sent',
  LOGIN_INITIATED: 'login initiated',
  CONFIRM_OTP: 'confirm otp',
  OTP_VERIFIED: 'otp verified',
  OTP_INCORRECT: 'otp incorrect',
  RESEND_OTP: 'resend otp',
  RESENT_OTP: 'resendt otp',
  RESEND_OTP_FAILED: 'resend otp failed',
  SIGNUP_PROFILE_UPDATE_STARTED: 'signup profile update started',
  SIGNUP_PROFILE_COMPLETED: 'signup profile completed',
  SIGNUP_PROFILE_UPDATE_VALIDATION_ERROR: 'signup profile update validation error',
  SIGNUP_PROFILE_UPDATE_ERROR: 'signup profile update error',
  SIGNUP_PROFILE_IMAGE_PICKER_PRESSED: 'signup profile image picker pressed',
  SIGNUP_PROFILE_IMAGE_UPDATE_ERROR: 'signup profile image update error',
  SIGNUP_PROFILE_IMAGE_UPDATE_STARTED: 'signup profile image update started',
  SIGNUP_PROFILE_IMAGE_UPDATED: 'signup  profile image updated',
  ONBOARDING_COMPLETED: 'onboarding  completed',
  OB_INTEREST_SELECTION_SKIPPED: 'onboarding interest selection skipped',
  OB_INTEREST_SELECTION_CONTINUED: 'onboarding interest selection continued',
  OB_INTEREST_SELECTED: 'onboarding interest selected',
  OB_INTEREST_UNSELECTED: 'onboarding interest unselected',
  OB_INTEREST_SELECTED_ALL: 'onboarding interest selected all',
  OB_INTEREST_UNSELECTED_ALL: 'onboarding interest unselected all',
  OB_INTEREST_UPDATE_ERROR: 'onboarding interest update error',
  OB_INTEREST_UPDATE_COMPLETED: 'onboarding interest update completed',

  ACTIVE_CREATOR: 'active creator',
  ACTIVE_USER: 'active user',

  FEATURED_COMMUNITIES_SCROLLED: 'featured communities scrolled',
  FEATURED_COMMUNITIES_VIEW_ALL_OPENED: 'featured communities view all opened',
  POPULAR_COMMUNITIES_SCROLLED: 'popular communities scrolled',
  POPULAR_COMMUNITIES_VIEW_ALL_OPENED: 'popular communities view all opened',
  FEATURED_EVENT_SCROLLED: 'featured event scrolled',
  UPCOMING_EVENT_SCROLLED: 'upcoming event scrolled',
  FEATURED_EVENT_VIEW_ALL_OPENED: 'featured event view all opened',
  UPCOMING_EVENT_VIEW_ALL_OPENED: 'upcoming event view all opened',
  TRENDING_LIVE_EVENTS_SCROLLED: 'trending live events scrolled',
  TRENDING_LIVE_EVENT_VIEW_ALL_OPENED: 'trending event view all opened',
  TRENDING_CREATORS_SCROLLED: 'trending creators scrolled',
  TRENDING_CREATOR_TAPPED: 'trending creator tapped',
  BROWSE_INTEREST_TAPPED: 'browse interest tapped',
  BROWSE_COLLECTION_TAPPED: 'browse interest tapped',
  SEARCH_INITIATED: 'search initiated',
  SEARCH_CANCEL_BUTTON_PRESSED: 'search cancel button pressed',
  SEARCHED_TEXT: 'searched text',
  SEARCHED_TAB_COMMUNITY_OPENED: 'searched tab community opened',
  SEARCHED_TAB_EVENT_OPENED: 'searched tab event opened',
  SEARCHED_TAB_USER_OPENED: 'searched tab user opened',
  SEARCHED_COMMUNITY_CARD_TAPPED: 'searched tab community tapped',
  SEARCHED_EVENT_CARD_TAPPED: 'searched tab event tapped',
  SEARCHED_USER_CARD_TAPPED: 'searched tab user tapped',
  SEARCHED_COMMUNITY_SCROLLED: 'searched tab community scrolled',
  SEARCHED_EVENT_SCROLLED: 'searched tab event scrolled',
  SEARCHED_USER_SCROLLED: 'searched tab user scrolled',
  COMMUNITY_LIST_SCROLLED: 'community list scrolled',
  COMMUNITY_CARD_TAPPED: 'community card tapped',
  COMMUNITY_CARD_IMPRESSION: 'community impression',
  COMMUNITY_DETAIL_VIEWED: 'community detail viewed',
  COMMUNITY_MENU_REPORT: 'community menu report',
  COMMUNITY_BANNER_SCROLLED: 'community banner scrolled',
  COMMUNITY_BANNER_ADD_TAPPED: 'community banner add tapped',
  COMMUNITY_BANNER_IMAGE_UPLOAD_INITIATED: 'community banner image upload initiated',
  COMMUNITY_BANNER_IMAGE_UPLOAD_SUCCESSFUL: 'community banner image upload successful',
  COMMUNITY_BANNER_IMAGE_UPLOAD_FAILED: 'community banner image upload failed',
  COMMUNITY_SHARE_TAPPED: 'community share tapped',
  COMMUNITY_SHARE_COMPLETED: 'share community completed',
  COMMUNITY_SHARE_CANCELLED: 'share community cancelled',
  COMMUNITY_JOINING_REQUESTED: 'community joining requested',
  COMMUNITY_JOINED: 'community joined',
  COMMUNITY_GO_TO_BROADCAST: 'community go to broadcast',
  COMMUNITY_REQUESTED_SENT: 'community join request sent',
  COMMUNITY_PAYMENT_INITIATED: 'community payment initiated',
  COMMUNITY_PAYMENT_SUCCESSFUL: 'community payment successful',
  COMMUNITY_PAYMENT_FAILED: 'community payment failed',
  COMMUNITY_PAYMENT_RETRY: 'community payment retry',
  COMMUNITY_PAYMENT_CHANGE_PAYMENT_METHOD: 'community payment change payment method',
  COMMUNITY_EVENT_VIEWED: 'community event viewed',
  COMMUNITY_CREATOR_VIEWED: 'community creator viewed',
  COMMUNITY_DESCRIPTION_VIEWED: 'community description viewed',
  COMMUNITY_TAGS_VIEWED: 'community tags viewed',
  COMMUNITY_BANNER_VIEWED: 'community banner viewed',
  COMMUNITY_TERMS_AND_CONDITION_VIEWED: 'community terms and condition viewed',
  COMMUNITY_DETAIL_MENU_TAPPED: 'community detail menu tapped',
  COMMUNITY_MENU_TAPPED: 'community menu tapped',
  COMMUNITY_MENU_VIEW_DETAIL: 'community menu view detail',
  COMMUNITY_MENU_SHARE: 'community menu share',
  COMMUNITY_REPORT_INITIATED: 'community report initiated',
  COMMUNITY_REPORT_SUCCESSFUL: 'community report successful',
  COMMUNITY_REPORT_FAILED: 'community report failed',
  COMMUNITY_MENU_GO_TO_BROADCAST: 'community menu go to broadcast',
  COMMUNITY_MENU_EDIT: 'community menu edit',
  COMMUNITY_MENU_MEMBER: 'community menu manage member',
  COMMUNITY_MENU_ADD_CO_CREATOR: 'community menu add co creator',
  COMMUNITY_MENU_INSIGHTS: 'community menu insights',
  COMMUNITY_MENU_SETTINGS: 'community menu settings',
  COMMUNITY_MENU_SHARE: 'community menu share',
  COMMUNITY_MENU_ENABLE_GROUP_CHAT: 'community menu enable group chat',
  COMMUNITY_MENU_HIDE_MEMBER_LIST: 'community menu hide member list',
  COMMUNITY_MENU_ALLOW_MEMBER_VIEW_PAST_BROADCAST: 'community menu allow member view past broadcast',
  COMMUNITY_MENU_MUTE: 'community menu mute',
  COMMUNITY_MENU_TYPE_CHANGE: 'community menu type change',
  COMMUNITY_MENU_DELETE: 'community menu delete',
  COMMUNITY_DELETE_INITIATED: 'community delete initiated',
  COMMUNITY_DELETE_SUCCESSFUL: 'community delete successful',
  COMMUNITY_DELETE_FAILED: 'community delete failed',
  COMMUNITY_MENU_LEAVE: 'community menu leave',
  COMMUNITY_MENU_LEAVE_REPORT: 'community menu leave and report',
  COMMUNITY_MENU_INSIGHTS_TAPPED: 'community menu insights tapped',
  COMMUNITY_INSIGHTS_OPENED: 'community insights opened ',
  COMMUNITY_INSIGHTS_VIEWED: 'community insights viewed ',
  COMMUNITY_MENU_MANAGE_MEMBERS: 'community manage members',
  COMMUNITY_MENU_MANAGE_MEMBERS_OPENED: 'community manage members opened',
  COMMUNITY_MENU_MANAGE_MEMBERS_ALL_TAB: 'community manage members all tab ',
  COMMUNITY_MENU_MANAGE_MEMBERS_REQUEST_TAB: 'community manage members request tab',
  COMMUNITY_MENU_MANAGE_MEMBERS_REQUEST_ACCEPTED: 'community manage members request accept',
  COMMUNITY_MENU_MANAGE_MEMBERS_REQUEST_DISMISS: 'community manage members request dismiss',
  COMMUNITY_MENU_MANAGE_MEMBER_REMOVE: 'community manage member remove',
  COMMUNITY_MENU_MANAGE_MEMBER_REPORT_REMOVE: 'community manage member report and remove',
  EVENT_CARD_TAPPED: 'event card tapped',
  EVENT_CARD_IMPRESSION: 'event impression',
  EVENT_DETAIL_VIEWED: 'event detail viewed',
  EVENT_CREATOR_VIEWED: 'event creator viewed',
  EVENT_TERMS_AND_CONDITION_VIEWED: 'event terms and condition viewed',
  EVENT_SHARE_TAPPED: 'event share tapped',
  EVENT_SHARE_COMPLETED: 'event share completed',
  EVENT_SHARE_CANCELLED: 'event share cancelled',
  EVENT_VIEW_CALENDAR: 'event view calendar',
  EVENT_SAVE: 'event save',
  EVENT_REMOVE: 'event remove',
  EVENT_JOIN: 'event join',
  EVENT_START: 'event start',
  EVENT_PAYMENT_INITIATED: 'event payment initiated',
  EVENT_PAYMENT_SUCCESSFUL: 'event payment successful',
  EVENT_PAYMENT_FAILED: 'event payment failed',
  EVENT_PAYMENT_RETRY: 'event payment retry',
  EVENT_MENU_VIEW_DETAILS: 'event menu view details',
  EVENT_MENU_JOIN: 'event menu join',
  EVENT_MENU_START: 'event menu start',
  EVENT_START_INITIATED: 'event start initiated',
  EVENT_MENU_JOIN_INITIATED: 'event menu join initiated',
  EVENT_JOIN_INITIATED: 'event join initiated',
  EVENT_MENU_START_INITIATED: 'event menu start initiated',
  EVENT_MENU_RESTART: 'event menu restart',
  EVENT_MENU_SAVE: 'event menu save',
  EVENT_MENU_SHARE: 'event menu share',
  EVENT_MENU_SHARE_INITIATED: 'event menu share initiated',
  EVENT_MENU_SHARE_COMPLETED: 'event menu share completed',
  EVENT_MENU_SHARE_DISMISSED: 'event menu share dismissed',
  EVENT_MENU_NOT_INTERESTED: 'event menu not interested ',
  EVENT_MENU_REPORT_INITIATED: 'event menu report initiated',
  EVENT_MENU_REPORT_COMPLETED: 'event menu report completed',
  EVENT_MENU_REPORT_FAILED: 'event menu report failed',
  EVENT_MENU_REMOVE: 'event menu remove',
  EVENT_MENU_EDIT: 'event menu edit',
  EVENT_EDIT: 'event edit',
  EVENT_MENU_CANCEL_INITIATED: 'event menu cancel initiated',
  EVENT_MENU_CANCEL_COMPLETED: 'event menu cancel completed',
  EVENT_MENU_CANCEL_FAILED: 'event menu cancel failed',
  EVENT_MENU_VIEW_INSIGHTS: 'event menu view insights',
  EVENT_TAB_VIEWED: 'event tab viewed',
  EVENT_LIVE_TAB_VIEWED: 'event live tab viewed',
  EVENT_UPCOMING_TAB_VIEWED: 'event upcoming tab viewed',
  EVENT_PAST_TAB_VIEWED: 'event past tab viewed',
  EVENT_LIVE_TAB_NO_DATA: 'event live tab no data',
  EVENT_UPCOMING_TAB_NO_DATA: 'event upcoming no data',
  EVENT_PAST_TAB_NO_DATA: 'event past no data',
  NOTIFICATIONS_ALL_TAB_VIEWED: 'notifications all tab viewed',
  NOTIFICATIONS_REQUEST_TAB_VIEWED: 'notifications request tab viewed',
  NOTIFICATIONS_ALL_SCROLLED: 'notifications all scrolled',
  NOTIFICATIONS_REQUEST_SCROLLED: 'notifications request scrolled',
  NOTIFICATIONS_REQUEST_POSITIVE_BUTTON_PRESSED: 'notifications request positive button pressed',
  NOTIFICATIONS_REQUEST_NEGATIVE_BUTTON_PRESSED: 'notifications request negative button pressed',
  CHANNEL_VIEW: 'channel viewed',
  CHANNEL_SCROLLED: 'channel scrolled ',
  CHANNEL_CARD_TAPPED: 'channel card tapped',
  CHANNEL_HEADER_TAPPED: 'channel header tapped',
  CHANNEL_BROADCAST_TAB_VIEWED: 'channel broadcast tab viewed',
  CHANNEL_CHAT_TAB_VIEWED: 'channel chat tab viewed',
  CHANNEL_CREATOR_TAB_VIEWED: 'channel creator tab viewed',
  CHANNEL_CHAT_CAMERA_PHOTO_TAPPED: 'channel camera photo tapped ',
  CHANNEL_CHAT_GALLARY_PHOTO_TAPPED: 'channel gallery photo tapped ',
  CHANNEL_CHAT_CAMERA_VIDEO_TAPPED: 'channel camera video tapped ',
  CHANNEL_CHAT_GALLARY_VIDEO_TAPPED: 'channel gallery video tapped ',
  CHANNEL_CHAT_TEXT_SENT: 'channel text sent',
  CHANNEL_CHAT_BOTTOM_SHEET_OPENED: 'channel bottom sheet opened',
  CHANNEL_CHAT_PHOTO_SENT: 'channel photo sent ',
  CHANNEL_CHAT_VIDEO_SENT: 'channel video sent ',
  CHANNEL_CHAT_THREAD_STARTED: 'channel chat thread started',
  PROFILE_SELF_VIEWED: 'profile self viewed',
  PROFILE_OTHER_VIEWED: 'profile other viewed',
  PROFILE_MENU_PRESSED: 'profile menu pressed',
  PROFILE_PURCHASED: 'profile purchases',
  PROFILE_INTERESTS: 'profile interests',
  PROFILE_BECOME_A_CREATOR: 'profile become a creator',
  PROFILE_SELF_PRIVACY: 'profile self privacy',
  PROFILE_SELF_PRIVACY_VIEWED: 'profile self privacy viewed',
  PROFILE_SELF_HELP: 'profile self help',
  PROFILE_SELF_HELP_VIEWED: 'profile self help viewed',
  PROFILE_SELF_SETTINGS: 'profile self settings',
  PROFILE_SELF_LOGOUT: 'profile self logout',
  PROFILE_SELF_LOGOUT_SUCCESSFUL: 'profile self logout successful',
  PROFILE_SELF_LOGOUT_CANCELLED: 'profile self logout cancelled',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_INITIATED: 'profile self payment information form initiated',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_COMPLETED: 'profile self  payment information form completed',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_FAILED: 'profile self  payment information form failed',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_EDIT_INITIATED: 'profile self payment information edit form initiated',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_EDIT_COMPLETED: 'profile self  payment information edit form completed',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_EDIT_FAILED: 'profile self  payment information edit form failed',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_DELETE_INITIATED: 'profile self payment information delete form initiated',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_DELETE_COMPLETED: 'profile self  payment information delete form completed',
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_DELETE_FAILED: 'profile self  payment information delete form failed',
  PROFILE_SELF_PAYMENT_INFORMATION_LIST_OPENED: 'profile self payment information list opened',
  PROFILE_SELF_PAYMENT_WITHDRAW_FORM_INITIATED: 'profile self payment withdraw form initiated',
  PROFILE_SELF_PAYMENT_WITHDRAW_FORM_COMPLETED: 'profile self  payment withdraw form completed',
  PROFILE_SELF_PAYMENT_WITHDRAW_FORM_FAILED: 'profile self  payment withdraw form failed',
  PROFILE_SELF_DASHBOARD_OPENED: 'profile self dashboard opened',
  PROFILE_SELF_EVENT_RECORDING_LIST_OPENED: 'profile self event recording list opened',
  PROFILE_SELF_EVENT_RECORDING_LIST_SCROLLED: 'profile self event recording list scrolled',
  PROFILE_SELF_EVENT_RECORDING_PLAYED: 'profile self event recording played',
  PROFILE_SELF_EVENT_RECORDING_DOWNLOADED: 'profile self event recording downloaded',
  PROFILE_SELF_EVENT_RECORDING_SHARED: 'profile self event recording shared',
  PROFILE_SELF_CREATOR_FORM_INITIATED: 'profile self creator form initiated',
  PROFILE_SELF_CREATOR_FORM_COMPLETED: 'profile self  creator form completed',
  PROFILE_SELF_CREATOR_FORM_FAILED: 'profile self  creator form failed',
  PROFILE_SELF_EDIT_INITIATED: 'profile self edit initiated',
  PROFILE_SELF_EDIT_COMPLETED: 'profile self edit completed',
  PROFILE_SELF_EDIT_FAILED: 'profile self edit failed',
  PROFILE_SELF_DELETE_ACCOUNT_INITIATED: 'profile self delete account initiated',
  PROFILE_SELF_DELETE_ACCOUNT_COMPLETED: 'profile self delete account completed',
  PROFILE_SELF_DELETE_ACCOUNT_FAILED: 'profile self delete account failed',
  PROFILE_SELF_DEACTIVATE_ACCOUNT_INITIATED: 'profile self deactivate account initiated',
  PROFILE_SELF_DEACTIVATE_ACCOUNT_COMPLETED: 'profile self deactivate account completed',
  PROFILE_SELF_DEACTIVATE_ACCOUNT_FAILED: 'profile self deactivate account failed',
  PROFILE_SELF_DEACTIVATE_ACCOUNT_INITIATED: 'profile self deactivate account initiated',
  PROFILE_SELF_DEACTIVATE_ACCOUNT_COMPLETED: 'profile self deactivate account completed',
  PROFILE_SELF_DEACTIVATE_ACCOUNT_FAILED: 'profile self deactivate account failed',
  PROFILE_SELF_CHANGE_NUMBER_INITIATED: 'profile self change number initiated',
  PROFILE_SELF_CHANGE_NUMBER_COMPLETED: 'profile self change number completed',
  PROFILE_SELF_CHANGE_NUMBER_FAILED: 'profile self change number failed',
  PROFILE_SELF_BLOCKED_ACCOUNTS_TAPPED: 'profile self blocked accounts tapped',
  PROFILE_SELF_BLOCKED_ACCOUNTS_VIEWED: 'profile self blocked accounts viewed',
  PROFILE_SELF_BLOCKED_ACCOUNTS_SCROLLED: 'profile self blocked accounts scrolled',
  PROFILE_UNBLOCKED: 'profile unblocked',
  PROFILE_SELF_TURN_OFF_LIMIT_NOTIFICATIONS: 'profile self Turn off limit notifications',
  PROFILE_SELF_TURN_ON_LIMIT_NOTIFICATIONS: 'profile self Turn on limit notifications',
  PROFILE_SELF_TURN_ON_ALL_NOTIFICATIONS: 'profile_self Turn off all notifications',
  PROFILE_SELF_TURN_OFF_ALL_NOTIFICATIONS: 'profile self Turn on all notifications',
  PROFILE_SELF_BLOCKED_ACCOUNTS_VIEWED: 'profile self blocked accounts viewed',
  PROFILE_SELF_BLOCKED_ACCOUNTS_SCROLLED: 'profile self blocked accounts scrolled',
  PROFILE_INTEREST_SELECTION_STARTED: 'profile self interest selection started',
  PROFILE_INTEREST_SELECTION_SKIPPED: 'profile self interest selection skipped',
  PROFILE_INTEREST_SELECTION_CONTINUED: 'profile self interest selection continued',
  PROFILE_INTEREST_SELECTED: 'profile self interest selected',
  PROFILE_INTEREST_UNSELECTED: 'profile self interest unselected',
  PROFILE_INTEREST_SELECTED_ALL: 'profile self interest selected all',
  PROFILE_INTEREST_UNSELECTED_ALL: 'profile self interest unselected all',
  PROFILE_INTEREST_UPDATE_ERROR: 'profile self interest update error',
  PROFILE_INTEREST_UPDATE_COMPLETED: 'profile self interest update completed',
  PROFILE_OTHER_REPORT_INITIATED: 'profile other report initiated',
  PROFILE_OTHER_REPORT_COMPLETED: 'profile other report completed',
  PROFILE_OTHER_REPORT_FAILED: 'profile other report failed',
  PROFILE_OTHER_BLOCK_INITIATED: 'profile other block initiated',
  PROFILE_OTHER_BLOCK_COMPLETED: 'profile other block completed',
  PROFILE_OTHER_BLOCK_FAILED: 'profile other block failed',


  PROFILE_SELF_SHARE_INITIATED: 'profile self share initiated',
  PROFILE_SELF_SHARE_COMPLETED: 'profile self share completed',
  PROFILE_SELF_SHARE_FAILED: 'profile self share failed',

  PROFILE_OTHER_SHARE_INITIATED: 'profile other share initiated',
  PROFILE_OTHER_SHARE_COMPLETED: 'profile other share completed',
  PROFILE_OTHER_SHARE_FAILED: 'profile other share failed',
  PROFILE_SELF_PURCHASES_VIEWED: 'profile self purchased viewed',
  PROFILE_SELF_PURCHASES_SCROLLED: 'profile self purchased scrolled',
  PROFILE_SELF_PURCHASES_DETAIL_VIEWED: 'profile self purchased detail viewed',
  PROFILE_SELF_TRANSACTIONS: 'profile self transactions',
  PROFILE_SELF_SUBSCRIPTIONS: 'profile self subscriptions',
  PROFILE_SELF_TRANSACTIONS_SCROLLED: 'profile self transactions scrolled',
  PROFILE_SELF_SUBSCRIPTIONS_SCROLLED: 'profile self subscriptions scrolled',
  PROFILE_SELF_TRANSACTIONS_DETAIL_VIEWED: 'profile self transactions detail viewed',
  PROFILE_SELF_SUBSCRIPTIONS_DETAIL_VIEWED: 'profile self subscriptions detail viewed',
  PROFILE_COMMUNITY: 'profile community tab',
  PROFILE_EVENT: 'profile event tab',
  PROFILE_ABOUT_TAB: 'profile about tab',
  USER_FOLLOW_INITIATED: 'user follow initiated',
  USER_FOLLOW_FAILED: 'user follow failed',
  USER_FOLLOW_SUCCESSFULLY: 'user followed successfully',
  USER_UNFOLLOW_INITIATED: 'user unfollow initiated',
  USER_UNFOLLOW_FAILED: 'user unfollow failed',
  USER_UNFOLLOW_SUCCESSFULLY: 'user unfollowed successfully',
  //Create constant start
  CREATE_PRESSED: 'create pressed in bottom sheet',
  CREATE_LIVE_PRESSED: 'create live room pressed',
  CREATE_LIVE_INITIATED: 'create live room initiated',
  //CREATE_LIVE_CANCELLED: 'create live room cancelled',

  //CREATE_LIVE_TITLE_UPDATED: 'create live event title updated',
  CREATE_LIVE_ADD_SPEAKER_TAPPED: 'create live event add speaker tapped',
  //CREATE_LIVE_SPEAKDER_ADDED: 'create live event speaker added',
  //CREATE_LIVE_SPEAKER_SELECTION_DONE: 'create live event speaker selection done',
  //CREATE_LIVE_EVENT_TYPE_TAPPED: 'create live event type tapped',
  //CREATE_LIVE_EVENT_TYPE_SELECTED: 'create live event type updated',
  CREATE_LIVE_ROOM_CREATED: 'create live room created',
  CREATE_LIVE_EVENT_FAILED: 'create live event failed',

  //CREATE_LIVE_EVENT_CANCELLED: 'create live event cancelled',

  CREATE_EVENT_PRESSED: 'create event pressed',
  //CREATE_BANNER_SELECTION_START: 'create event banner upload start',
  //CREATE_BANNER_SELECTION_PASSED: 'create event banner upload completed',
  //CREATE_BANNER_SELECTION_FAILED: 'create event banner selectuploadion failed',
  //CREATE_EVENT_SPEAKER_OPENED: 'create event add speaker opened',
  //CREATE_EVENT_ADD_SPEAKER_SCROLLED: 'create event add speaker scrolled',
  //CREATE_EVENT_ADD_SPEAKER_ADDED: 'create event add speaker added',
  //CREATE_EVENT_ADD_SPEAKER_REMOVED: 'create event add speaker removed',
  CREATE_EVENT_CANCELLED: 'create event cancelled',
  CREATE_EVENT_CREATED: 'create event created',
  CREATE_EVENT_FAILED: 'create event failed',

  //CREATE_EVENT_PRICE_CHECKBOX_PRESSED: 'create event price checkbox pressed',
  //CREATE_EVENT_PASSES_CHECKBOX_PRESSED: 'create event passes checkbox pressed',
  CREATE_EVENT_MONETIZATION_UPDATED: 'create event monetization updated',
  CREATE_EVENT_STEP2_START: 'create event step two start',
  CREATE_EVENT_INTEREST_UPDATED: 'create event interest updated',
  CREATE_EVENT_SUITED_FOR_UPDATED: 'create event suited for updated ',
  CREATE_EVENT_LANGUAGE_UPDATED: 'create event language updated',
  CREATE_EVENT_TYPE_UPDATED: 'create event type updated',

  CREATE_COMMUNITY_PRESSED: 'create community pressed',
  CREATE_COMMUNITY_CRAETED: 'create community created',
  CREATE_COMMUNITY_FAILED: 'create community failed',
  CREATE_COMMUNITY_CANCELLED: 'create community cancelled',
  CREATE_COMMUNITY_ADD_CO_CREATOR_OPENED: 'create community add co-creator opened',
  CREATE_COMMUNITY_ADD_CO_CREATOR_SCROLLED: 'create community add co-creator scrolled',
  CREATE_COMMUNITY_ADD_CO_CREATOR_ADDED: 'create community add co-creator added',
  CREATE_COMMUNITY_ADD_CO_CREATOR_REMOVED: 'create community add co-creator removed',
  CREATE_EVENT_SPEAKER_OPENED: 'create event add speaker opened',
  CREATE_EVENT_ADD_SPEAKER_SCROLLED: 'create event add speaker scrolled',
  CREATE_EVENT_ADD_SPEAKER_ADDED: 'create event add speaker added',
  CREATE_EVENT_ADD_SPEAKER_REMOVED: 'create event add speaker removed',

  USER_INVITED_BY_NUMBER: 'user invited by number',

  //CREATE_COMMUNITY_SELECTION_START: 'create community banner upload start',
  //CREATE_COMMUNITY_SELECTION_PASSED: 'create community banner upload completed',
  //CREATE_COMMUNITY_SELECTION_FAILED: 'create community banner selectuploadion failed',
  //CREATE_COMMUNITY_ADD_CO_CREATOR_OPENED: 'create community add co-creator opened',
  //CREATE_COMMUNITY_ADD_CO_CREATOR_SCROLLED: 'create community add co-creator scrolled',
  //CREATE_COMMUNITY_ADD_CO_CREATOR_ADDED: 'create community add co-creator added',
  //CREATE_COMMUNITY_ADD_CO_CREATOR_REMOVED: 'create community add co-creator removed',
  CREATE_COMMUNITY_MONETIZATION_UPDATED: 'create community monetization updated',
  //CREATE_COMMUNITY_PRICE_CHECKBOX_PRESSED: 'create community price checkbox pressed',
  //CREATE_COMMUNITY_PASSES_CHECKBOX_PRESSED: 'create evcommunityent passes checkbox pressed',
  CREATE_COMMUNITY_FREQUENCY: 'create community frequency updated',
  CREATE_COMMUNITY_STEP2_START: 'create community step two start',
  CREATE_COMMUNITY_INTEREST_UPDATED: 'create community interest updated',
  CREATE_COMMUNITY_SUITED_FOR_UPDATED: 'create community suited for updated ',
  CREATE_COMMUNITY_LANGUAGE_UPDATED: 'create community language updated',
  CREATE_COMMUNITY_UPDATED: 'create community type updated',
  ////===============================Room

  // Room Detail
  // {
  // Entity: 'ROOM'
  // Id: `${AnalyticsConstant.ROOM}:${data?.id}`,
  // Name: data?.title,
  // Type: data?.type,
  // CurrentUserIdentity: userData?.id,
  // CurrentUserName: userData?.full_name,
  // CurrentRole: SPEAKER/MODE
  // }
  ROOM_START_INITIATED: 'room start initiated',
  ROOM_JOIN_INITIATED: 'room join initiated',
  ROOM_STARTED: 'room started',
  ROOM_JOINED: 'room joined',
  ROOM_START_FAILED_ERROR: 'room start failed', //  ErrorMessage: error?.response?.data?.message
  ROOM_JOIN_FAILED: 'room join failed',
  ROOM_ALLOW_HAND_RAISE: 'room allow hand raise', // AudioOutput: 'speaker/headphone/slient'

  ROOM_HAND_RAISED: 'room hand raised',
  ROOM_HAND_DOWN: 'room hand down',
  ROOM_HAND_ACCEPTED: 'room raised hand accepted',


  ROOM_BOTTOM_SHEET_OPENED: 'room bottom sheet opened',
  ROOM_CHAT_TAPPED: 'room chat tapped',
  ROOM_CHAT_WINDOW_CLOSED: 'room chat window closed',
  ROOM_CHAT_WINDOW_OPEN: 'room chat window open',
  ROOM_DESCRIPTION_TAPPED: 'room description tapped',
  ROOM_DESCRIPTION_VIEWED: 'room description viewed',
  ROOM_SHARE_EVENT_TAPPED: 'room share event tapped',

  ROOM_SHARE_COMPLETED: 'room share completed',
  ROOM_SHARE_CANCELLED: 'room share cancelled',

  ROOM_REPORT_AND_REMOVE_TAPPED: 'room report and leave tapped',
  ROOM_REPORTED_AND_REMOVE: 'room reported and remove',
  ROOM_SELF_REPORT_AND_LEAVE_TAPPED: 'room self report and leave tapped',
  ROOM_SELF_REPORTED_AND_LEFT: 'room self reported and left',
  ROOM_LEAVE_TAPPED: 'room leave tapped',
  ROOM_LEAVE_LEFT: 'room leave left',
  ROOM_SELF_LEAVE_TAPPED: 'room self leave tapped',
  ROOM_SELF_LEAVE_LEFT: 'room self left',
  ROOM_ENDED_TAPPED: 'room ended tapped',
  ROOM_ENDED: 'room ended',
  ROOM_CHAT_SENT: 'room chat sent',
  ROOM_AUDIO_STATUS: 'room audio output switch', // AudioOutput: 'speaker/headphone/slient'
  ROOM_PROFILE_VISIT: "room profile visit",
  ROOM_MIC_STATUS: 'room mic status',
  ROOM_VIDEO_STATUS: 'room video status',
  
  ROOM_INVITE_TO_LIVE_EVENT: 'room invite to live event',
  ROOM_INVITE_TO_LIVE_EVENT_FAILED: "room invite to live event fail",
  ROOM_INVITE_TO_STAGE_REQUEST: 'room invite to stage request',
  ROOM_INVITE_TO_STAGE_REQUEST_ACCEPTED: 'room invite to stage request accepted',
  ROOM_INVITE_TO_STAGE_REQUEST_REJECTED: 'room invite to stage request rejected',
  ROOM_FULL_SCREEN: 'room full screen',
  ROOM_MINIMISE_SCREEN: 'room minimize screen',
  ROOM_INVITE_TO_BECOME_MODERATOR_REQUEST: 'room invite to become moderator request',
  ROOM_INVITE_TO_BECOME_MODERATOR_REQUEST_ACCEPTED: 'room invite to become moderator request accepted',
  ROOM_INVITE_TO_BECOME_MODERATOR_REQUEST_REJECTED: 'room invite to become moderator request rejected',
  ROOM_SPOTLIGHT: 'room spotlight',
  ROOM_SPOTLIGHT_REMOVE: 'room spotlight remove',
  ROOM_PIN: "room pin",
  ROOM_PIN_REMOVE: "room pin remove",
  ROOM_RECORDING_ON: "room recording on",
  ROOM_RECORDING_OFF: "room recording off"
};