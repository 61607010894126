import clevertap from "clevertap-web-sdk";
import { isArray } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import { CommunityInterest } from "../../components/CommunityInterest";
import { CreatorCard } from "../../components/CreatorCard";
import DownloadAppBanner from "../../components/DownloadAppBanner";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import OpenInApp from "../../components/OpenInApp";
import { TermConditions } from "../../components/TermConditions";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";
import AuthContext from "../../context/AuthContext";
import { CommunityContext } from "../../context/CommunityContext";
import { EventListContainer } from "../ProfilePage/EventTab";
import BuyCommunityMembership from "./BuyCommunityMembership";
import CommunityBanner from "./CommunityBanner";
import CommunityDescription from "./CommunityDescription";

const CommunityPage = () => {
  const { community, fetchCommunity } = useContext(CommunityContext);
  const [haveEvent, setHaveEvent] = useState(false);
  const { authUser, requesting } = useContext(AuthContext);

  useEffect(() => {
    if (community?.id && !requesting) {
      clevertap.event.push(AnalyticsConstant.COMMUNITY_DETAIL_VIEWED, {
        screen: "COMMUNITY", //need_to_discuss
        CommunityId: community?.id,
        Interest: community?.interest
          ? JSON.stringify(community?.interest)
          : "",
        CommunityName: community?.title,
        CommunityType: community?.type,
        IsPaid: community?.is_paid,
        IsMember: community?.is_member,
        Price: community?.price,
        CurrentUserIdentity: authUser?.id,
        CurrentUserName: authUser?.full_name,
      });
    }
  }, [
    community?.id,
    community?.type,
    community?.is_paid,
    community?.is_member,
    community?.price,
    community?.title,
    authUser?.id,
    authUser?.full_name,
    requesting,
  ]);

  return (
    <React.Fragment>
      <DownloadAppBanner />

      <Navbar
        OpenInAppComponent={
          community?.id ? (
            <OpenInApp
              screenName="community"
              id={community?.id}
              title={community?.title}
            />
          ) : null
        }
      />
      <section className="home-contain pb-5 mt-4">
        <Container>
          {/* <SubscriptionStatus /> */}
          <Row>
            <Col lg={8} md={7}>
              <div className="banner-box">
                <CommunityBanner />
                <h4 className="home-title mb-0 mt-3 mt-md-4 fw-normal text-lowercase">
                  {community?.title}
                </h4>
                <CommunityInterest interest={community.interest} />
                <CommunityDescription />
                <div className={`mt-4 pt-2 ${!haveEvent ? "d-none" : ""}`}>
                  <p className="home-heading">Events</p>
                  <EventListContainer
                    name={`event_list_of_${community.id}`}
                    action="api:ih5W24rc/community-events"
                    initialValues={{ community_id: community?.id }}
                    callback={(data) => {
                      // console.log("IamLoaded...", data);
                      setHaveEvent(!!data.length);
                    }}
                  />
                </div>
                <div className="mt-4 pt-2">
                  <p className="home-heading text-lowercase">Creators</p>
                  {isArray(community?.moderators) &&
                    community?.moderators
                      .filter((u) => u.username)
                      .map((creator, index) => (
                        <CreatorCard
                          key={`creator_${creator.user_id}`}
                          author={creator}
                          isLast={index === community.moderators.length - 1}
                        />
                      ))}
                </div>
                 
                <TermConditions conditionFor="community" />
              </div>
            </Col>
            <Col lg={4} md={5}>
              <BuyCommunityMembership />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer
        showFooterBottomBar={true}
        community={community}
        fetchCommunity={fetchCommunity}
      />
    </React.Fragment>
  );
};

export default CommunityPage;
