import React, { useCallback } from "react";
import CountryCodeModal from "../../pages/ModelsPage/CountryCodeModal";
import { AppModal } from "../modal/app-modal-utils";
import { AppReactSelect } from "./AppSelectField";
import { AppInputGroup } from "./Fields";
import { AppNumberField } from "./NumberField";
import { useField, useFormikContext } from "formik";
import "./PhoneNumber.scss";
import clevertap from "clevertap-web-sdk";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";

export const AppPhoneNumberField = ({ name, ...restProps }) => {
  return (
    <AppInputGroup
      mainClass="phone_number_group"
      inputAs={AppNumberField}
      prepend={<CountryCodeModalBtn name={`${name}.code`} />}
      name={`${name}.number`}
      {...restProps}
    />
  );
};

const CountryCodeModalBtn = ({
  name,
  disabled,
  shouldDisableOnSubmitting = true,
}) => {
  const [input] = useField(name);
  const { isSubmitting } = useFormikContext();

  const handeCountryCode = useCallback(() => {
    clevertap.event.push(AnalyticsConstant.COUNTRYCODE_BOTTOM_SHEET_OPEN);
    AppModal.open(CountryCodeModal, {
      className: "flex-column justify-content-center",
      centered: true,
    }).then(({ item, searchText }) => {
      if (item.dial_code) {
        clevertap.event.push(AnalyticsConstant.COUNTRYCODE_SELECTED, {
          ...item,
          text: searchText,
          screen: "SignInScreen",
        });
      }
      input.onChange({ currentTarget: { value: item.dial_code, name } });
    });
  }, []);

  return (
    <select
      className="form-select modal-selact"
      data-bs-toggle="modal"
      disabled={disabled || (isSubmitting && shouldDisableOnSubmitting)}
      data-bs-target="#conditions-search"
      aria-label="Default"
      onClick={handeCountryCode}
    >
      <option hidden defaultValue>
        {input.value}
      </option>
    </select>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    // none of react-select's styles are passed to <Control />
    borderWidth: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    // backgroundColor: 'red'
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => {
    return { ...provided, width: "200px" };
  },
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
};
