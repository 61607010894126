import React from "react";
import { Link } from "react-router-dom";
import RoundedImage from "./RoundedImage";
import badge from "../assets/images/badge-sm.png";

const UserListCicle = ({
  users,
  max = 5,
  showMoreUserHint,
  radius,
  className,
}) => {
  const _users = users?.filter((u) => u.username);
  const maxUsers = _users ? [..._users].splice(0, max) : [];

  const haveMoreSpeakers = users && users.length > 5 ? users.length - 5 : false;

  return (
    <div
      className={`avatar-group align-items-center ${
        className ? className : ""
      }`}
    >
      {maxUsers.map((speaker) => (
        <div
          className="avatar-group-item"
          key={speaker.id}
          // style={{ height: `${radius}px` }}
        >
          <Link
            to={`/${speaker.username}`}
            className="d-inline-block"
            onClick={(e) => e.stopPropagation()}
          >
            <RoundedImage
              title={speaker.full_name}
              radius={radius}
              imageUrl={speaker.image_url}
              isVerified={speaker.is_verified}
              initial="dark"
            />

            {speaker.is_verified ? (
              <div className="verified-icon">
                <img src={badge} className="img-fluid icon-sm" alt="" />
              </div>
            ) : null}
          </Link>
        </div>
      ))}
      {showMoreUserHint && haveMoreSpeakers ? (
        <div className="avatar-group-item ps-3">
          <Link to="#" className="text-white border-0 p-1 home-desc">
            +{haveMoreSpeakers} More
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export const SpeakerNames = ({ speakers }) => {
  return speakers?.map((speaker, index) => (
    <span key={speaker.id}>
      {index > 0 && index === speakers.length - 1 ? "and " : null}
      {speaker.full_name}
      {index + 2 < speakers.length ? ", " : " "}
    </span>
  ));
};

export default UserListCicle;
