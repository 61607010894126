import React from "react";
import music from "../assets/images/icon/music.png";
import hindi from "../assets/images/icon/hindi.png";
import kids from "../assets/images/icon/kids.png";
import { useContext } from "react";
import MasterDataContext from "../context/MasterData/MasterDataContext";
import { isArray } from "lodash";

export const CommunityInterest = ({ interest }) => {
  return (
    <div className="mt-2 mt-md-3">
      {interest?.map((ins) => (
        <span
          key={`interest_${ins.id}`}
          className="badge bg-light me-3 mt-1 mb-1"
        >
          <span className="emoji">{ins.emoji}</span>
          {ins.name}
        </span>
      ))}
    </div>
  );
};

export const InterestListOnCard = ({ interest }) => {
  return (
    <div className="mt-3">
      {interest?.map((ins) => (
        <span
          key={`interest_${ins.id}`}
          className="badge bg-dark me-2 mt-1 mb-1 interest_tag"
        >
          <span className="emoji">{ins.emoji}</span>
          {ins.name}
        </span>
      ))}
    </div>
  );
};

export const InterestTags = ({
  interests,
  childClassName = "badge bg-dark me-2",
}) => {
  return (
    isArray(interests) &&
    interests?.map((ins) => (
      <span
        key={`interest_${ins.id}`}
        className={`${childClassName} interest_tag`}
      >
        <span className="emoji">{ins.emoji}</span>
        {ins.name}
      </span>
    ))
  );
};

export const BestSuitedFor = ({
  bestSuitedFor,
  className = "mt-4",
  childClassName = "badge bg-dark me-2",
}) => {
  const { bestSuitedIcons } = useContext(MasterDataContext);
  const dataObj = bestSuitedFor ? bestSuitedIcons[bestSuitedFor] : null;
  return dataObj ? (
    <div className={className}>
      <BestSuitedForTag
        childClassName={childClassName}
        bestSuitedFor={bestSuitedFor}
      />
    </div>
  ) : null;
};

export const BestSuitedForTag = ({
  bestSuitedFor,
  childClassName = "badge bg-dark me-2",
}) => {
  const { bestSuitedIcons } = useContext(MasterDataContext);
  const dataObj = bestSuitedFor ? bestSuitedIcons[bestSuitedFor] : null;
  return dataObj ? (
    <span
      key={`bestSuitedFor_${dataObj.id}`}
      className={`${childClassName} best_suited_tag`}
    >
      <span className="emoji">{dataObj.emoji}</span>
      {dataObj.title}
    </span>
  ) : null;
};

// export const LanguageTags = ({
//   languages,
//   className = "mt-4",
//   childClassName = "badge bg-dark me-2",
// }) => {
//   return isArray(languages) ? (
//     <div className={className}>
//       <LanguageTagList languages={languages} childClassName={childClassName} />
//     </div>
//   ) : null;
// };

export const LanguageTag = ({
  language,
  childClassName = "badge bg-dark me-2",
}) => {
  const { languageIcons } = useContext(MasterDataContext);
  const langIcon = languageIcons[language?.code]
    ? languageIcons[language?.code]
    : null;
  return language?.code ? (
    <span key={`lang_${language.id}`} className={`${childClassName} lang_tag`}>
      <span className="emoji">
        {language.displayText}
        <span className="dot"></span>
        <span className="dot"></span>
      </span>
      {language.title}
    </span>
  ) : null;
};
