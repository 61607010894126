import React, { useContext } from "react";
import { AppListContext } from "./AppList";

export const AppListNoRecordFound = ({ message = "No Record Found" }) => {
  const { requesting, data } = useContext(AppListContext);
  return !requesting && !data.length ? (
    <div className="flex-column align-items-center justify-content-center d-flex">
      <small className="form-text text-muted no-record-found">{message}</small>
    </div>
  ) : null;
};
