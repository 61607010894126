import React, { useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AuthorContext } from "../../context/AuthorContext";

const AboutTab = () => {
  const { author } = useContext(AuthorContext);
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {author.about_me ? (
            <Card>
              <CardBody>
                <p className="home-desc text-muted mb-0">{author.about_me}</p>
              </CardBody>
            </Card>
          ) : (
            <div className="flex-column align-items-center justify-content-center d-flex">
              <small className="form-text text-muted no-record-found">
                oh no! There's nothing here.
              </small>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AboutTab;
