import Raect from "react";
import { MessageCard } from "../components/MessageCard";

const NoMatchPage = () => {
  return (
    <MessageCard type="NotFound">
      <h1 className="home-main-title">Page Not Found</h1>
    </MessageCard>
  );
};

export default NoMatchPage;
