import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { ModalBody } from "reactstrap";
import { useAlert } from "react-alert";
// import images
import closeIcon from "../../assets/images/icon/close-btn.svg";
import { Formik, Form, useFormikContext } from "formik";

import { AppPhoneNumberField } from "../../shared/formikBootstrap/PhoneNumberField";
import * as Yup from "yup";
import { getUser, requestToGetOtp, verifyOtp } from "../../api/auth";
import { Col, Row } from "reactstrap";
import { AppInput, AppOtpField } from "../../shared/formikBootstrap/Fields";
import { get, padStart } from "lodash";
import { setCookie } from "../../utils/cookie";
import AuthContext from "../../context/AuthContext";
import { updateUserName } from "../../api/user";
import clevertap from "clevertap-web-sdk";
import EventDefaultImage from "../../assets/images/Event.png";
import CommunityDefaultImage from "../../assets/images/Community.png";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";

const messagees = {
  _1: "incorrect mobile number!",
  _2: "incorrect otp!",
};

const dImage = {
  e: EventDefaultImage,
  c: CommunityDefaultImage,
};

const ValidateSchema = Yup.object().shape({
  mobile: Yup.object().shape({
    code: Yup.string().required("Country Code is required."),
    number: Yup.string()
      .required("Mobile Number is required.")
      .max(10)
      .min(6, "mobile number must be at least 6 characters"),
  }),
  full_name: Yup.string().when("step", {
    is: 3,
    then: Yup.string().required("full name is required.").min(2),
  }),

  email: Yup.string().when("step", {
    is: 3,
    then: Yup.string().when("showEmail", {
      is: true,
      then: Yup.string().required("email is required.").email("invalid email"),
    }),
  }),

  // otp: Yup.string().when("step", {
  //   is: 2,
  //   then: Yup.string().required("OTP is required.").min(4),
  // }),
});

const LoginModal = ({ close, event, showEmail }) => {
  const isCommunity = event?.hasOwnProperty("is_member");
  const image = isCommunity
    ? event?.image_url
    : get(event, "cover_media.0.path");

  const _image = !image ? dImage[isCommunity ? "c" : "e"] : image;

  const { updateUserData } = useContext(AuthContext);

  useEffect(() => {
    clevertap.event.push(AnalyticsConstant.LOGIN_INITIATED);
    clevertap.event.push(
      AnalyticsConstant.SIGN_IN_SCREEN + AnalyticsConstant.OPENED,
      {
        screen: "SignInScreen",
      }
    );

    return () => {
      clevertap.event.push(
        AnalyticsConstant.SIGN_IN_SCREEN + AnalyticsConstant.CLOSED,
        {
          screen: "SignInScreen",
        }
      );
    };
  }, []);

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setFieldValue, setFieldError }) => {
      // console.log("Hello...");
      try {
        if (values.step === 1) {
          const response = await requestToGetOtp(
            values.mobile.number,
            values.mobile.code
          );
          setFieldValue("step", 2);
          setFieldValue("token", response.data.authToken.token);
        } else if (values.step === 2) {
          const finalResponse = await verifyOtp(values.token, values.otp);
          if (!finalResponse.data.user.username) {
            setFieldValue("step", 3);
            setFieldValue("auth_token", finalResponse?.data?.authToken);
            return false;
          }
          console.log(
            "finalResponse?.data?.authToken",
            finalResponse?.data?.authToken
          );
          setCookie("AUTH-TOKEN", finalResponse?.data?.authToken);
          const userResponse = await getUser();
          updateUserData(userResponse.data);
          clevertap.profile.push({
            Site: {
              Name: userResponse?.data?.full_name,
              Identity: userResponse?.data?.id,
              // MobileNumber: userResponse?.data?.mobile,
              Email: userResponse?.data?.email,
              Phone: userResponse?.data?.mobile,
              Photo: userResponse?.data?.image_url,
              // username: userResponse?.data?.username,
            },
          });

          close(userResponse.data);
        } else if (values.step === 3) {
          //

          await updateUserName(
            {
              full_name: values.full_name,
              token: values.token,
              email: values.email ? values.email : "",
              // username: values.username,
            },
            {
              headers: {
                Authorization: `Bearer ${values.auth_token}`,
              },
            }
          );

          setCookie("AUTH-TOKEN", values.auth_token);
          const userResponse = await getUser();
          updateUserData(userResponse.data);

          clevertap.profile.push({
            Site: {
              Name: userResponse?.data?.full_name,
              Identity: userResponse?.data?.id,
              // MobileNumber: userResponse?.data?.mobile,
              // username: userResponse?.data?.username,
              Email: userResponse?.data?.email,
              Phone: userResponse?.data?.mobile,
              Photo: userResponse?.data?.image_url,
            },
          });

          close(userResponse.data);
        }
      } catch (error) {
        setFieldError(
          values.step === 1 ? "mobile.number" : "otp",
          messagees[`_${values.step}`]
        );
        if (values.step === 2) {
          clevertap.event.push(
            AnalyticsConstant.MOBILE_NUMBER_VALIDATION_ERROR,
            {
              screen: "SignInScreen",
              MobileNumber: values.mobile.number,
              CountryCode: values.mobile.code,
              ErrorMessage: error?.response?.data?.message,
            }
          );
        }
      }
      setSubmitting(false);
    },
    []
  );

  return (
    <>
      <ModalBody>
        <span
          role="button"
          className="float-end"
          onClick={() => close("close")}
        >
          <img src={closeIcon} className="img-fluid" alt="" />
        </span>
        <div className="text-center px-4">
          {event ? (
            <>
              <div className="model-img pb-4">
                <img src={_image} className="img-fluid" alt="" />
              </div>

              <p className="modal-desc text-light pt-5 mb-2">{event.title}</p>
              <div className="modal-border"></div>
            </>
          ) : null}
          <Formik
            validationSchema={ValidateSchema}
            initialValues={{
              mobile: {
                number: "",
                code: "+91",
              },
              otp: "",
              step: 1,
            }}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  <p className="modal-head pt-4">
                    {props.values.step === 1
                      ? "please enter your mobile number."
                      : null}
                    {props.values.step === 2
                      ? `enter OTP sent on ${props.values.mobile.code}  ${props.values.mobile.number}`
                      : null}
                    {props.values.step === 3 ? "setup your profile" : null}
                  </p>

                  {props.values.step === 1 ? <OtpGenerateComponent /> : null}
                  {props.values.step === 2 ? <OtpInputComponent /> : null}
                  {props.values.step === 3 ? (
                    <SetupProfileComponent showEmail={showEmail} />
                  ) : null}
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
      <div className="text-center mt-3">
        <Link to="#" className="text-white terms-conditions">
          <u>Terms and conditions</u>
        </Link>
      </div>
    </>
  );
};

const OtpInputComponent = () => {
  const { values, setFieldValue } = useFormikContext();
  const [secondsLeft, setLeftySeconds] = useState(30);
  const [restartCount, setRestartCount] = useState(true);
  const interval = useRef();
  const [isResendReqResolved, setResendStatus] = useState(true);
  const _alert = useAlert();

  useEffect(() => {
    interval.current = setInterval(() => {
      setLeftySeconds((v) => v - 1);
    }, 1000);
  }, [restartCount]);

  useEffect(() => {
    if (secondsLeft === 0) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [secondsLeft]);

  const handleResendOpt = useCallback(async () => {
    setResendStatus(false);
    try {
      const response = await requestToGetOtp(
        values.mobile.number,
        values.mobile.code
      );
      setFieldValue("token", response.data.authToken.token);
      setRestartCount((v) => !v);
      setLeftySeconds(30);
      _alert.success("OTP has been sent to your number.");
    } catch (error) {
      console.log("Error", error);
    }
    setResendStatus(true);
  }, [values.mobile]);

  return (
    <>
      <Row className="g-2 px-2 px-md-4">
        <Col lg={12}>
          <AppOtpField name="otp" />
        </Col>
      </Row>
      <div className="mt-4">
        <button
          type="submit"
          disabled={!values.otp || values.otp.toString().length < 4}
          className="btn btn-outline-custom navbar-btn btn-rounded"
        >
          Confirm
        </button>
      </div>

      {secondsLeft > 0 ? (
        <div className="mt-4">
          <p className="text-light mb-0">
            Resend in {padStart(secondsLeft, 2, "0")} secs
          </p>
        </div>
      ) : null}

      {secondsLeft <= 0 ? (
        <div className="mt-4">
          <button
            type="button"
            onClick={handleResendOpt}
            disabled={!isResendReqResolved}
            className="btn btn-link text-light mb-0"
          >
            <u>Resend</u>
          </button>
        </div>
      ) : null}
    </>
  );
};

const OtpGenerateComponent = () => {
  const form = useFormikContext();

  useEffect(() => {
    clevertap.event.push(
      AnalyticsConstant.OTP_SCREEN + AnalyticsConstant.OPENED,
      {
        screen: "OTPScreen",
      }
    );

    return () => {
      clevertap.event.push(
        AnalyticsConstant.OTP_SCREEN + AnalyticsConstant.CLOSED,
        {
          screen: "OTPScreen",
        }
      );
    };
  }, []);

  return (
    <>
      <AppPhoneNumberField
        name="mobile"
        placeholder="Mobile Number"
        maxLength={10}
        onKeyPress={(e) => {
          console.log("122", e);
          if (e.which == 10 || e.which == 13) {
            form.submitForm();
          }
        }}
      />
      <div className="mt-4 pt-3">
        <button
          type="submit"
          disabled={form.isSubmitting}
          className="btn btn-outline-custom navbar-btn btn-rounded"
        >
          Generate OTP
        </button>
      </div>
    </>
  );
};

const SetupProfileComponent = ({ showEmail }) => {
  const form = useFormikContext();

  useEffect(() => {
    clevertap.event.push(
      AnalyticsConstant.SIGNUP_SCREEN + AnalyticsConstant.OPENED,
      {
        screen: "PickNameScreen",
      }
    );

    return () => {
      clevertap.event.push(
        AnalyticsConstant.SIGNUP_SCREEN + AnalyticsConstant.CLOSED,
        {
          screen: "PickNameScreen",
        }
      );
    };
  }, []);

  useEffect(() => {
    const _v = { ...form.values, showEmail: !!showEmail };
    form.resetForm();
    form.setValues(_v);
  }, [showEmail]);

  console.log("form", form.values);

  return (
    <>
      {showEmail ? (
        <AppInput
          name="email"
          type="text"
          placeholder="Email"
          fieldProps={{ className: "text-center" }}
          className="mb-3"
        />
      ) : null}

      <AppInput
        name="full_name"
        type="text"
        placeholder="Full Name"
        fieldProps={{ className: "text-center" }}
      />
      <div className="mt-4 pt-3">
        <button
          type="submit"
          className="btn btn-outline-custom navbar-btn btn-rounded"
        >
          Let's Go!
        </button>
      </div>
    </>
  );
};

export default LoginModal;
