import request from "../config/request";

export const createOrder = (eventId) => {
  return request.post("/api:ofkVZm10/payment/order", { entity_id: eventId });
};

export const createCommunityOrder = (communityId, planId) => {
  return request.post("/api:ofkVZm10/payment/order", {
    is_community: true,
    plan_id: planId,
    entity_id: communityId,
  });
};

export const transactionStatus = ({
  order_id,
  transaction_id,
  entity_id,
  event_type,
  razorpay_signature,
}) => {
  return request.post("/api:ofkVZm10/payment/transaction", {
    order_id,
    transaction_id,
    entity_id,
    is_community: event_type,
    razorpay_signature,
  });
};

export const communityTransactionStatus = ({
  order_id,
  transaction_id,
  entity_id,
  subscription_id,
  razorpay_signature,
}) => {
  // apidoc:ofkVZm10/#/payment/post_payment_transaction

  return request.post("/api:ofkVZm10/payment/transaction", {
    order_id,
    transaction_id,
    entity_id,
    is_community: true,
    subscription_id,
    razorpay_signature,
  });
};

export const getTransactionByEventId = (eventId) => {
  return request.get(`/api:ofkVZm10/transaction/event/${eventId}`);
};
