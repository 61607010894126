import React, { useContext, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { Container } from "reactstrap";
import Section from "./Section";
import Footer from "../../components/Footer";
import { AuthorContext } from "../../context/AuthorContext";
import clevertap from "clevertap-web-sdk";
import AuthContext from "../../context/AuthContext";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";
import DownloadAppBanner from "../../components/DownloadAppBanner";
import OpenInApp from "../../components/OpenInApp";

const ProfilePage = () => {
  const { author } = useContext(AuthorContext);
  const { authUser } = useContext(AuthContext);
  useEffect(() => {
    const eventName = AnalyticsConstant.Profile + AnalyticsConstant.OPENED;

    if (author?.id && authUser?.id && author?.id === authUser?.id) {
      clevertap.event.push(eventName, {
        Entity: AnalyticsConstant.PROFILE_SELF,
        screen: AnalyticsConstant.PROFILE_SELF,
        Id: `${AnalyticsConstant.Profile}:${author?.id}`,
        Name: author?.full_name,
        Creator: author?.is_creator,
        FollowerCount: author?.follower_count,
        FollowingCount: author?.following_count,
        Email: author?.email,
        Verified: author?.is_verified,
        CurrentUserIdentity: authUser?.id,
        CurrentUserName: authUser?.full_name,
      });
    } else if (author?.id && authUser?.id && author?.id !== authUser?.id) {
      clevertap.event.push(eventName, {
        screen: AnalyticsConstant.PROFILE_OTHER,
        Entity: AnalyticsConstant.PROFILE_OTHER,
        Id: `${AnalyticsConstant.Profile}:${author?.id}`,
        Name: author?.full_name,
        Creator: author?.is_creator,
        FollowerCount: author?.follower_count,
        FollowingCount: author?.following_count,
        Email: author?.email,
        Verified: author?.is_verified,
        CurrentUserIdentity: authUser?.id,
        CurrentUserName: authUser?.full_name,
      });
    }
  }, [author?.id, authUser?.id]);
  return (
    <React.Fragment>
      <DownloadAppBanner />

      <Navbar
        OpenInAppComponent={
          author?.id ? (
            <OpenInApp
              screenName="profile"
              id={author?.id}
              userName={author?.username}
            />
          ) : null
        }
      />
      <section className="pb-5">
        <Container>
          <Section />
        </Container>
      </section>
      <Footer showFooterBottomBar={false} />
    </React.Fragment>
  );
};

export default ProfilePage;
