import React, { useContext } from "react";
import { Card, CardBody } from "reactstrap";
import ParseHtml from "../../components/ParseHtml";
import { CommunityContext } from "../../context/CommunityContext";

const CommunityDescription = () => {
  const { community } = useContext(CommunityContext);

  return (
    <div className="mt-4 pt-2">
      <p className="home-heading">description</p>
      <Card>
        <CardBody>
          <p className="home-desc text-muted mb-0 parse">
            <ParseHtml text={community?.description} />
          </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default CommunityDescription;
