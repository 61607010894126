const Cookie = {
  set: function (name, value, days = 30) {
    var domain, domainParts, date, expires, host;

    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    host = window.location.host;
    if (host.split(".").length === 1) {
      // no "." in a domain - it's localhost or something similar
      document.cookie = name + "=" + value + expires + "; path=/";
    } else {
      // Remember the cookie on all subdomains.
      //
      // Start with trying to set cookie to the top domain.
      // (example: if user is on foo.com, try to set
      //  cookie to domain ".com")
      //
      // If the cookie will not be set, it means ".com"
      // is a top level domain and we need to
      // set the cookie to ".foo.com"
      domainParts = host.split(".");
      domainParts.shift();
      domain = "." + domainParts.join(".");

      document.cookie =
        name + "=" + value + expires + "; path=/; domain=" + domain;

      // check if cookie was successfuly set to the given domain
      // (otherwise it was a Top-Level Domain)
      if (Cookie.get(name) === null || Cookie.get(name) !== value) {
        // append "." to current domain
        domain = "." + host;
        document.cookie =
          name + "=" + value + expires + "; path=/; domain=" + domain;
      }
    }
  },

  get: function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  erase: function (name) {
    Cookie.set(name, "", -1);
  },
};

export function setCookie(cname, cvalue, exdays = 30) {
  Cookie.set(cname, cvalue, exdays);
}
export function getCookie(cname) {
  return Cookie.get(cname);
}

export function deleteCookie(cname) {
  Cookie.erase(cname);
}
