import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCommunity } from "../api/communityApi";
import { getEventById } from "../api/event";
import { getUserByUsername } from "../api/user";
import { FullPageLoader } from "../components/FullPageLoader";
import { LoginNeededPage } from "../pages/LoginNeededPage";
import NoMatchPage from "../pages/NoMatchPage";
import { PrivateEventPage } from "../pages/PrivateEventPage";
import AuthContext from "./AuthContext";

export const CommunityContext = React.createContext();

export const CommunityProvider = ({ children }) => {
  const [community, setCommunity] = useState({});
  const [fetching, setFetchStatus] = useState(true);
  const { communityId } = useParams();
  const { authUser, requesting } = useContext(AuthContext);
  const [errorType, setErrorType] = useState(null);

  const fetchCommunity = async () => {
    if (authUser?.id) {
      setFetchStatus(true);
      try {
        const { data: eventData } = await getCommunity(communityId);

        setCommunity(eventData);
      } catch (error) {
        console.log("Error", error);
        const errorCode = error?.response?.data?.code;
        setErrorType(errorCode);
      }
      setTimeout(() => {
        setFetchStatus(false);
      }, 0);
    } else if (!requesting && !authUser?.id) {
      setTimeout(() => {
        setFetchStatus(false);
      }, 0);
    }
  };
  useEffect(() => {
    fetchCommunity();
  }, [authUser?.id, communityId, requesting]);

  return (
    <CommunityContext.Provider value={{ community, setCommunity, fetchCommunity }}>
      <RenderComponent
        fetching={fetching}
        communityId={community?.id}
        requesting={requesting}
        authUser={authUser}
        errorType={errorType}
      >
        {children}
      </RenderComponent>
    </CommunityContext.Provider>
  );
};

const RenderComponent = ({
  children,
  fetching,
  communityId,
  requesting,
  authUser,
  errorType,
}) => {
  if (fetching || requesting) {
    // console.log('requesting', requesting, fetching, authUser?.id, '1')
    return <FullPageLoader />;
  } else if (!fetching && communityId) {
    // console.log('requesting', requesting, fetching, authUser?.id, '2')
    return children;
  } else if (errorType === "ERROR_CODE_NOT_FOUND") {
    return <NoMatchPage />;
  } else if (!authUser?.id && !requesting) {
    // console.log('requesting', requesting, fetching, authUser?.id, '3')
    return <LoginNeededPage message="Please login to access this community." />;
  } else if (
    authUser?.id &&
    !requesting &&
    !fetching &&
    errorType === "ERROR_CODE_ACCESS_DENIED"
  ) {
    // console.log('requesting', requesting, fetching, authUser?.id, '3')
    return (
      <PrivateEventPage message="Looks like this is a private community, to access please get in touch with the creator." />
    );
  } else {
    // console.log('requesting', requesting, fetching, authUser?.id, '4')
    return <NoMatchPage />;
  }
};
