import React, { useRef, useEffect, useState } from "react";
import { AppInput } from "./Fields";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Form } from "react-bootstrap";
import { conforms, get, groupBy, isArray } from "lodash";
import axios from "axios";
import request from "../../config/request";

function makeGroupOptions(myOptions, groupByKey) {
  let groupOptions = {};

  groupOptions = groupBy(myOptions, function (opt) {
    const key = get(opt, groupByKey);
    return key ? key : "Uncategorized";
  });

  const groupOptionsLabel = Object.keys(groupOptions);
  return groupOptionsLabel.map((groupLabel) => {
    return {
      label: groupLabel,
      options: groupOptions[groupLabel],
    };
  });
}

const NativeOption = ({
  prefixOption,
  options,
  getOptionValue,
  getOptionLabel,
}) => {
  return (
    <>
      {prefixOption}
      {options.map((option, index) => (
        <option
          key={`native_select_${getOptionValue(option)}_${index}`}
          value={getOptionValue(option)}
        >
          {getOptionLabel(option)}
        </option>
      ))}
    </>
  );
};

const NativeGroupOption = ({
  options,
  getOptionValue,
  getOptionLabel,
  groupByKey,
  prefixOption,
}) => {
  const groupOptions = makeGroupOptions(options, groupByKey);
  return groupOptions.map((groupOption) => {
    return (
      <optgroup
        label={groupOption.label}
        key={`native_group_option_${groupOption.label}`}
      >
        <NativeOption
          prefixOption={prefixOption}
          options={groupOption.options}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
        />
      </optgroup>
    );
  });
};

export const AppReactSelect = React.forwardRef((props, ref) => {
  return (
    <AppInput {...props} multiple={props.isMulti}>
      {(inputProps, { touched }, { setTouched }) => {
        const { disabled: isDisabled, multiple, ...restProps } = inputProps;
        const customStyles = {
          control: (provided) => ({
            ...provided,
            ...(inputProps.isInvalid ? { borderColor: "#dc3545" } : {}),
          }),
          menu: (provided) => {
            return {
              ...provided,
              zIndex: 9,
            };
          },
          menuList: (provided) => {
            return {
              ...provided,
              color: "#00b4f8",
              fontWeight: 600,
            };
          },
          multiValueLabel: (provided) => {
            return {
              ...provided,
              color: "#09b6f8",
              backgroundColor: "#dbf5fe",
              fontWeight: 600,
            };
          },
          multiValueRemove: (provided) => {
            return {
              ...provided,
              color: "#09b6f8",
              backgroundColor: "#dbf5fe",
              fontWeight: 600,
            };
          },
          ...props.styles,
        };

        return (
          <MyReactSelect
            {...props}
            isDisabled={isDisabled}
            isMulti={multiple}
            {...restProps}
            ref={ref}
            styles={customStyles}
            onMenuClose={() => {
              if (!touched) {
                setTouched(true);
              }
              if (typeof restProps.onMenuClose === "function") {
                restProps.onMenuClose();
              }
            }}
          />
        );
      }}
    </AppInput>
  );
});

export const MyReactSelect = React.forwardRef(
  (
    {
      action,
      dataKey = "data",
      method = "get",
      payload,
      isMulti,
      options,
      isCreatable,
      groupByKey,
      isDisabled,
      getOptionValue = (item) => (typeof item === "object" ? item.id : item),
      getOptionLabel = (item) => (typeof item === "object" ? item.name : item),
      loadedCallback,
      config,
      ...restProps
    },
    ref
  ) => {
    const [asyncOptions, setAsyncOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const mapFnc = (v) => {
      let label = v.label;
      let value = v.value;

      if (typeof getOptionLabel === "function") {
        label = getOptionLabel(v);
      }
      if (typeof getOptionValue === "function") {
        value = getOptionValue(v);
      }
      return {
        ...(typeof v === "object" ? v : {}),
        label,
        value,
      };
    };
    const fetchData = async () => {
      setLoading(true);
      const response = await request({
        method,
        url: action,
        params:
          payload && typeof payload === "string"
            ? JSON.parse(payload)
            : payload,
        ...config,
      });
      const data = get(response, `data.${dataKey}`);
      setAsyncOptions(data);
      setLoading(false);
      if (typeof loadedCallback === "function") {
        loadedCallback(data);
      }
    };

    useEffect(() => {
      if (action) {
        fetchData();
      }
    }, [action, dataKey, method, payload]);

    useEffect(
      function () {
        if (ref) {
          ref.current = { fetchData };
        }
      },
      [action, dataKey, method, payload]
    );

    let myOptions = action ? asyncOptions : options;
    myOptions = (isArray(myOptions) ? myOptions : []).map(mapFnc);
    return React.createElement(isCreatable ? CreatableSelect : Select, {
      options: groupByKey ? makeGroupOptions(myOptions, groupByKey) : myOptions,
      isMulti,
      isDisabled,
      isLoading: loading,
      getOptionValue: !isCreatable ? getOptionValue : undefined,
      getOptionLabel: !isCreatable ? getOptionLabel : undefined,
      ...restProps,
    });
  }
);

export const AppSelect = React.forwardRef(
  (
    {
      action,
      dataKey = "data",
      method = "get",
      payload,
      options,
      prefixOption,
      getOptionValue = (item) => (typeof item === "object" ? item.id : item),
      getOptionLabel = (item) => (typeof item === "object" ? item.name : item),
      hidePlaceholder = true,
      groupByKey,
      ...props
    },
    ref
  ) => {
    const [asyncOptions, setAsyncOptions] = useState([]);
    useEffect(() => {
      const fnc = async () => {
        const response = await request({
          method,
          url: action,
          params: payload ? JSON.parse(payload) : null,
        });
        setAsyncOptions(get(response, `data.${dataKey}`));
      };
      if (action) {
        fnc();
      }
    }, [action, dataKey, method, payload]);
    const myOptions = action ? asyncOptions : options;

    return (
      <AppInput {...props}>
        {(inputProps) => {
          return (
            <Form.Control ref={ref} as="select" {...inputProps}>
              {inputProps.placeholder ? (
                <option hidden={hidePlaceholder} value="">
                  {inputProps.placeholder}
                </option>
              ) : null}
              {groupByKey ? (
                <NativeGroupOption
                  options={myOptions}
                  getOptionValue={getOptionValue}
                  getOptionLabel={getOptionLabel}
                  groupByKey={groupByKey}
                  prefixOption={prefixOption}
                />
              ) : (
                <NativeOption
                  options={myOptions}
                  getOptionValue={getOptionValue}
                  getOptionLabel={getOptionLabel}
                  prefixOption={prefixOption}
                />
              )}
            </Form.Control>
          );
        }}
      </AppInput>
    );
  }
);

export const AppReactAsyncSelect = ({
  action,
  loadOptions,
  payload = {},
  searchKey = "search",
  method = "get",
  dataKey = "data",
  isMulti,
  onError,
  isCreatable,
  styles,
  groupByKey,
  getOptionLabel,
  getOptionValue,
  ...props
}) => {
  const axiosInst = useRef();
  const handleLoadOptions = async (inputValue) => {
    if (typeof loadOptions === "function") {
      return loadOptions(inputValue);
    } else if (action) {
      try {
        if (axiosInst.current) {
          const oldSource = axiosInst.current;
          oldSource.cancel("overlapping");
          oldSource.token.promise.then((error) => {
            return Promise.resolve(error);
          });
        }
        axiosInst.current = axios.CancelToken.source();
        const response = await request({
          method,
          url: action,
          cancelToken: axiosInst.current.token,
          params: {
            [searchKey]: inputValue,
            ...payload,
          },
        });
        const mapFnc = (v) => {
          let label = v.label;
          let value = v.value;
          if (typeof getOptionLabel === "function") {
            label = getOptionLabel(v);
          }
          if (typeof getOptionValue === "function") {
            value = getOptionValue(v);
          }
          return {
            ...v,
            label,
            value,
          };
        };
        const remoteOptions = get(response, `data.${dataKey}`, []).map(mapFnc);

        return groupByKey
          ? makeGroupOptions(remoteOptions, groupByKey)
          : remoteOptions;
      } catch (e) {
        if (e.message !== "overlapping") {
          if (typeof onError === "function") {
            onError(e);
          }
        }
      }
    }
    return null;
  };
  return (
    <AppInput {...props} multiple={isMulti}>
      {(inputProps, { touched }, { setTouched }) => {
        const customStyles = {
          control: (provided) => ({
            ...provided,
            ...(inputProps.isInvalid ? { borderColor: "#dc3545" } : {}),
          }),
          ...styles,
        };
        const {
          disabled: isDisabled,
          multiple: isMulti,
          ...restProps
        } = inputProps;
        return React.createElement(
          isCreatable ? AsyncCreatableSelect : AsyncSelect,
          {
            isMulti,
            isDisabled,
            ...restProps,
            loadOptions: handleLoadOptions,
            styles: customStyles,
            onMenuClose: () => {
              if (!touched) {
                setTouched(true);
              }
              if (typeof restProps.onMenuClose === "function") {
                restProps.onMenuClose();
              }
            },
          }
        );
      }}
    </AppInput>
  );
};

export const MyReactAsyncSelect = React.forwardRef(
  (
    {
      action,
      dataKey = "data",
      method = "get",
      payload,
      searchKey = "search",
      isMulti,
      options,
      isCreatable,
      groupByKey,
      isDisabled,
      getOptionValue = (item) => (typeof item === "object" ? item.id : item),
      getOptionLabel = (item) => (typeof item === "object" ? item.name : item),
      loadedCallback,
      loadOptions,
      onError,
      config,
      ...restProps
    },
    ref
  ) => {
    const [asyncOptions, setAsyncOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const axiosInst = useRef();
    const mapFnc = (v) => {
      let label = v.label;
      let value = v.value;

      if (typeof getOptionLabel === "function") {
        label = getOptionLabel(v);
      }
      if (typeof getOptionValue === "function") {
        value = getOptionValue(v);
      }
      return {
        ...(typeof v === "object" ? v : {}),
        label,
        value,
      };
    };

    let myOptions = action ? asyncOptions : options;
    myOptions = (isArray(myOptions) ? myOptions : []).map(mapFnc);

    const handleLoadOptions = async (inputValue) => {
      if (typeof loadOptions === "function") {
        return loadOptions(inputValue);
      } else if (action) {
        try {
          if (axiosInst.current) {
            const oldSource = axiosInst.current;
            oldSource.cancel("overlapping");
            oldSource.token.promise.then((error) => {
              return Promise.resolve(error);
            });
          }
          axiosInst.current = axios.CancelToken.source();
          const response = await request({
            method,
            url: action,
            cancelToken: axiosInst.current.token,
            params: {
              [searchKey]: inputValue,
              ...payload,
            },
          });
          const mapFnc = (v) => {
            let label = v.label;
            let value = v.value;
            if (typeof getOptionLabel === "function") {
              label = getOptionLabel(v);
            }
            if (typeof getOptionValue === "function") {
              value = getOptionValue(v);
            }
            return {
              ...v,
              label,
              value,
            };
          };
          const remoteOptions = get(response, `data.${dataKey}`, []).map(
            mapFnc
          );

          return groupByKey
            ? makeGroupOptions(remoteOptions, groupByKey)
            : remoteOptions;
        } catch (e) {
          if (e.message !== "overlapping") {
            if (typeof onError === "function") {
              onError(e);
            }
          }
        }
      }
      return null;
    };

    return React.createElement(
      isCreatable ? AsyncCreatableSelect : AsyncSelect,
      {
        isMulti,
        isDisabled,
        ...restProps,
        loadOptions: handleLoadOptions,
        // styles: customStyles,
      }
    );
  }
);
