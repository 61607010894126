import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { getBannerImage } from "../utils/app-utils";

export const Banner = ({ grayscale, banners, defaultImage }) => {
  const _items = banners
    ?.filter((m) => m?.path)
    .map((img) => ({
      id: img.path,
      src: img.path,
    }));

  const items =
    (!_items || !_items.length) && defaultImage
      ? [{ id: 1, src: defaultImage }]
      : _items;

  const slides = items?.map((item, key) => {
    return (
      <Carousel.Item key={item.id || item.src}>
        <img
          src={getBannerImage(item.src)}
          className={`img-fluid w-100 ${grayscale ? "grayscale" : ""}`}
          alt=""
        />
      </Carousel.Item>
    );
  });

  return (
    <Carousel
      className="slide home-slider"
      indicators={false}
      controls={items.length > 1 ? true : false}
    >
      {slides}
    </Carousel>
  );
};
