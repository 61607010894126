import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import badge from "../assets/images/badge.png";
import RoundedImage from "./RoundedImage";

export const CreatorCard = ({ author, isLast }) => {
  return (
    <Card className={!isLast ? "mb-3" : ""}>
      <CardBody>
        <Link to={`/${author?.username}`}>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <RoundedImage
                title={author.full_name}
                imageUrl={author?.image_url}
                isVerified={author?.is_verified}
                imageClassName="user-avatar"
                radius={80}
              />
              {author?.is_verified ? (
                <div className="verified-icon">
                  <img src={badge} className="img-fluid icon" alt="" />
                </div>
              ) : null}
            </div>
            <div className="flex-grow-1 ms-4 overflow-hidden pe-4">
              <h5 className="mb-0 text-muted home-heading text-truncate">
                {author.full_name}
              </h5>
              <span className="text-muted text-truncate home-heading">
                @{author?.username}
              </span>
              <p className="text-muted text-truncate mb-0 mt-1 home-desc">
                {author.bio}
              </p>
            </div>
            {/* <div className="flex-shrink-0">
            <Link to="#">
              <img src={plusIcon} className="home-plus-icon" alt="" />
            </Link>
          </div> */}
          </div>
        </Link>
      </CardBody>
    </Card>
  );
};
