import React from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import DeepLink from "../components/DeepLink";
import AuthContext from "../context/AuthContext";

const HomePage = () => {
  const myFrame = useRef();

  const { authUser, requesting } = useContext(AuthContext);

  useEffect(() => {
    const elm = document.getElementById("root");
    if (elm) {
      elm.classList.add("home-page-root");
    }
    return () => elm.classList.remove("home-page-root");
  }, []);

  useEffect(() => {
    if (requesting === false && authUser?.id) {
      setTimeout(() => {
        myFrame.current?.contentWindow?.postMessage(JSON.stringify(authUser));
      }, 100);
    }
  }, [requesting, authUser?.id]);

  return (
    <>
      <DeepLink />
    </>
  );
};

export default HomePage;
