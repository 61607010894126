import React, { useContext } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

// import images
import facebook from "../assets/images/icon/facebook.svg";
import twitter from "../assets/images/icon/twitter.svg";
import Insta from "../assets/images/icon/Insta.svg";
import upshevron from "../assets/images/icon/up-shevron.svg";
import googleplayFooter from "../assets/images/icon/googleplay-footer.svg";
import { EventContext } from "../context/EventContext";
import { EventActionBtn } from "./EventActionBtn";
import { CommunityContext } from "../context/CommunityContext";
import { BuyCommunityBtn } from "../pages/CommunityPage/BuyCommunityBtn";

const Footer = ({ showFooterBottomBar, community, fetchCommunity }) => {
  //bak to top button
  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const { event } = useContext(EventContext);

  return (
    <React.Fragment>
      <section className="footer-bg">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="footer-terms">
                <ul className="mb-0 list-inline text-center mt-2">
                  <li className="list-inline-item">
                    <a
                      href="https://joincueapp.notion.site/About-a50bce49be254b588884d2607197a1c4"
                      className="footer-link"
                      target="_blank"
                    >
                      about
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/p/privacy-policy.html" className="footer-link">
                      privacy
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/p/terms.html" className="footer-link">
                      terms
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://medium.com/cue-app"
                      className="footer-link"
                      target="_blank"
                    >
                      blog
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="/p/community-guidelines.html"
                      className="footer-link"
                    >
                      community guides
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="mailto:support@joincueclub.com"
                      className="footer-link"
                    >
                      support
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={6}>
              <div className="mt-4 ps-0 ps-xl-5">
                <p className="footer-desc mb-0">
                  cue club is a creator-first live social platform powering
                  conversations between creators and their fans. the platform
                  offers creator discovery with seamless content monetisation,
                  audience engagement and rich insights.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <Row className="align-items-end">
                <Col md={5}>
                  <div className="mt-4 ps-0 ps-xl-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="f-14 text-light d-none d-md-block">
                          find us on
                        </p>
                        <ul className="list-inline social-img mb-0">
                          <li className="list-inline-item">
                            <a
                              target="_blank"
                              href="https://www.facebook.com/joincueclub"
                            >
                              <img src={facebook} className="img" alt="" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              target="_blank"
                              href="https://twitter.com/joincueclub"
                            >
                              <img src={twitter} className="img" alt="" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://www.instagram.com/joincueclub/"
                              target="_blank"
                            >
                              <img src={Insta} className="img" alt="" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <Link
                        to="#"
                        className="d-block d-sm-none"
                        id="back-to-top"
                        onClick={() => toTop()}
                      >
                        <img src={upshevron} className="img-fluid" alt="" />
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={7}>
                  <div className="mt-4 d-flex justify-content-center justify-content-md-between align-items-center">
                    <a
                      href="https://cueclub.onelink.me/OpLb/jr1iz0s5"
                      target="_blank"
                    >
                      <img
                        src={googleplayFooter}
                        className="img-fluid googleplay-footer"
                        alt=""
                      />
                    </a>
                    <Link
                      to="#"
                      className="pe-5 d-none d-xl-block"
                      id="back-to-top"
                      onClick={() => toTop()}
                      style={{ display: "block" }}
                    >
                      <img src={upshevron} className="img-fluid" alt="" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="row footer-alt">
            <Col lg={12}>
              <div className="text-center">
                <p className="footer-alt-title mb-0">
                  © {new Date().getFullYear()} cue club. all rights reserved
                </p>
              </div>
            </Col>
          </div>
        </Container>
      </section>

      {(event?.id || community?.id) && showFooterBottomBar ? (
        <section className="section pt-3 d-block d-sm-none">
          <Container className="p-0">
            <Row className="g-0">
              <Col lg={12}>
                <Card className="footer-sidebar">
                  <CardBody>
                    <div className="text-center my-1">
                      {event?.id ? <EventActionBtn event={event} /> : null}
                      {community?.id ? (
                        <BuyCommunityBtn
                          communityData={community}
                          afterJoin={fetchCommunity}
                          isDetailPage={true}
                        />
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      ) : null}
    </React.Fragment>
  );
};

export default Footer;
