import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { getEventById } from "../../api/event";
import { getTransactionByEventId } from "../../api/payment";
import { getUserByUsername } from "../../api/user";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { AuthorContext } from "../../context/AuthorContext";
import { EventContext } from "../../context/EventContext";
import { eventTitleInUrl } from "../../utils/app-utils";
import About from "../EventPage/About";
import Download from "./Download";
import Section from "./Section";

const PassPage = () => {
  const { setEvent } = useContext(EventContext);
  const { setAuthor } = useContext(AuthorContext);
  const { eventId } = useParams();
  const [transData, setTransData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fnc = async () => {
      try {
        const { data: event } = await getEventById(eventId);
        const authorId = event?.created_by;
        const { data: author } = await getUserByUsername(authorId);

        if (!event?.has_paid) {
          navigate(
            `/${author.username}/${eventId}/${eventTitleInUrl(event?.title)}`
          );
          return;
        }
        
        if (typeof window.fbq === "function") {
          window.fbq("track", "Purchase");
        }

        setEvent(event);
        setAuthor(author);
        const {
          data: { items: transactions },
        } = await getTransactionByEventId(eventId);
        const transaction = transactions?.find(
          (trans) => trans.status === "CAPTURED"
        );
        setTransData(transaction);
      } catch (error) {
        console.log("Error", error);
        navigate("/");
      }
    };

    fnc();
  }, [eventId]);

  console.log("transData", transData);
  return (
    <React.Fragment>
      <Navbar />
      <section className="home-contain pb-4 pb-lg-5">
        <Container>
          <Section transId={transData?.transaction_id} />
          <Row>
            <Col lg={8}>
              <div className="banner-box">
                <About />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer showFooterBottomBar={false} />
    </React.Fragment>
  );
};

export default PassPage;
