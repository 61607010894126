import React from "react";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { AppModal } from "../../shared/modal/app-modal-utils";
import { isLogin } from "../../utils/helper";
import LoginModal from "../../pages/ModelsPage/LoginModal";
import { ModalClasses } from "../../utils/app-utils";
import LinkEmailModal from "./LinkEmailModal";
import { addListener, getEventById } from "../../api/event";
import { useAlert } from "react-alert";
import RegisterSuccessModal from "./RegisterSuccessModal";
import { EventContext } from "../../context/EventContext";
import { useState } from "react";
import { FullPageLoader } from "../FullPageLoader";

const RegisterMeInEventBtn = ({ eventId }) => {
  const { authUser } = useContext(AuthContext);
  const { setEvent } = useContext(EventContext);

  const [showLoader, setShowLoader] = useState(false);
  const [isBusy, setBusy] = useState(false);

  const _alert = useAlert();
  const handleClick = async () => {
    try {
      setBusy(true);
      let user = authUser;

      if (!isLogin()) {
        user = await AppModal.open(
          LoginModal,
          {
            showFooter: false,
            showCloseBtn: false,
            className: ModalClasses,
            centered: true,
          },
          { showEmail: true }
        );
      }
      if (!user.email) {
        user.email = await AppModal.open(LinkEmailModal, {
          showFooter: false,
          showCloseBtn: false,
          className: ModalClasses,
          centered: true,
        });
      }
      setShowLoader(true);
      const { data: event } = await getEventById(eventId);
      setEvent(event);

      if (!event.in_calendar) {
        // call api to register in calendar
        await addListener(event.id, user.id);
        setEvent({ ...event, in_calendar: true });
        setShowLoader(false);
        AppModal.open(
          RegisterSuccessModal,
          {
            showFooter: false,
            showCloseBtn: false,
            className: ModalClasses,
            centered: true,
          },
          { event }
        );
      } else {
        _alert.info("you are already register.");
      }
      setBusy(false);
    } catch (error) {
      console.log("Error", error);
      setShowLoader(false);
      setBusy(false);
      _alert.error("error!!");
    }
  };

  return (
    <>
     {showLoader ? <FullPageLoader /> : null}
      <button
        type="button"
        disabled={isBusy}
        onClick={handleClick}
        className="btn btn-custom btn-lg btn-rounded by-pass-btn no-disable bounce"
      >
        register
      </button>
    </>
  );
};

export default RegisterMeInEventBtn;
