import { getCookie } from "./cookie";

export const isLogin = () => !!getCookie("AUTH-TOKEN");

export const getShortName = (str) => {
  if (!str) {
    return "";
  }
  const firstLetterCollection = str.match(/\b(\w)/g);
  return firstLetterCollection.splice(0, 2).join("");
};

export const displayDate = (int) => {
  const date = new Date(int);
  return date.toLocaleDateString("en-US", {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const displayTime = (int) => {
  const date = new Date(int);
  return date.toLocaleTimeString("en-US", {
    minute: "2-digit",
    hour12: true,
    hour: "2-digit",
  });
};

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}


