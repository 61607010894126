import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { getShortName } from "../utils/helper";
import ImageLoader from "react-loading-image";
import { getAvatarImage } from "../utils/app-utils";

const RoundedImage = ({
  imageUrl,
  title,
  className,
  radius,
  imageClassName,
  isVerified,
  zoom = 1,
  initial = "light",
  ...props
}) => {
  const [shortTitle, setShprtTitle] = useState("");
  const [isLoaded, setLoad] = useState(true);
  const [isError, setError] = useState(false);
  const _imageUrl = getAvatarImage(imageUrl);

  useEffect(() => {
    setShprtTitle(getShortName(title));
  }, [title]);

  useEffect(() => {
    // if (imageUrl) {
    setLoad(_imageUrl ? false : true);

    setError(false);
  }, [_imageUrl]);

  return (
    <div
      className={`rounded-image-container _${radius} ${className}`}
      style={{ width: `${radius}px`, height: `${radius}px` }}
      {...props}
      data-image={_imageUrl ? 1 : 0}
      data-isloaded={isLoaded ? 1 : 0}
      data-iserror={isError ? 1 : 0}
    >
      {!isLoaded || isError || !_imageUrl ? (
        <div
          className="avatar-sm"
          style={{ width: `${radius}px`, height: `${radius}px` }}
        >
          {!shortTitle && !isLoaded ? (
            <Spinner
              style={{ width: `${radius}px`, height: `${radius}px` }}
              animation="border"
              role="status"
            />
          ) : (
            <div
              className={`avatar-title rounded-circle  ${
                initial === "dark" ? "text-light" : "text-dark"
              } f-16`}
              style={{
                backgroundColor:
                  initial === "dark" ? "rgba(0, 0, 0, 0.4)" : "#FFF2DE",
              }}
            >
              {shortTitle}
            </div>
          )}
        </div>
      ) : null}

      <ImageLoader
        src={_imageUrl}
        alt={title}
        onLoad={(e) => {
          setLoad(true);
        }}
        onError={(e) => {
          setError(true);
        }}
        className={` 1 rounded-circle ${imageClassName ? imageClassName : ""} ${
          isVerified ? "verified-border" : ""
        }  ${!_imageUrl || !isLoaded || isError ? "d-none" : ""}`}
        style={{ maxWidth: `${radius * zoom}px`, height: "auto" }}
      />
    </div>
  );
};

export default RoundedImage;
