import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { RazorPayContext } from "../context/RazorPayContext";
import LoginModal from "../pages/ModelsPage/LoginModal";
import { AppModal } from "../shared/modal/app-modal-utils";
import { ModalClasses, secondToStr, toCurrency } from "../utils/app-utils";
import { getCookie } from "../utils/cookie";
import { isLogin } from "../utils/helper";
import { isMobile } from "react-device-detect";
import { generateOpenInAppUrl, OpenInAppModal } from "./OpenInApp";
import RegisterMeInEventBtn from "./RegisterMe/RegisterMeInEventBtn";

export const EventActionBtn = ({ event, enabledPassBtn = true }) => {
  const { makePayment, isBusy } = useContext(RazorPayContext);

  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const currentTime = new Date().getTime();
  const isStartedDatePassed = new Date(event.start_at) <= new Date();
  const isStartedDateInFuture = new Date(event.start_at) >= new Date();
  const showRestartEvent =
    isStartedDatePassed && !event.is_live && event?.created_by === authUser?.id;
  const isCreator = event?.created_by === authUser?.id;

  const showStartEvent =
    isStartedDateInFuture &&
    !event.is_live &&
    event?.created_by === authUser?.id;

  const showStartSoonEvent =
    isStartedDateInFuture &&
    !event.is_live &&
    event?.created_by !== authUser?.id;

  // console.log("authUser?.id", authUser?.id, event?.id);
  const showBuyPassBtn =
    event?.is_paid &&
    !event?.has_paid &&
    authUser?.id !== event?.created_by &&
    (isStartedDateInFuture || event.is_live);
  const showJoinBtn =
    event.is_live && (!event?.is_paid || (event?.is_paid && event?.has_paid));
  const showPassBtn =
    !event.is_live &&
    event?.start_at > currentTime &&
    event?.is_paid &&
    event?.has_paid;

  const showRegisterButton =
    // isStartedDateInFuture &&
    event?.requires_reg &&
    !event?.is_paid &&
    !event?.in_calendar &&
    event?.type === "OPEN" &&
    event?.created_by !== authUser?.id && !event.is_live;

  const location = useLocation();
  const handleJoinEvent = useCallback(
    async (e) => {
      e.stopPropagation();
      if (!isLogin()) {
        await AppModal.open(
          LoginModal,
          {
            showFooter: false,
            showCloseBtn: false,
            className: ModalClasses,
            centered: true,
          },
          { event }
        );
      }

      // if (isMobile) {
      //   AppModal.open(
      //     OpenInAppModal,
      //     {
      //       showFooter: false,
      //       showCloseBtn: false,
      //       className: ModalClasses,
      //       centered: true,
      //     },
      //     {
      //       screenName: "feed",
      //       id: event?.id,
      //       title: event?.title,
      //       userName: event?.username,
      //     }
      //   );
      //   return false;
      // }

      const uri = process.env.REACT_APP_AUTH_TOKEN_REQUIRE_TO_JOIN
        ? `/room-join-request/${event.id}?token=${getCookie(
            "AUTH-TOKEN"
          )}&path=${location.pathname}`
        : `/preview${location.pathname}`;

      window.location.href = `${process.env.REACT_APP_WEB_ROOM}${uri}`;
    },
    [event]
  );

  // const handleRestart =

  const handlePass = useCallback(
    (e) => {
      e.stopPropagation();
      navigate(`/event/${event.id}/pass`);
    },
    [event]
  );

  return (
    <>
      {showBuyPassBtn ? (
        <button
          type="button"
          onClick={(e) => makePayment(e, event)}
          disabled={isBusy}
          className="btn btn-custom btn-lg btn-rounded by-pass-btn"
        >
          buy pass {toCurrency(event?.price)}
        </button>
      ) : null}

      {showJoinBtn ? (
        <button
          type="button"
          onClick={handleJoinEvent}
          className="btn btn-dark navbar-btn btn-rounded w-xl profile-tab-button"
        >
          join
        </button>
      ) : null}

      {showPassBtn &&
      enabledPassBtn &&
      !showRestartEvent &&
      !showStartEvent &&
      !showStartSoonEvent ? (
        <button
          type="button"
          onClick={handlePass}
          className="btn btn-custom btn-lg btn-rounded by-pass-btn"
        >
          view pass
        </button>
      ) : null}

      {showRestartEvent ? (
        <button
          type="button"
          onClick={handleJoinEvent}
          className="btn btn-custom btn-lg btn-rounded by-pass-btn"
        >
          restart event
        </button>
      ) : null}

      {showStartEvent && !showRegisterButton ? (
        <StartInSomeTimeButton
          startTime={event?.start_at}
          isModerator={isCreator}
          handleJoinEvent={handleJoinEvent}
        />
      ) : null}

      {showStartSoonEvent && !showBuyPassBtn && !showRegisterButton ? (
        <StartSoonButton
          startTime={event?.start_at}
          isModerator={isCreator}
          handleJoinEvent={handleJoinEvent}
        />
      ) : null}

      {showRegisterButton && !showBuyPassBtn ? (
        <RegisterMeInEventBtn eventId={event?.id} />
      ) : null}
    </>
  );
};

const StartSoonButton = ({ isModerator, startTime, handleJoinEvent }) => {
  const [timeLeft, setLeftTime] = useState(
    Math.floor((new Date(startTime).getTime() - new Date().getTime()) * 0.001)
  );

  useEffect(() => {
    const _id = setInterval(() => {
      const secondLeft = Math.floor(
        (new Date(startTime).getTime() - new Date().getTime()) * 0.001
      );
      setLeftTime(secondLeft);
      if (setLeftTime === 0) {
        clearInterval(_id);
      }
    }, 1000);
  }, []);

  const handleClick = (e) => {
    if (timeLeft >= 300 && !isModerator) {
      return false;
    }
    handleJoinEvent(e);
  };

  const label = isModerator ? "starts" : "join";
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        disabled={timeLeft >= 300 && !isModerator}
        className="btn btn-custom btn-lg btn-rounded by-pass-btn no-disable bounce"
      >
        {timeLeft === 0 ? label : `${label} in ${secondToStr(timeLeft)}`}
      </button>
      {timeLeft !== 0 ? (
        <h4 className="event-help-text">
          Thank you for your patience. we are waiting for the creator to start
          the event.
        </h4>
      ) : null}
    </>
  );
};

const StartInSomeTimeButton = ({ isModerator, startTime, handleJoinEvent }) => {
  const [timeLeft, setLeftTime] = useState(
    Math.floor((new Date(startTime).getTime() - new Date().getTime()) * 0.001)
  );

  useEffect(() => {
    const _id = setInterval(() => {
      const secondLeft = Math.floor(
        (new Date(startTime).getTime() - new Date().getTime()) * 0.001
      );
      setLeftTime(secondLeft);
      if (setLeftTime <= 0) {
        clearInterval(_id);
      }
    }, 1000);
  }, []);

  const handleClick = (e) => {
    if (timeLeft >= 300 && !isModerator) {
      return false;
    }

    handleJoinEvent(e);
  };
  const label = isModerator ? "starts" : "join";
  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={timeLeft >= 300 && !isModerator}
      className="btn btn-custom btn-lg btn-rounded by-pass-btn"
    >
      {timeLeft <= 0
        ? `${label} event`
        : `${label} in ${secondToStr(timeLeft)}`}
    </button>
  );
};
