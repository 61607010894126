import HomePage from "../pages/HomePage";
import PassPage from "../pages/PassPage";
import ProfilePage from "../pages/ProfilePage";
import ModelsPage from "../pages/ModelsPage";
import { SecureRoutes } from "./SecureRoutes";
import CommunityPage from "../pages/CommunityPage";
import { CommunityProvider } from "../context/CommunityContext";
import CommunityMemebershipPass from "../pages/PassPage/CommunityMemebershipPass";
import LogoutPage from "../pages/LogoutPage";
import EndRoomPage from "../pages/EndRoomPage";

const routes = [
  // { path: "/models", component: <ModelsPage /> },
  { path: "/", component: <HomePage /> },
  { path: "/end-room", component: <EndRoomPage /> },
  { path: "/logout", component: <LogoutPage /> },
  {
    path: "/community/:communityId/:title",
    component: (
      <CommunityProvider>
        <CommunityPage />
      </CommunityProvider>
    ),
  },
  {
    path: "/community/membership/:communityId",
    component: (
      <CommunityProvider>
        <CommunityMemebershipPass />
      </CommunityProvider>
    ),
  },
  // { path : "/profile" , component : <ProfilePage />},
  // { path: "/pass", component: <PassPage /> },
  {
    path: "/event/:eventId/pass",
    component: (
      <SecureRoutes>
        <PassPage />
      </SecureRoutes>
    ),
  },
  // { path: "/", component: <HomePage /> }
];

export const secureRoutes = [];

export default routes;
