import React from "react";
import { useEffect } from "react";
import { MessageCard } from "../components/MessageCard";
import { MessageCardWithUpcomingEvent } from "../components/MessageCardWithUpcomingEvent";

export const LoginNeededPage = ({
  message = "Please login to access this event.",
}) => {

    useEffect(() => {
        document.getElementById('header-login-btn')?.click()
    }, [])
  return (
    <MessageCard type="Login">
      <h1 className="home-main-title">{message}</h1>
    </MessageCard>
  );
};
