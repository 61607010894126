import { Form, Formik } from "formik";
import React from "react";
import { ModalBody } from "reactstrap";
import closeIcon from "../../assets/images/icon/close-btn.svg";
import * as Yup from "yup";
import { AppInput } from "../../shared/formikBootstrap/Fields";
import { updateEmail } from "../../api/user";

const ValidateSchema = Yup.object().shape({
  email: Yup.string().required("email is required.").email("invalid email."),
});

const LinkEmailModal = ({ close }) => {
  const handleSubmit = async (values) => {
    // call api to link the email
    await updateEmail(values.email);
    close(values.email);
  };

  return (
    <>
      <ModalBody>
        <span
          role="button"
          className="float-end"
          onClick={() => close("close")}
        >
          <img src={closeIcon} className="img-fluid" alt="" />
        </span>
        <Formik
          validationSchema={ValidateSchema}
          initialValues={{
            email: "",
          }}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <Form onSubmit={props.handleSubmit}>
                <div className="register-me-modal email-link-modal">
                <div className="register-me-modal-content">
                  <h2>update your email address</h2>
                  <div className="w-100">
                  <AppInput
                    placeholder="email"
                    fieldProps={{ className: "text-center" }}
                    name="email"
                  />
                  </div>

                  <div className="mt-4 pt-3">
                    <button
                      type="submit"
                      disabled={props.isSubmitting}
                      className="btn btn-outline-custom navbar-btn btn-rounded"
                    >
                      register
                    </button>
                  </div>
                </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </>
  );
};

export default LinkEmailModal;
