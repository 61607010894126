import React from "react";
import { Spinner } from "reactstrap";


export const FullPageLoader = () => {
  return (
    <div className="full-page-loader">
      <Spinner animation="border" role="status" size="lg" />
    </div>
  );
};
