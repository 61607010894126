import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import badge from "../../assets/images/badge.png";
import EventTab from "./EventTab";
import CommunityTab from "./CommunityTab";
import AboutTab from "./AboutTab";
import { Link, useSearchParams } from "react-router-dom";
import { AuthorContext } from "../../context/AuthorContext";
import { kFormatter } from "../../utils/helper";
import RoundedImage from "../../components/RoundedImage";
import clevertap from "clevertap-web-sdk";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";
import AuthContext from "../../context/AuthContext";

const Section = () => {
  const { author } = useContext(AuthorContext);
  const { authUser } = useContext(AuthContext);
  // Tabs
  const [activeTab, setactiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const _type = searchParams.get("type");

  useEffect(() => {
    let eventName = "";

    if (_type === "event") {
      eventName = "events tapped";
    } else if (_type === "about") {
      eventName = "about tapped";
    } else {
      eventName = "communities tapped";
    }

    if (author?.id && authUser?.id && author?.id === authUser?.id) {
      clevertap.event.push(eventName, {
        Entity: AnalyticsConstant.PROFILE_SELF,
        Id: `${AnalyticsConstant.Profile}:${authUser?.id}`,
        Name: authUser?.full_name,
        Creator: authUser?.is_creator,
        FollowerCount: authUser?.follower_count,
        FollowingCount: authUser?.following_count,
        Email: authUser?.email,
        Verified: authUser?.is_verified,
        CurrentUserIdentity: authUser?.id,
        CurrentUserName: authUser?.full_name,
      });
    } else if (author?.id && authUser?.id && author?.id !== authUser?.id) {
      clevertap.event.push(eventName, {
        Entity: AnalyticsConstant.PROFILE_OTHER,
        Id: `${AnalyticsConstant.Profile}:${author?.id}`,
        Name: author?.full_name,
        Creator: author?.is_creator,
        FollowerCount: author?.follower_count,
        FollowingCount: author?.following_count,
        Email: author?.email,
        Verified: author?.is_verified,
        CurrentUserIdentity: authUser?.id,
        CurrentUserName: authUser?.full_name,
      });
    }
  }, [author?.id, authUser?.id, _type]);

  return (
    <React.Fragment>
      <Row className="mt-4 pt-5  mt-md-5 justify-content-center">
        <Col lg={10}>
          <Card className="rounded-16">
            <CardBody className="px-5 pt-3">
              <div className="profile-content text-center">
                <div className="profile-img">
                  <div className="profile-img-wrapper">
                    {/* <img
                      src={author?.image_url}
                      className="img-fluid profile-verified-border rounded-circle img-w-100"
                      alt=""
                    /> */}
                    <RoundedImage
                      isVerified={author?.is_verified}
                      imageUrl={author?.image_url}
                      radius={96}
                      title={author?.full_name}
                    />
                  </div>
                  {author?.is_verified ? (
                    <div className="profile-verified-icon">
                      <img src={badge} className="img-fluid icon" alt="" />
                    </div>
                  ) : null}
                </div>

                <h4 className="home-title fw-semibold mt-4 mt-md-5 mb-0 text-truncate">
                  {author?.full_name}
                </h4>
                <Link
                  to={`/${author?.username}`}
                  className="text-white home-title"
                >
                  @{author?.username}
                </Link>

                <p className="home-desc text-muted mt-3">
                  {kFormatter(author?.follower_count)} followers &nbsp; &nbsp; l
                  &nbsp; &nbsp; {kFormatter(author?.following_count)} following
                </p>
                <p className="home-desc text-muted mt-3 mb-0">{author?.bio}</p>
              </div>
            </CardBody>
          </Card>

          <Card className="mt-4">
            <CardBody className="profile-tab">
              <Nav
                className="nav nav-pills justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                      setSearchParams({ type: "community" });
                    }}
                  >
                    communities
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                      setSearchParams({ type: "event" });
                    }}
                  >
                    events
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                      setSearchParams({ type: "about" });
                    }}
                  >
                    about
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>

          <TabContent className="mt-4" activeTab={activeTab}>
            <TabPane tabId="1">
              <CommunityTab />
            </TabPane>
            <TabPane tabId="2">
              <EventTab />
            </TabPane>
            <TabPane tabId="3">
              <AboutTab />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
