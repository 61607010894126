import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

//import images
import badge from "../../assets/images/badge-sm.png";
import ico_facebook from "../../assets/images/icon/ico_facebook.svg";
import ico_twitter from "../../assets/images/icon/ico_twitter.svg";
import chain from "../../assets/images/icon/icon_link-chain.svg";
import { EventContext } from "../../context/EventContext";
import { displayDate, displayTime } from "../../utils/helper";
import { EventActionBtn } from "../../components/EventActionBtn";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  BestSuitedFor,
  BestSuitedForTag,
  InterestListOnCard,
  InterestTags,
  LanguageTag,
  LanguageTagList,
} from "../../components/CommunityInterest";
import RoundedImage from "../../components/RoundedImage";
import UserListCicle from "../../components/UserListCircle";
import AuthContext from "../../context/AuthContext";

const ByPassSection = () => {
  const { event } = useContext(EventContext);
  const { authUser } = useContext(AuthContext);
  const speakers = event?.speakers;
  const maxSpeakers = (speakers ? [...speakers].splice(0, 5) : []).filter(
    (u) => u.username
  );
  const url = window.location.toString();
  const navigate = useNavigate();
  const creator = speakers?.find((v) => v.id === event?.created_by);
  
  const shareMessage =
    event?.created_by === authUser?.id
      ? `check this event "${event.title}" I am hosting on cue club `
      : `check this event "${event.title}" by ${creator?.full_name} on cue club`;

  return (
    <React.Fragment>
      <Col lg={4} md={5}>
        <div className="mt-4 card-sidebar d-none d-md-block">
          <Card>
            <CardBody>
              <div>
                <Link to="#" className="time-tag mb-0">
                  {displayDate(event?.start_at)} &nbsp; &nbsp; l &nbsp; &nbsp;{" "}
                  {displayTime(event?.start_at)}
                </Link>

                <div className="mt-3">
                  <h4 className="home-title mb-0 fw-normal">{event?.title}</h4>
                </div>

                <div className="d-flex mt-3 mt-lg-4 align-items-center ">
                  <UserListCicle
                    className="nowrap"
                    users={speakers}
                    max={5}
                    radius={40}
                    showMoreUserHint={true}
                  />
                  <div className="flex-grow-1 ms-4">
                    <p className="mb-0 home-desc truncate-line-2">
                      {maxSpeakers?.map((speaker, index) => (
                        <span key={speaker.id}>
                          {index > 0 && index === maxSpeakers.length - 1
                            ? "and "
                            : null}
                          {speaker.full_name}
                          {index + 2 < maxSpeakers.length ? ", " : " "}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
                <div className="mt-4 mb-3">
                  <InterestTags
                    childClassName="mb-2 badge bg-dark me-2"
                    interests={event?.interest}
                  />
                  <BestSuitedForTag
                    childClassName="mb-2 badge bg-dark me-2"
                    bestSuitedFor={event?.best_suited_for}
                  />
                  <LanguageTag
                    childClassName="mb-2 badge bg-dark me-2"
                    language={event?.language}
                  />
                </div>
                <div className="text-center mb-2">
                  <EventActionBtn event={event} />
                </div>
              </div>
            </CardBody>

            <div className="card-footer">
              <div className="text-center p-2 p-lg-3">
                <ul className="list-inline social-img mb-0 mt-2">
                  <li className="list-inline-item">
                    <a
                      data-original-title={event.title}
                      href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${shareMessage}`}
                      target="_blank"
                      title="Facebook"
                    >
                      <img src={ico_facebook} alt="" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href={`https://twitter.com/share?text=${shareMessage}&url=${url} + "&hashtags=cue"`}
                      target="_blank"
                      title="Twitter"
                    >
                      <img src={ico_twitter} alt="" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <CopyToClipboard
                      text={url}
                      role="button"
                      onCopy={() => alert("Link copied")}
                    >
                      <img src={chain} alt="" />
                    </CopyToClipboard>
                  </li>
                </ul>
              </div>
            </div>
          </Card>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ByPassSection;
