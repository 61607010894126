import React from "react";
import { MessageCard } from "../components/MessageCard";

export const PrivateEventPage = ({
  message = "Looks like this is a private event, to join please get in touch with the creator.",
}) => {
  return (
    <MessageCard type="Private">
      <h1 className="home-main-title">{message}</h1>
    </MessageCard>
  );
};
