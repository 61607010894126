import { get, trim } from "lodash";
import {
  communityTransactionStatus,
  createCommunityOrder,
  createOrder,
  transactionStatus,
} from "../api/payment";

export const doPayment = (
  Razorpay,
  user,
  eventId,
  onOrderCreated,
  beforeTransaction,
  afterTransaction
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const eventType = "false";
      const orderResponse = await createOrder(eventId);
      const isorderCreated = get(orderResponse, "data.response", "");
      if (isorderCreated == "product sold out") {
        reject({ type: "error", message: "Pass sold out", code: "sold_out" });
        return;
      }

      if (typeof onOrderCreated === "function") {
        onOrderCreated(orderResponse);
      }

      const RazorpayOptions = {
        key: orderResponse.data.app_id,
        amount: orderResponse.data.price,
        currency: orderResponse.data.currency,
        name: orderResponse.data.company_name,
        description: orderResponse.data.product_name,
        // image: Logo,
        order_id: orderResponse.data.razorpay_order_id,
        handler: async (res) => {
          // console.log("res[:::]", res);
          if (typeof beforeTransaction === "function") {
            beforeTransaction();
          }
          try {
            const transResponse = await transactionStatus({
              order_id: orderResponse.data.id,
              transaction_id: res.razorpay_payment_id,
              entity_id: eventId,
              event_type: eventType,
              razorpay_signature: res.razorpay_signature,
            });

            const capturedData = get(transResponse, "data.item", []);
            if (capturedData.status == "CAPTURED") {
              resolve({ type: "success", message: "payment has been done." });
            } else {
              reject({
                type: "error",
                message: "Payment has been failed.",
                data: transResponse,
                code: "payment_failed",
              });
            }
          } catch (error) {
            console.log("Errro12", error);
            reject({
              type: "error",
              message: "Payment has been failed.",
              data: error,
              code: "payment_failed",
            });
          }

          if (typeof afterTransaction === "function") {
            afterTransaction();
          }
        },
        prefill: {
          name: user?.full_name,
          email: user?.email,
          contact: user?.mobile,
        },
        notes: {
          address: "Cue Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      console.log("RazorpayOptions:[Event]", RazorpayOptions);

      const rzpay = new Razorpay(RazorpayOptions);
      rzpay.on("payment.failed", function (response) {
        // console.log("payment.failed", response);

        reject({
          type: "error",
          message: "Payment Fail",
          code: "decline",
          data: response,
        });

        //   rzpay
      });

      rzpay.open();
    } catch (error) {
      reject({
        type: "error",
        message: error?.response?.data?.message || error.message,
        code: "fail_to_create_order",
        data: error.response.data,
      });
    }
  });
};

export const byCommunityMembership = (
  Razorpay,
  userData,
  eventId,
  plantId,
  onOrderCreated,
  beforeTransaction,
  afterTransaction
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const orderResponse = await createCommunityOrder(eventId, plantId);
      const isorderCreated = get(orderResponse, "data.response", "");

      if (isorderCreated == "product sold out") {
        reject({ type: "error", message: "Pass sold out", code: "sold_out" });
        return;
      }

      if (typeof onOrderCreated === "function") {
        onOrderCreated(orderResponse);
      }
      const orderModel = paymentOrderModel(orderResponse.data);

      const RazorpayOptions = {
        currency: orderModel.currency,
        key: orderModel.app_id,
        name: orderModel.company_name,
        description: orderModel.product_name,
        currency: orderModel.currency,
        amount: orderModel.price,
        // subscription_id: orderModel.subscription_id,
        order_id: orderModel.order_id,
        prefill: {
          email: userData.email,
          contact: userData.mobile,
          name: userData.full_name,
        },
        config: {
          display: {
            blocks: {
              banks: {
                name: "Pay using Google",
                instruments: [
                  {
                    method: "upi",
                    flows: ["intent"],
                    apps: ["google_pay"],
                  },
                  {
                    method: "upi",
                    flows: ["intent"],
                    apps: ["phonepe"],
                  },
                ],
              },
              other: {
                name: "Other Payment modes",
                instruments: [
                  {
                    method: "card",
                    issuers: ["ICIC"],
                  },
                  {
                    method: "netbanking",
                  },
                ],
              },
            },
            sequence: ["block.banks", "block.other"],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
        handler: async (res) => {
          console.log("res:[community-success]", res);
          if (typeof beforeTransaction === "function") {
            beforeTransaction();
          }
          try {
            const transResponse = await communityTransactionStatus({
              order_id: orderModel.id,
              transaction_id: res.razorpay_payment_id,
              razorpay_signature: res.razorpay_signature,
              entity_id: eventId,
              subscription_id: parseInt(orderModel.subscription_id),
            });
            const capturedData = get(transResponse, "data.item", []);
            if (capturedData.status == "CAPTURED") {
              resolve({ type: "success", message: "payment has been done." });
            } else {
              reject({
                type: "error",
                message: "Payment has been failed.",
                data: transResponse,
                code: "payment_failed",
              });
            }
            console.log("transResponse", transResponse);
          } catch (error) {
            reject({
              type: "error",
              message: "Payment has been failed.",
              data: error,
              code: "payment_failed",
            });
          }
          if (typeof afterTransaction === "function") {
            afterTransaction();
          }
        },
        prefill: {
          name: userData?.full_name,
          email: userData?.email,
          contact: userData?.mobile,
        },
        notes: {
          address: "Cue Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      console.log("RazorpayOptions:[Community]", RazorpayOptions);

      const rzpay = new Razorpay(RazorpayOptions);

      rzpay.on("payment.failed", function (response) {
        console.log("payment.failed", response);

        reject({
          type: "error",
          message: "Payment Fail",
          code: "decline",
          data: response,
        });

        //   rzpay
      });

      rzpay.open();
    } catch (error) {
      reject({
        type: "error",
        message: error?.response?.data?.message || error.message,
        code: "fail_to_create_order",
        data: error.response.data,
      });
    }
  });
};

const paymentOrderModel = (data) => {
  return {
    app_id: get(data, "app_id", -1),
    id: get(data, "id", -1),
    created_at: get(data, "created_at", 0),
    currency: get(data, "currency", "INR"),
    price: trim(get(data, "price", 0)),
    product_id: trim(get(data, "product_id", 0)),
    product_name: trim(get(data, "product_name", "")),
    company_name: trim(get(data, "company_name", "")),
    purchased_by: trim(get(data, "purchased_by", 0)),
    razorpay_order_id: trim(get(data, "razorpay_order_id", null)),
    receipt_id: trim(get(data, "receipt_id", "")),
    subscription_id: trim(get(data, "subscription_id", "")),
    order_id: trim(get(data, "razorpay_order_id", "")),
  };
};
