export const AnalyticsConstant = {
  //Screens

  OPENED: " opened",
  CLOSED: " closed",
  SIGN_IN_SCREEN: "signin screen",
  WELCOME_SCREEN: "welcome screen",
  OTP_SCREEN: "otp screen",
  SIGNUP_SCREEN: "signup screen",
  ONBOARDING_INTEREST: "onboarding interest",

  // NO data screens event need to be added

  // app.js
  APP_LAUNCHED: "app launched",

  // welcomescreen
  JOURNEY_STARTED: "journey started",
  WELCOME_SLIDER_MOVED: "welcome slider moved",

  // signin screen / mobile number screen
  COUNTRYCODE_BOTTOM_SHEET_OPEN: "country code bottom sheet open",
  COUNTRYCODE_SELECTED: "country code selected",
  COUNTRYCODE_SEARCHED: "country code searched",
  VERIFY_MOBILE_NUMBER: "verify mobile number",
  TERMS_AND_CONDITION_PRESSED: "terms and condition pressed",
  TERMS_AND_CONDITION_VIEWED: "terms and condition viewed",
  MOBILE_NUMBER_VALIDATION_FAILED: "mobile number validation failed",
  MOBILE_NUMBER_VALIDATION_ERROR: "mobile number validation error",
  OTP_SENT: "otp sent",
  LOGIN_INITIATED: "login initiated",

  // enter otp screen
  CONFIRM_OTP: "confirm otp",
  OTP_VERIFIED: "otp verified",
  // OTP_RECEIVED: 'otp received',
  OTP_INCORRECT: "otp incorrect",
  RESEND_OTP: "resend otp",
  RESENT_OTP: "resendt otp",
  RESEND_OTP_FAILED: "resend otp failed",

  // update name and image
  SIGNUP_PROFILE_UPDATE_STARTED: "signup profile update started",
  SIGNUP_PROFILE_COMPLETED: "signup profile completed",
  SIGNUP_PROFILE_UPDATE_VALIDATION_ERROR:
    "signup profile update validation error",
  SIGNUP_PROFILE_UPDATE_ERROR: "signup profile update error",
  SIGNUP_PROFILE_IMAGE_PICKER_PRESSED: "signup profile image picker pressed",
  SIGNUP_PROFILE_IMAGE_UPDATE_ERROR: "signup profile image update error",
  SIGNUP_PROFILE_IMAGE_UPDATE_STARTED: "signup profile image update started",
  SIGNUP_PROFILE_IMAGE_UPDATED: "signup  profile image updated",
  ///

  // OB_INTEREST_SELECTION_STARTED: 'onboarding interest selection started',
  OB_INTEREST_SELECTION_SKIPPED: "onboarding interest selection skipped",
  OB_INTEREST_SELECTION_CONTINUED: "onboarding interest selection continued",
  OB_INTEREST_SELECTED: "onboarding interest selected",
  OB_INTEREST_UNSELECTED: "onboarding interest unselected",
  OB_INTEREST_SELECTED_ALL: "onboarding interest selected all",
  OB_INTEREST_UNSELECTED_ALL: "onboarding interest unselected all",
  OB_INTEREST_UPDATE_ERROR: "onboarding interest update error",
  OB_INTEREST_UPDATE_COMPLETED: "onboarding interest update completed",

  FEATURED_COMMUNITIES_SCROLLED: "featured communities scrolled",
  FEATURED_COMMUNITIES_VIEW_ALL_OPENED: "featured communities view all opened",
  POPULAR_COMMUNITIES_SCROLLED: "popular communities scrolled",
  POPULAR_COMMUNITIES_VIEW_ALL_OPENED: "popular communities view all opened",

  FEATURED_EVENT_SCROLLED: "featured event scrolled",
  FEATURED_EVENT_VIEW_ALL_OPENED: "featured event view all opened",
  TRENDING_LIVE_EVENTS_SCROLLED: "trending live events scrolled",
  TRENDING_LIVE_EVENT_VIEW_ALL_OPENED: "trending event view all opened",

  TRENDING_CREATORS_SCROLLED: "trending creators scrolled",
  TRENDING_CREATOR_TAPPED: "trending creator tapped",
  BROWSE_INTEREST_TAPPED: "browse interest tapped",
  BROWSE_COLLECTION_TAPPED: "browse interest tapped",

  SEARCH_INITIATED: "search initiated",
  SEARCHED_TEXT: "searched text",
  SEARCHED_TAB_COMMUNITY_OPENED: "searched tab community opened",
  SEARCHED_TAB_event_OPENED: "searched tab event opened",
  SEARCHED_TAB_USER_OPENED: "searched tab user opened",

  SEARCHED_COMMUNITY_CARD_TAPPED: "searched tab community tapped",
  SEARCHED_EVENT_CARD_TAPPED: "searched tab event tapped",
  SEARCHED_USER_CARD_TAPPED: "searched tab user tapped",

  SEARCHED_COMMUNITY_SCROLLED: "searched tab community scrolled",
  SEARCHED_EVENT_SCROLLED: "searched tab event scrolled",
  SEARCHED_USER_SCROLLED: "searched tab user scrolled",

  COMMUNITY_CARD_TAPPED: "community card tapped",
  COMMUNITY_CARD_IMPRESSION: "community impression",
  COMMUNITY_DETAIL_VIEWED: "community detail viewed",

  COMMUNITY_BANNER_SCROLLED: "community banner scrolled",

  COMMUNITY_SHARE_TAPPED: "community share tapped",
  COMMUNITY_SHARE_COMPLETED: "share community completed",

  COMMUNITY_JOINING_INITIATED: "community joining initiated",
  COMMUNITY_JOINED: "community joined",

  COMMUNITY_PAYMENT_INITIATED: "community payment initiated",
  COMMUNITY_PAYMENT_SUCCESSFUL: "community payment successful",
  COMMUNITY_PAYMENT_FAILED: "community payment failed",
  COMMUNITY_PAYMENT_RETRY: "community payment retry",
  COMMUNITY_PAYMENT_CHANGE_PAYMENT_METHOD:
    "community payment change payment method",

  COMMUNITY_EVENT_VIEWED: "community event viewed",
  COMMUNITY_CREATOR_VIEWED: "community creator viewed",
  COMMUNITY_DESCRIPTION_VIEWED: "community description viewed",
  COMMUNITY_TERMS_AND_CONDITION_VIEWED: "community terms and condition viewed",

  COMMUNITY_DETAIL_MENU_TAPPED: "community detail menu tapped",
  COMMUNITY_MENU_TAPPED: "community menu tapped",
  COMMUNITY_MENU_VIEW_DETAIL: "community menu view detail",
  COMMUNITY_MENU_SHARE: "community menu share",
  COMMUNITY_MENU_REPORT: "community menu report",
  COMMUNITY_MENU_GO_TO_BROADCAST: "community menu go to broadcast",

  COMMUNITY_MENU_EDIT: "community menu edit",
  COMMUNITY_MENU_MEMBER: "community menu manage member",
  COMMUNITY_MENU_ADD_CO_CREATOR: "community menu add co creator",
  COMMUNITY_MENU_INSIGHTS: "community menu insights",
  COMMUNITY_MENU_SETTINGS: "community menu settings",
  COMMUNITY_MENU_SHARE: "community menu share",

  COMMUNITY_MENU_ENABLE_GROUP_CHAT: "community menu enable group chat",
  COMMUNITY_MENU_HIDE_MEMBER_LIST: "community menu hide member list",
  COMMUNITY_MENU_ALLOW_MEMBER_VIEW_PAST_BROADCAST:
    "community menu allow member view past broadcast",
  COMMUNITY_MENU_MUTE: "community menu mute",
  COMMUNITY_MENU_TYPE_CHANGE: "community menu type change",
  COMMUNITY_MENU_DELETE: "community menu delete",

  COMMUNITY_DELETE_INITIATED: "community delete initiated",
  COMMUNITY_DELETE_SUCCESSFUL: "community delete successful",
  COMMUNITY_DELETE_FAILED: "community delete failed",

  COMMUNITY_MENU_LEAVE: "community menu leave",
  COMMUNITY_MENU_LEAVE_REPORT: "community menu leave and report",

  COMMUNITY_MENU_INSIGHTS_TAPPED: "community menu insights tapped",
  COMMUNITY_INSIGHTS_OPENED: "community insights opened ",
  COMMUNITY_INSIGHTS_VIEWED: "community insights viewed ",

  COMMUNITY_MENU_MANAGE_MEMBERS: "community manage members",

  COMMUNITY_MENU_MANAGE_MEMBERS_OPENED: "community manage members opened",
  COMMUNITY_MENU_MANAGE_MEMBERS_ALL_TAB: "community manage members all tab ",
  COMMUNITY_MENU_MANAGE_MEMBERS_REQUEST_TAB:
    "community manage members request tab",
  COMMUNITY_MENU_MANAGE_MEMBERS_REQUEST_ACCEPTED:
    "community manage members request accept",
  COMMUNITY_MENU_MANAGE_MEMBERS_REQUEST_DISMISS:
    "community manage members request dismiss",

  COMMUNITY_MENU_MANAGE_MEMBER_REMOVE: "community manage member remove",
  COMMUNITY_MENU_MANAGE_MEMBER_REPORT_REMOVE:
    "community manage member report and remove",

  EVENT_CARD_TAPPED: "event card tapped",
  EVENT_CARD_IMPRESSION: "event impression",
  EVENT_DETAIL_VIEWED: "event detail viewed",
  EVENT_CREATOR_VIEWED: "event creator viewed",
  EVENT_TERMS_AND_CONDITION_VIEWED: "event terms and condition viewed",

  EVENT_SHARE_TAPPED: "event share tapped",
  EVENT_SHARE_COMPLETED: "event share completed",

  EVENT_VIEW_CALENDAR: "event view calendar",

  EVENT_SAVE: "event save",
  EVENT_JOIN: "event join",
  EVENT_START: "event start",
  EVENT_PAYMENT_INITIATED: "event payment initiated",
  EVENT_PAYMENT_SUCCESSFUL: "event payment successful",
  EVENT_PAYMENT_FAILED: "event payment failed",
  EVENT_PAYMENT_RETRY: "event payment retry",

  EVENT_MENU_VIEW_DETAILS: "event menu view details",
  EVENT_MENU_JOIN: "event menu join",
  EVENT_MENU_START: "event menu start",
  EVENT_MENU_RESTART: "event menu restart",
  EVENT_MENU_SAVE: "event menu save",
  EVENT_MENU_SHARE: "event menu share",
  EVENT_MENU_NOT_INTERESTED: "event menu not interested ",
  EVENT_MENU_REPORT_INITIATED: "event menu report initiated",
  EVENT_MENU_REPORT_COMPLETED: "event menu report completed",
  EVENT_MENU_REPORT_FAILED: "event menu report failed",
  EVENT_MENU_REMOVE: "event menu remove",
  EVENT_MENU_EDIT: "event menu edit",
  EVENT_MENU_CANCEL_INITIATED: "event menu cancel initiated",
  EVENT_MENU_CANCEL_COMPLETED: "event menu cancel completed",
  EVENT_MENU_CANCEL_FAILED: "event menu cancel failed",
  EVENT_MENU_VIEW_INSIGHTS: "event menu view insights",

  // EVENT_MENU_: 'event menu ',

  EVENT_TAB_VIEWED: "event tab viewed",
  EVENT_LIVE_TAB_VIEWED: "event live tab viewed",
  EVENT_UPCOMING_TAB_VIEWED: "event upcoming tab viewed",
  EVENT_PAST_TAB_VIEWED: "event past tab viewed",

  EVENT_LIVE_TAB_NO_DATA: "event live tab no data",
  EVENT_UPCOMING_TAB_NO_DATA: "event upcoming no data",
  EVENT_PAST_TAB_NO_DATA: "event past no data",

  NOTIFICATIONS_ALL_TAB_VIEWED: "notifications all tab viewed",
  NOTIFICATIONS_REQUEST_TAB_VIEWED: "notifications request tab viewed",
  NOTIFICATIONS_ALL_SCROLLED: "notifications all scrolled",
  NOTIFICATIONS_REQUEST_SCROLLED: "notifications request scrolled",

  NOTIFICATIONS_REQUEST_POSITIVE_BUTTON_PRESSED:
    "notifications request positive button pressed",
  NOTIFICATIONS_REQUEST_NEGATIVE_BUTTON_PRESSED:
    "notifications request negative button pressed",

  CHANNEL_VIEW: "channel viewed",
  CHANNEL_SCROLLED: "channel scrolled ",
  CHANNEL_CARD_TAPPED: "channel card tapped",
  CHANNEL_HEADER_TAPPED: "channel header tapped",
  CHANNEL_BROADCAST_TAB_VIEWED: "channel broadcast tab viewed",
  CHANNEL_CHAT_TAB_VIEWED: "channel chat tab viewed",
  CHANNEL_CREATOR_TAB_VIEWED: "channel creator tab viewed",

  CHANNEL_CHAT_TEXT_SENT: "channel chat text sent",
  CHANNEL_CHAT_BOTTOM_SHEET_OPENED: "channel chat bottom sheet opened",
  CHANNEL_CHAT_CAMERA_PHOTO_SENT: "channel chat camera photo sent ",
  CHANNEL_CHAT_GALLARY_PHOTO_SENT: "channel chat gallery photo sent ",
  CHANNEL_CHAT_CAMERA_VIDEO_SENT: "channel chat camera video sent ",
  CHANNEL_CHAT_GALLARY_VIDEO_SENT: "channel chat gallary video sent ",
  CHANNEL_CHAT_REACTION_SENT: "channel chat reaction sent",
  CHANNEL_CHAT_THREAD_STARTED: "channel chat thread started",

  CHANNEL_BROADCAST_TEXT_SENT: "channel broadcast text sent",
  CHANNEL_BROADCAST_BOTTOM_SHEET_OPENED:
    "channel broadcast bottom sheet opened",
  CHANNEL_BROADCAST_CAMERA_PHOTO_SENT: "channel broadcast camera photo sent ",
  CHANNEL_BROADCAST_GALLARY_PHOTO_SENT: "channel broadcast gallary photo sent ",
  CHANNEL_BROADCAST_CAMERA_VIDEO_SENT: "channel broadcast camera video sent ",
  CHANNEL_BROADCAST_GALLARY_VIDEO_SENT: "channel broadcast gallary video sent ",
  CHANNEL_BROADCAST_REACTION_SENT: "channel broadcast reaction sent",
  // CHANNEL_BROADCAST_THREAD_STARTED: 'channel broadcast thread started',

  PROFILE_VIEWED: "profile viewed",
  PROFILE_MENU_PRESSED: "profile viewed",
  PROFILE_PURCHASED: "profile purchases",
  PROFILE_Interests: "profile Interests",
  PROFILE_become_a_creator: "profile become a creator",

  PROFILE_SELF_PRIVACY: "profile self privacy",
  PROFILE_SELF_HELP: "profile self help",
  PROFILE_SELF_SETTINGS: "profile self settings",
  // PROFILE_SELF_EDIT: 'profile self edit',
  PROFILE_SELF_LOGOUT: "profile self logout",
  PROFILE_SELF_LOGOUT_SUCCESSFUL: "profile self logout successful",
  PROFILE_SELF_LOGOUT_FAILED: "profile self logout failed",

  PROFILE_SELF_PAYMENT_INFORMATION_FORM_INITIATED:
    "profile self payment information form initiated",
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_COMPLETED:
    "profile self  payment information form completed",
  PROFILE_SELF_PAYMENT_INFORMATION_FORM_FAILED:
    "profile self  payment information form failed",

  PROFILE_SELF_PAYMENT_INFORMATION_LIST_OPENED:
    "profile self payment information list opened",
  PROFILE_SELF_PAYMENT_INFORMATION_LIST_SCROLLED:
    "profile self payment information list scrolled",

  PROFILE_SELF_PAYMENT_WITHDRAW_FORM_INITIATED:
    "profile self payment withdraw form initiated",
  PROFILE_SELF_PAYMENT_WITHDRAW_FORM_COMPLETED:
    "profile self  payment withdraw form completed",
  PROFILE_SELF_PAYMENT_WITHDRAW_FORM_FAILED:
    "profile self  payment withdraw form failed",

  PROFILE_SELF_DASHBOARD_OPENED: "profile self dashboard opened",

  PROFILE_SELF_PAYMENT_INFORMATION_EDIT_FORM_INITIATED:
    "profile self payment information edit form initiated",
  PROFILE_SELF_PAYMENT_INFORMATION_EDIT_FORM_COMPLETED:
    "profile self  payment information edit form completed",
  PROFILE_SELF_PAYMENT_INFORMATION_EDIT_FORM_FAILED:
    "profile self  payment information edit form failed",

  PROFILE_SELF_EVENT_RECORDING_LIST_OPENED:
    "profile self event recording list opened",
  PROFILE_SELF_EVENT_RECORDING_LIST_SCROLLED:
    "profile self event recording list scrolled",
  PROFILE_SELF_EVENT_RECORDING_PLAYED: "profile self event recording played",
  PROFILE_SELF_EVENT_RECORDING_DOWNLOADED:
    "profile self event recording downloaded",
  PROFILE_SELF_EVENT_RECORDING_SHARED: "profile self event recording shared",

  PROFILE_SELF_CREATOR_FORM_INITIATED: "profile self creator form initiated",
  PROFILE_SELF_CREATOR_FORM_COMPLETED: "profile self  creator form completed",
  PROFILE_SELF_CREATOR_FORM_FAILED: "profile self  creator form failed",

  PROFILE_SELF_EDIT_INITIATED: "profile self edit initiated",
  PROFILE_SELF_EDIT_COMPLETED: "profile self edit completed",
  PROFILE_SELF_EDIT_FAILED: "profile self edit failed",

  PROFILE_SELF_DELETE_ACCOUNT_INITIATED:
    "profile self delete account initiated",
  PROFILE_SELF_DELETE_ACCOUNT_COMPLETED:
    "profile self delete account completed",
  PROFILE_SELF_DELETE_ACCOUNT_FAILED: "profile self delete account failed",

  PROFILE_SELF_DEACTIVATE_ACCOUNT_INITIATED:
    "profile self deactivate account initiated",
  PROFILE_SELF_DEACTIVATE_ACCOUNT_COMPLETED:
    "profile self deactivate account completed",
  PROFILE_SELF_DEACTIVATE_ACCOUNT_FAILED:
    "profile self deactivate account failed",

  PROFILE_SELF_DEACTIVATE_ACCOUNT_INITIATED:
    "profile self deactivate account initiated",
  PROFILE_SELF_DEACTIVATE_ACCOUNT_COMPLETED:
    "profile self deactivate account completed",
  PROFILE_SELF_DEACTIVATE_ACCOUNT_FAILED:
    "profile self deactivate account failed",

  PROFILE_SELF_CHANGE_NUMBER_INITIATED: "profile self change number initiated",
  PROFILE_SELF_CHANGE_NUMBER_COMPLETED: "profile self change number completed",
  PROFILE_SELF_CHANGE_NUMBER_FAILED: "profile self change number failed",

  PROFILE_SELF_BLOCKED_ACCOUNTS_TAPPED: "profile self blocked accounts tapped",
  PROFILE_SELF_BLOCKED_ACCOUNTS_VIEWED: "profile self blocked accounts viewed",
  PROFILE_SELF_BLOCKED_ACCOUNTS_SCROLLED:
    "profile self blocked accounts scrolled",
  PROFILE_UNBLOCKED: "profile unblocked",

  PROFILE_SELF_Turn_off_limit_notifications:
    "profile_self Turn off limit notifications",
  PROFILE_SELF_Turn_off_limit_notifications:
    "profile self Turn off limit notifications",

  PROFILE_SELF_Turn_on_all_notifications:
    "profile_self Turn on all notifications",
  PROFILE_SELF_Turn_on_all_notifications:
    "profile self Turn on all notifications",

  PROFILE_SELF_BLOCKED_ACCOUNTS_VIEWED: "profile self blocked accounts viewed",
  PROFILE_SELF_BLOCKED_ACCOUNTS_SCROLLED:
    "profile self blocked accounts scrolled",

  PROFILE_INTEREST_SELECTION_STARTED: "profile self interest selection started",
  PROFILE_INTEREST_SELECTION_SKIPPED: "profile self interest selection skipped",
  PROFILE_INTEREST_SELECTION_CONTINUED:
    "profile self interest selection continued",
  PROFILE_INTEREST_SELECTED: "profile self interest selected",
  PROFILE_INTEREST_UNSELECTED: "profile self interest unselected",
  PROFILE_INTEREST_SELECTED_ALL: "profile self interest selected all",
  PROFILE_INTEREST_UNSELECTED_ALL: "profile self interest unselected all",
  PROFILE_INTEREST_UPDATE_ERROR: "profile self interest update error",
  PROFILE_INTEREST_UPDATE_COMPLETED: "profile self interest update completed",

  PROFILE_OTHER_REPORT_INITIATED: "profile other report initiated",
  PROFILE_OTHER_REPORT_COMPLETED: "profile other report completed",
  PROFILE_OTHER_REPORT_FAILED: "profile other report failed",

  PROFILE_OTHER_BLOCK_INITIATED: "profile other block initiated",
  PROFILE_OTHER_BLOCK_COMPLETED: "profile other block completed",
  PROFILE_OTHER_BLOCK_FAILED: "profile other block failed",

  PROFILE_OTHER_SHARE_INITIATED: "profile other share initiated",
  PROFILE_OTHER_SHARE_COMPLETED: "profile other share completed",
  PROFILE_OTHER_SHARE_FAILED: "profile other share failed",

  PROFILE_SELF_PURCHASES_VIEWED: "profile self purchased viewed",
  PROFILE_SELF_PURCHASES_SCROLLED: "profile self purchased scrolled",
  PROFILE_SELF_PURCHASES_BOTTOM_SHEET: "profile self purchased bottom sheet",

  PROFILE_SELF_TRANSACTIONS: "profile self transactions",
  PROFILE_SELF_SUBSCRIPTIONS: "profile self subscriptions",

  PROFILE_SELF_TRANSACTIONS_SCROLLED: "profile self transactions scrolled",
  PROFILE_SELF_SUBSCRIPTIONS_SCROLLED: "profile self subscriptions scrolled",

  PROFILE_SELF_TRANSACTIONS_DETAIL_VIEWED:
    "profile self transactions detail viewed",
  PROFILE_SELF_SUBSCRIPTIONS_DETAIL_VIEWED:
    "profile self subscriptions detail viewed",

  // PROFILE_SELF_NO_TRANSACTIONS: 'profile self no transactions',
  // PROFILE_SELF_NO_SUBSCRIPTIONS: 'profile self no subscriptions',
  // PROFILE_SELF_NO_PURCHASES: 'profile self no purchased',

  PROFILE_COMMUNITY: "profile community tab",
  PROFILE_EVENT: "profile event tab",
  PROFILE_ABOUT_TAB: "profile about tab",

  CREATE_PRESSED: "create pressed",
  CREATE_LIVE_ROOM_PRESSED: "create live room pressed",
  CREATE_EVENT_PRESSED: "create event pressed",
  CREATE_COMMUNITY_PRESSED: "create community pressed",

  // ==============================================
  CREATE_LIVE_ROOM_CREATED: "create live room created",

  CREATE_EVENT_CREATED: "create event created",
  CREATE_COMMUNITY_CRAETED: "create community created",

  CREATE_EVENT_CANCELLED: "create event cancelled",
  CREATE_COMMUNITY_CANCELLED: "create community cancelled",

  CREATE_COMMUNITY_ADD_CO_CREATOR_OPENED:
    "create community add co-creator opened",
  CREATE_COMMUNITY_ADD_CO_CREATOR_SCROLLED:
    "create community add co-creator scrolled",
  CREATE_COMMUNITY_ADD_CO_CREATOR_ADDED:
    "create community add co-creator added",
  CREATE_COMMUNITY_ADD_CO_CREATOR_REMOVED:
    "create community add co-creator removed",

  CREATE_EVENT_SPEAKER_OPENED: "create event add speaker opened",
  CREATE_EVENT_ADD_SPEAKER_SCROLLED: "create event add speaker scrolled",
  CREATE_EVENT_ADD_SPEAKER_ADDED: "create event add speaker added",
  CREATE_EVENT_ADD_SPEAKER_REMOVED: "create event add speaker removed",

  USER_FOLLOW: "user follow",
  USER_UNFOLLOW: "user unfollow",
};
