import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { CommunityContext } from "../../context/CommunityContext";
import { eventTitleInUrl } from "../../utils/app-utils";
import CommunityDescription from "../CommunityPage/CommunityDescription";
import CummunitySection from "./CummunitySection";
import Download from "./Download";

const CommunityMemebershipPass = () => {
  const { community } = useContext(CommunityContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (community?.id && !community?.is_member) {
      navigate(
        `/community/${community?.id}/${eventTitleInUrl(community?.title)}`
      );

      return;
    }

    if (community?.id && community?.is_member && typeof  window.fbq === 'function') {
      window.fbq("track", "Purchase");
    }
  }, [community?.id, community?.title, community?.is_member]);

  return (
    <React.Fragment>
      <Navbar />
      <section className="home-contain pb-4 pb-lg-5">
        <Container>
          {/* <SubscriptionStatus /> */}
          <CummunitySection />
          <Row>
            <Col lg={8}>
              <div className="banner-box">
                <CommunityDescription />
                <Download />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer showFooterBottomBar={false} />
    </React.Fragment>
  );
};

export default CommunityMemebershipPass;
