import React, { useContext } from "react";
import { Card, CardBody } from "reactstrap";

import { AuthorContext } from "../../context/AuthorContext";
import { EventContext } from "../../context/EventContext";
import { TermConditions } from "../../components/TermConditions";
import { CreatorCard } from "../../components/CreatorCard";
import ParseHtml from "../../components/ParseHtml";
import Download from "../PassPage/Download";

const About = () => {
  const { author } = useContext(AuthorContext);
  const { event } = useContext(EventContext);
  return (
    <React.Fragment>
      <div className="mt-4 pt-2">
        <p className="home-heading text-lowercase">about event</p>
        <Card>
          <CardBody>
            <p className="home-desc text-muted mb-0 parse">
              <ParseHtml text={event?.description} />
            </p>
          </CardBody>
        </Card>
      </div>
      {/* <div className="mt-4 pt-2">
        <p className="home-heading text-lowercase">creators</p>
        <CreatorCard author={author} />
      </div> */}

      <TermConditions />
      <Download />
    </React.Fragment>
  );
};

export default About;
