import request from "../config/request";

export const communityEvents = (communityId) => {
  return request.get("api:ih5W24rc/community-events", {
    params: { community_id: communityId },
  });
};

export const getCommunity = (communityId) => {
  return request.get("api:ih5W24rc/community/" + communityId);
};

export const requestToJoinCommunity = (communityId) => {
  return request.post("api:ih5W24rc/community-request", {
    community_id: communityId,
  });
};
