
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCookie } from "../utils/cookie";

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    deleteCookie("AUTH-TOKEN");
    navigate("/");
  }, [navigate]);

  return null;
};

export default LogoutPage;
