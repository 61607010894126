import React, { useCallback, useContext, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { getEventCalendar } from "../api/event";
import { EventItem } from "../pages/ProfilePage/EventTab";
import WelcomeImage from "../assets/images/welcome_image.png";
import AuthContext from "../context/AuthContext";
import { useSearchParams } from "react-router-dom";


const UpcomingEvent = () => {
  const [events, setEvents] = useState([]);
  const [busy, setBusy] = useState(false);

  const { authUser } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const eventId = searchParams.get("last_joined_event");
  const canJoinAgain = searchParams.get("can_join_again");

  console.log("canJoinAgain", canJoinAgain, eventId);

  useEffect(() => {
    setBusy(true);
    getEventCalendar()
      .then((response) => {
        const currentTime = new Date().getTime();
        setEvents(
          response?.data?.items?.filter((item) => item.start_at >= currentTime || item.is_live)
        );
        setTimeout(() => setBusy(false), 0);
      })
      .catch(() => {
        setBusy(false);
      });
  }, [authUser?.id]);


  return (
    <div className="upcoming-event-calendar">
      <Row>
        {events.map((event) => (
          <EventItem event={event} key={`${event.id}_event`} />
        ))}
        {!busy && !events.length ? (
          <div className="col-md-12">
            <img
              src={WelcomeImage}
              className="img-fluid"
              alt="no upcoming event found"
            />
          </div>
        ) : null}
      </Row>
    </div>
  );
};

export default UpcomingEvent;
