import { useEffect } from "react";

const DownloadAppBanner = () => {
  // useEffect(() => {
  //   window?.AF("banners", "showBanner");
  // }, []);

  return null;
};

export default DownloadAppBanner;
