import React from "react";
import PropTypes from "prop-types";
import { Form, FormControl } from "react-bootstrap";
import { AppInput } from "./Fields";

const POSITIVE_REGEX = /^[0-9]{1,6}(((\.)[0-9]{1,2})|\.)?$/g;
const NEGATIVE_REGEX = /^-?[0-9]{1,6}(((\.)[0-9]{1,2})|\.)?$/g;

class MoneyField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  toCurrency(number) {
    const { inputFormatLang, inputFormatOptions } = this.props;
    const formattedValue = parseFloat(
      inputFormatOptions.style === "percent" ? number / 100 : number
    ).toLocaleString(inputFormatLang, inputFormatOptions);
    return number && formattedValue ? formattedValue : "";
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing });
  }
  handleKeyPress(e) {
    const { selectionEnd, value, selectionStart } = e.currentTarget;
    const { allowNegative } = this.props;
    const regex = allowNegative ? NEGATIVE_REGEX : POSITIVE_REGEX;

    const onlyNumberRegex = new RegExp(/^[0-9]$/);
    const isOnlyNumber = !!onlyNumberRegex.exec(e.key);
    const expectedValue = this.expectedValue(
      value,
      e.key,
      selectionStart,
      selectionEnd
    );

    const allowKeys = ["."];
    if (allowNegative) {
      allowKeys.push("-");
    }

    if (
      !(
        (isOnlyNumber || allowKeys.includes(e.key)) &&
        expectedValue.toString().match(regex)
      )
    ) {
      e.preventDefault();
    }
  }
  handlePaste(e) {
    const { selectionEnd, value, selectionStart } = e.currentTarget;
    const copiedData = e.clipboardData.getData("Text");
    const { allowNegative } = this.props;
    const regex = allowNegative ? NEGATIVE_REGEX : POSITIVE_REGEX;

    const expectedValue = this.expectedValue(
      value,
      copiedData,
      selectionStart,
      selectionEnd
    );
    if (expectedValue && !expectedValue.toString().match(regex)) {
      e.preventDefault();
    }
  }
  expectedValue(value, newlyValue, selectionStart, selectionEnd) {
    let startValue = value.toString().slice(0, selectionStart);
    let endValue = value.toString().slice(selectionEnd);
    return startValue + newlyValue.toString() + endValue;
  }

  render() {
    const {
      inputFormatOptions,
      isAlignRight = true,
      inputFormatLang,
      allowNegative,
      ...restProps
    } = this.props;
    return (
      <div className={isAlignRight ? "text-right-container w-100" : "w-100"}>
        {this.state.isEditing ? (
          <Form.Control
            {...restProps}
            type="text"
            onPaste={this.handlePaste.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)}
            as="input"
            autocomplete="new-password"
            onBlur={(e) => {
              this.toggleEditing();
              if (typeof restProps.onBlur === "function") {
                restProps.onBlur(e);
              }
            }}
          />
        ) : (
          <Form.Control
            {...restProps}
            type="text"
            onFocus={this.toggleEditing.bind(this)}
            readOnly
            as="input"
            className="number-field"
            autocomplete="new-password"
            value={this.toCurrency(this.props.value)}
          />
        )}
      </div>
    );
  }
}

MoneyField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  allowNegative: PropTypes.bool,
  onChange: PropTypes.func,
  inputFormatLang: PropTypes.string,
  inputFormatOptions: PropTypes.object,
};
MoneyField.defaultProps = {
  inputFormatLang: "en-IN",
  inputFormatOptions: {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  },
  scale: 2,
  allowNegative: false,
};

class AppNumberField extends MoneyField {
  handleKeyPress(e) {
    const onlyNumberRegex = new RegExp(/^[0-9]$/);
    const isOnlyNumber = !!onlyNumberRegex.exec(e.key);
    if (!isOnlyNumber) {
      e.preventDefault();
    }
  }
  handlePaste = (e, isdragEvent = false) => {
    const { selectionEnd, value, selectionStart } = e.currentTarget;
    const copiedData = isdragEvent
      ? e.dataTransfer.getData("text")
      : e.clipboardData.getData("Text");

    const expectedValue = this.expectedValue(
      value,
      copiedData,
      selectionStart,
      selectionEnd
    );
    if (expectedValue && !expectedValue.toString().match(/^[0-9]+$/g)) {
      e.preventDefault();
    }
  };
  render() {
    const {
      isAlignRight = true,
      inputFormatOptions,
      inputFormatLang,
      ...restProps
    } = this.props;
    return (
      <AppInput
        {...restProps}
        type="text"
        onDrop={(e) => this.handlePaste(e, true)}
        onPaste={this.handlePaste}
        onKeyPress={this.handleKeyPress.bind(this)}
      />
    );
  }
}

class NumberField extends AppNumberField {
  render() {
    const { inputFormatOptions, inputFormatLang, ...restProps } = this.props;
    return (
      <FormControl
        {...restProps}
        type="text"
        onDrop={(e) => this.handlePaste(e, true)}
        onPaste={this.handlePaste}
        onKeyPress={this.handleKeyPress.bind(this)}
      />
    );
  }
}
export class AppFloatField extends MoneyField {
  render() {
    const {
      isAlignRight = true,
      inputFormatOptions,
      inputFormatLang,
      scale,
      ...restProps
    } = this.props;
    return (
      <AppInput
        {...restProps}
        type="text"
        onDrop={(e) => this.handlePaste(e, true)}
        onPaste={this.handlePaste}
        onKeyPress={this.handleKeyPress.bind(this)}
      />
    );
  }
}
export class FloatField extends MoneyField {
  render() {
    const {
      isAlignRight = true,
      inputFormatOptions,
      inputFormatLang,
      scale,
      ...restProps
    } = this.props;
    return (
      <FormControl
        {...restProps}
        type="text"
        onDrop={(e) => this.handlePaste(e, true)}
        onPaste={this.handlePaste}
        onKeyPress={this.handleKeyPress.bind(this)}
      />
    );
  }
}

export { MoneyField, AppNumberField, NumberField };
