import React, { useEffect } from "react";
import routes from "./allRoutes";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import DynamicPage from "../pages/DynamicPage";
import { AppModalProvider } from "../shared/modal/AppModalProvider";
import { AuthorProvider } from "../context/AuthorContext";
import { EventProvider } from "../context/EventContext";
import { RazorPayProvider } from "../context/RazorPayContext";
import AuthProvider from "../context/AuthContext/AuthProvider";
import MasterDataProvider from "../context/MasterData/MasterDataProvider";
import { PaymentFullPageLoader } from "../components/PaymentFullPageLoader";

const Index = () => {
  return (
    <React.Fragment>
      <Router>
        <AuthProvider>
          <MasterDataProvider>
            <AuthorProvider>
              <EventProvider>
                <RazorPayProvider>
                  <PaymentFullPageLoader />
                  <ScrollTop>
                    <Routes>
                      {routes.map((route, idx) => (
                        <Route
                          path={route.path}
                          element={route.component}
                          key={idx}
                        />
                      ))}
                      <Route path="*" element={<DynamicPage />} />
                    </Routes>
                  </ScrollTop>
                  <AppModalProvider />
                </RazorPayProvider>
              </EventProvider>
            </AuthorProvider>
          </MasterDataProvider>
        </AuthProvider>
      </Router>
    </React.Fragment>
  );
};

export default Index;

const ScrollTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};
