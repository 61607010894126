import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Footer from "../components/Footer";
import { MessageCardWithUpcomingEvent } from "../components/MessageCardWithUpcomingEvent";
import Navbar from "../components/Navbar";
import UpcomingEvent from "../components/UpcomingEvent";
import { AppModal } from "../shared/modal/app-modal-utils";
import { ModalClasses } from "../utils/app-utils";
import { getCookie } from "../utils/cookie";
import { isLogin } from "../utils/helper";
import LoginModal from "./ModelsPage/LoginModal";

const EndRoomPage = () => {
  const [searchParams] = useSearchParams();

  const eventId = searchParams.get("last_joined_event");
  const creator = searchParams.get("creator");
  const eventTitle = searchParams.get("eventTitle");
  const canJoinAgain = searchParams.get("can_join_again");

  const handleJoinAgainEvent = useCallback(
    async (e) => {
      e.stopPropagation();
      if (!isLogin()) {
        await AppModal.open(
          LoginModal,
          {
            showFooter: false,
            showCloseBtn: false,
            className: ModalClasses,
            centered: true,
          }
          //   { event }
        );
      }

      const uri = process.env.REACT_APP_AUTH_TOKEN_REQUIRE_TO_JOIN
        ? `/room-join-request/${eventId}?token=${getCookie("AUTH-TOKEN")}&path=/${creator}/${eventId}/${eventTitle}`
        : `/preview/${creator}/${eventId}/${eventTitle}`;

      window.location.href = `${process.env.REACT_APP_WEB_ROOM}${uri}`;
    },
    [eventId, creator, eventTitle]
  );

  return (
    <MessageCardWithUpcomingEvent>
      <>
        <h1 className="home-main-title">
          hello! <br /> We hope you’re enjoying <br /> your time on cue! 
        </h1>
        <p className="home-main-para">
          home to interesting conversations with <br /> creators and communities
          you admire.
        </p>

        {canJoinAgain ? (
          <button onClick={handleJoinAgainEvent} className="btn home-btn mt-36">
            Join Again
          </button>
        ) : (
          <button className="btn home-btn mt-36">share feedback</button>
        )}
      </>
    </MessageCardWithUpcomingEvent>
  );
};

export default EndRoomPage;
