import React, { useState, useEffect } from "react";
import { EventManager } from "./event-manager";
import { Modal, Button, Spinner } from "react-bootstrap";
import { renderDynamicComponent } from "../../utils/app-utils";
import { getAllPropsExcept, getOnlyProps } from "./app-modal-utils";

export class AppModalConsumer extends React.PureComponent {
  handleModalOpen(props) {
    let out;
    switch (props.appModalType) {
      case "confirm":
        out = this.openConfirmBox(props);
        break;
      case "message":
        this.openMessageBox(props);
        break;
      case "sideDrawer":
        out = this.openSideDrawer(props);
        break;
      case "modal":
        out = this.openModal(
          props.message,
          props.modalProps,
          props.messageComponentProps
        );
        break;
      case "loader":
        out = this.openModal(
          props.message,
          props.modalProps,
          props.messageComponentProps,
          false
        );
        break;
      default:
        break;
    }
    return out;
  }

  openConfirmBox(props) {
    return new Promise((resolve, reject) => {
      const func = (reason) => {
        reason === "close" ? reject(reason) : resolve(reason);
      };
      const componentProps = [
        "message",
        "confirmFnc",
        "title",
        "waitLabel",
        "okBtnLabel",
        "cancelBtnLabel",
        "appModalType",
        "showInBody",
        "showFooter",
        "showTitle",
        "icon",
      ];
      this.props.open(
        ModalConfirmConponent,
        {
          backdrop: "static",
          ...getAllPropsExcept(props, componentProps),
        },
        { ...getOnlyProps(props, componentProps), resolve },
        func
      );
    });
  }
  openMessageBox(props) {
    const componetProps = [
      "message",
      "title",
      "btnLabel",
      "close",
      "appModalType",
    ];
    this.props.open(
      ModalMessageConponent,
      getAllPropsExcept(props, componetProps),
      getOnlyProps(props, componetProps)
    );
  }
  openModal(
    messageComponent,
    modalProps,
    messageComponentProps,
    returnPromise = true
  ) {
    if (returnPromise) {
      return new Promise((resolve, reject) => {
        const func = (reason) => {
          reason === "close" ? reject(reason) : resolve(reason);
        };
        this.props.open(
          messageComponent,
          modalProps,
          messageComponentProps,
          func
        );
      });
    } else {
      return this.props.open(
        messageComponent,
        modalProps,
        messageComponentProps
      );
    }
  }
  openSideDrawer(props) {
    return new Promise((resolve, reject) => {
      const func = (reason) => {
        reason === "close" ? reject(reason) : resolve(reason);
      };

      this.props.open(
        ModalSideDrawer,
        { dialogAs: "div", backdrop: false },
        props,
        func
      );
    });
  }
  componentDidMount() {
    EventManager.on("app-open-modal", this.handleModalOpen.bind(this));
  }
  componentWillUnmount() {
    EventManager.off("app-open-modal", this.handleModalOpen);
  }

  render() {
    return null;
  }
}

const ModalConfirmConponent = ({
  message,
  confirmFnc,
  busy,
  close,
  title,
  icon,
  waitLabel = (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  ),
  okBtnLabel = <i className="bx bx-check"></i>,
  cancelBtnLabel = <i className="bx bx-x"></i>,
  isBusy,
  showInBody,
  showFooter = true,
  showTitle = true,
}) => {
  const fnc = () =>
    typeof confirmFnc === "function"
      ? confirmFnc({
          close: () => {
            busy(false);
            close("success");
          },
          busy,
        })
      : close("success");
  return (
    <>
      {title && showTitle ? (
        <Modal.Header closeButton>
          <Modal.Title>{renderDynamicComponent(title)}</Modal.Title>
        </Modal.Header>
      ) : null}
      {showInBody ? (
        <Modal.Body>
          {renderDynamicComponent(message, {
            ok: fnc,
            close,
            isBusy,
            busy,
            title,
            icon,
          })}
        </Modal.Body>
      ) : (
        renderDynamicComponent(message, {
          ok: fnc,
          close,
          isBusy,
          busy,
          title,
          icon,
        })
      )}
      {showFooter ? (
        <Modal.Footer>
          <Button
            disabled={isBusy}
            variant="secondary"
            onClick={() => close("cancel")}
          >
            {cancelBtnLabel}
          </Button>
          <Button disabled={isBusy} variant="primary" onClick={fnc}>
            {isBusy ? waitLabel : okBtnLabel}
          </Button>
        </Modal.Footer>
      ) : null}
    </>
  );
};

const ModalMessageConponent = ({ message, title, btnLabel = "Ok", close }) => {
  return (
    <>
      {title ? (
        <Modal.Header closeButton>
          <Modal.Title>{renderDynamicComponent(title)}</Modal.Title>
        </Modal.Header>
      ) : null}

      <Modal.Body>{renderDynamicComponent(message)}</Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="primary" onClick={close}>
          {btnLabel}
        </Button>
      </Modal.Footer>
    </>
  );
};

const ModalSideDrawer = ({ message, hidden, close }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const showMe = function () {
      setShow(true);
    };
    showMe();
  }, []);
  return (
    <div className={`customizer ${!hidden && show ? "open" : ""}`}>
      <button className="customizer-close link" onClick={close}>
        <i className="bx bx-x"></i>
      </button>
      <div className="customizer-content p-4 ps ps--active-y">
        {renderDynamicComponent(message)}
      </div>
    </div>
  );
};
