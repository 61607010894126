import { isArray, template } from "lodash";

export function mapPropsEvent(props, input, helper) {
  let onlyInputProps = { ...props, ...input };

  onlyInputProps.onChange = (e) => {
    let event = e;
    if (
      (e && typeof e === "object" && !e.hasOwnProperty("currentTarget")) ||
      !e ||
      typeof e === "string" ||
      typeof e === "number" ||
      typeof e === "boolean"
    ) {
      event = { currentTarget: { value: e, name: input.name } };
    }
    if (
      event &&
      typeof event === "object" &&
      event.currentTarget.type === "file"
    ) {
      const files = [];
      for (let i = 0; i < event.currentTarget.files.length; i++) {
        files.push(event.currentTarget.files[i]);
      }
      event = {
        currentTarget: {
          value: onlyInputProps.multiple
            ? [...(onlyInputProps.value ? onlyInputProps.value : []), ...files]
            : files[0],
          name: input.name,
          type: "file",
        },
      };
    }
    input.onChange(event);

    if (props.onChange && typeof props.onChange === "function") {
      props.onChange(e, helper);
    }
  };
  if (props.onBlur) {
    onlyInputProps.onBlur = (e) => {
      input.onBlur(e);
      props.onBlur(e);
    };
  }
  return onlyInputProps;
}
export function isImage(dataURL) {
  try {
    const mimeType = dataURL.split(",")[0].split(":")[1].split(";")[0];
    return !!mimeType.match("image.*");
  } catch (e) {
    return false;
  }
}
export function fileValidation(
  file,
  {
    maxFileSize,
    minFileSize,
    acceptedFiles,
    minImageDimensions,
    maxImageDimensions,
    exactImageDimensions,
  },
  message
) {
  if (!(file instanceof File || file instanceof Blob)) {
    return;
  }

  let maxFileSizeBytes = maxFileSize ? maxFileSize * 1000000 : null;
  let minFileSizeBytes = minFileSize ? minFileSize * 1000000 : null;
  let acceptedFilesArr = acceptedFiles
    ? isArray(acceptedFiles)
      ? acceptedFiles
      : acceptedFiles.split(",")
    : null;
  const errors = [];
  return new Promise(function (resolve) {
    let fReader = new FileReader();

    fReader.onload = function (e) {
      let attribues = {
        maxFileSize,
        minFileSize,
        fileSize: parseFloat(e.total / 1000000).toFixed(2),
        acceptedFiles: acceptedFiles,
      };

      /**
       * Checking file extensions
       */
      if (acceptedFilesArr && !checkFileExtensions(acceptedFilesArr, file)) {
        errors.push(generateErrorMessage("acceptedFiles", attribues, message));
      }
      /**
       * Checking file size for max size validation
       */
      if (maxFileSizeBytes && e.total > maxFileSizeBytes) {
        errors.push(generateErrorMessage("maxFileSize", attribues, message));
      }
      /**
       * Checking file size for min validation
       */
      if (minFileSizeBytes && e.total < minFileSizeBytes) {
        errors.push(generateErrorMessage("minFileSize", attribues, message));
      }
      /**
       * Checking File type is Image
       */
      if (isImage(e.target.result)) {
        let img = new Image();
        img.onload = function (imgEvent) {
          const imgE = imgEvent.target ? imgEvent.target : imgEvent.path[0];
          /**
           * Min Dimensions validation
           */
          if (
            minImageDimensions &&
            minImageDimensions[0] &&
            !minImageDimensions[1] &&
            imgE.width < minImageDimensions[0]
          ) {
            attribues.minImageWidth = minImageDimensions[0];
            attribues.imageWidth = imgE.width;
            errors.push(
              generateErrorMessage("minImageWidth", attribues, message)
            );
          } else if (
            minImageDimensions &&
            minImageDimensions[1] &&
            !minImageDimensions[0] &&
            imgE.height < minImageDimensions[1]
          ) {
            attribues.minImageHeight = minImageDimensions[1];
            attribues.imageHeight = imgE.height;
            errors.push(
              generateErrorMessage("minImageHeight", attribues, message)
            );
          } else if (
            minImageDimensions &&
            minImageDimensions[1] &&
            minImageDimensions[0] &&
            (imgE.height < minImageDimensions[1] ||
              imgE.width < minImageDimensions[0])
          ) {
            attribues.minImageHeight = minImageDimensions[1];
            attribues.minImageWidth = minImageDimensions[0];
            attribues.imageHeight = imgE.height;
            attribues.imageWidth = imgE.width;
            errors.push(
              generateErrorMessage("minImageWidthHeight", attribues, message)
            );
          }
          /**
           * Max Dimensions Validation
           */
          if (
            maxImageDimensions &&
            maxImageDimensions[0] &&
            !maxImageDimensions[1] &&
            imgE.width > maxImageDimensions[0]
          ) {
            attribues.maxImageWidth = maxImageDimensions[0];
            attribues.imageWidth = imgE.width;
            errors.push(
              generateErrorMessage("maxImageWidth", attribues, message)
            );
          } else if (
            maxImageDimensions &&
            maxImageDimensions[1] &&
            !maxImageDimensions[0] &&
            imgE.height > maxImageDimensions[1]
          ) {
            attribues.maxImageHeight = maxImageDimensions[1];
            attribues.imageHeight = imgE.height;
            errors.push(
              generateErrorMessage("maxImageHeight", attribues, message)
            );
          } else if (
            maxImageDimensions &&
            maxImageDimensions[1] &&
            maxImageDimensions[0] &&
            (imgE.height > maxImageDimensions[1] ||
              imgE.width > maxImageDimensions[0])
          ) {
            attribues.maxImageWidth = maxImageDimensions[0];
            attribues.maxImageHeight = maxImageDimensions[1];
            attribues.imageHeight = imgE.height;
            attribues.imageWidth = imgE.width;
            errors.push(
              generateErrorMessage("maxImageWidthHeight", attribues, message)
            );
          }
          /**
           * exact Image Dimensions validation
           */
          if (
            exactImageDimensions &&
            exactImageDimensions[0] &&
            !exactImageDimensions[1] &&
            imgE.width != exactImageDimensions[0]
          ) {
            attribues.imageWidth = exactImageDimensions[0];
            errors.push(generateErrorMessage("imageWidth", attribues, message));
          } else if (
            exactImageDimensions &&
            exactImageDimensions[1] &&
            !exactImageDimensions[0] &&
            imgE.height != exactImageDimensions[1]
          ) {
            attribues.imageHeight = exactImageDimensions[1];
            errors.push(
              generateErrorMessage("imageHeight", attribues, message)
            );
          } else if (
            exactImageDimensions &&
            exactImageDimensions[1] &&
            exactImageDimensions[0] &&
            (imgE.height != exactImageDimensions[1] ||
              imgE.width != exactImageDimensions[0])
          ) {
            attribues.imageWidth = exactImageDimensions[0];
            attribues.imageHeight = exactImageDimensions[1];
            errors.push(
              generateErrorMessage("imageWidthHeight", attribues, message)
            );
          }
          /**
           * Resolving the promise instance
           */
          resolve(errors.length ? errors : "");
        };

        img.src = e.target.result;
      } else {
        /**
         * Resolving the promise instance
         */
        resolve(errors.length ? errors : "");
      }
    };

    fReader.readAsDataURL(file);
  });
}

function generateErrorMessage(type, attribues, message) {
  if (message) {
    if (typeof message === "function") {
      return message(type, attribues);
    } else if (typeof message === "object") {
      if (message[type]) {
        return template(message[type].toString())(attribues);
      } else {
        return template(errorLang[type])(attribues);
      }
    }
    return template(message.toString())(attribues);
  }
  return template(errorLang[type])(attribues);
}

const errorLang = {
  maxFileSize:
    "File size should be less than <%=maxFileSize%> MB but it is <%=fileSize%> MB",
  minFileSize:
    "File size should be greater than  <%=minFileSize%> MB but it is <%=fileSize%> MB",

  minImageWidth:
    "Image width should be greater than  <%=minImageWidth%> PX and Selected file width is <%=imageWidth%>",
  minImageHeight:
    "Image height should be greater than  <%=minImageHeight%> PX and Selected file height is <%=imageHeight%>",
  minImageWidthHeight:
    "Image width/height should be greater than <%=minImageWidth %>/<%={minImageHeight%> PX.",

  maxImageWidth: "Image width should be less than  <%= maxImageWidth %> PX.",
  maxImageHeight: "Image height should be less than  <%= maxImageHeight %> PX.",
  maxImageWidthHeight:
    "Image width/height should be less than  <%= maxImageWidth}%>/  <%={maxImageHeight%> PX.",

  imageWidth: "Image width should be  <%= imageWidth %> PX.",
  imageHeight: "Image height should be  <%= imageHeight %> PX.",
  imageWidthHeight:
    "Image width/height should be  <%= imageWidth %>/ <%=imageHeight%> PX.",
  acceptedFiles: "Invalid file. Acceptable extensions are  <%=acceptedFiles%>",
};

export function getFileExt(name) {
  let name_arr = name.split(".");
  return name_arr[name_arr.length - 1];
}
/**
 * To verify the file Extensions
 * @param {Array} acceptedFiles
 * @param {File Instance} file
 */
export function checkFileExtensions(acceptedFiles, file) {
  const fileName = file.name;
  const fileType = file.type;
  let is_valid = false;
  acceptedFiles.map(function (file_type) {
    if (file_type.startsWith(".")) {
      var ext = getFileExt(fileName);
      var patt = new RegExp(file_type.replace(".", ""), "gmi");

      if (patt.test(ext) === true) {
        is_valid = true;
      }
    } else {
      let match_with = file_type;
      let patt = "";

      if (file_type.endsWith("*")) {
        match_with.slice(0, -1);
        patt = new RegExp("^" + match_with, "gmi");
      } else {
        patt = new RegExp(match_with, "gmi");
      }

      if (patt.test(fileType) === true) {
        is_valid = true;
      }
    }
  });

  return is_valid;
}
