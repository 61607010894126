import React from "react";
import { ModalBody } from "reactstrap";
import closeIcon from "../../assets/images/icon/close-btn.svg";
import { get } from "lodash";
import EventDefaultImage from "../../assets/images/Event.png";
import { eventTitleInUrl } from "../../utils/app-utils";

const RegisterSuccessModal = ({ close, event }) => {
  const image = get(event, "cover_media.0.path");
  const _image = !image ? EventDefaultImage : image;
  const link = window.location.href
  return (
    <>
      <ModalBody>
        <span
          role="button"
          className="float-end"
          onClick={() => close("close")}
        >
          <img src={closeIcon} className="img-fluid" alt="" />
        </span>
        <div className="text-center px-4 register-me-modal">
          <div className="model-img pb-4">
            <img src={_image} className="img-fluid" alt="" />
          </div>
          <div className="register-me-modal-content">
            <h2 className="modal-heading">registration successful</h2>
            <h2>"{event.title}"</h2>
            <div className="modal-border"></div>

            <p>
              to join this event login mobile app using the same number or visit
              -<a href={link}>{link}</a>
            </p>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default RegisterSuccessModal;
