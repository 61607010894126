import { get } from "lodash";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { EventContext } from "../context/EventContext";

export const EventPageMetaTag = () => {
  const { event } = useContext(EventContext);
  const image = get(event, "cover_media.0.path");
  return (
    <Helmet>
      <title>{event.title}</title>
      <meta property="og:url" content={window.location.toString()} />
      <meta property="og:type" content="event" />
      <meta property="og:title" content={event.title} />
      <meta property="og:description" content={event.description} />
      {image ? <meta property="og:image" content={image} /> : null}
    </Helmet>
  );
};
