import React from "react";

export function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}
export function getTimeOffset() {
  let userTimeZone = new Date().getTimezoneOffset();
  const sign = userTimeZone <= 0 ? "+" : "-";
  userTimeZone = Math.abs(userTimeZone);
  const modulus = userTimeZone % 60;
  const absoluteNumber = parseInt(userTimeZone / 60);
  return (
    sign + pad(absoluteNumber, 2) + (modulus ? ":" + pad(modulus, 2) : ":00")
  );
}

export function pad(value, size) {
  let s = value.toString();
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

export function convertObjectToQueryString(obj, prefix) {
  let str = [];

  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? convertObjectToQueryString(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v ? v : "")
      );
    }
  }
  return str.join("&");
}

export const renderDynamicComponent = (dynamicComponent, props) => {
  if (typeof dynamicComponent === "function") {
    return React.createElement(dynamicComponent, props);
  } else if (
    typeof dynamicComponent === "object" &&
    typeof dynamicComponent.render === "function"
  ) {
    return dynamicComponent.render({
      ...props,
      ...dynamicComponent.defaultProps,
    });
  }
  return dynamicComponent;
};

export function toCurrency(number, currency = "INR") {
  const inputFormatLang = "en-IN";
  const inputFormatOptions = {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  };
  const formattedValue = parseFloat(
    inputFormatOptions.style === "percent" ? number / 100 : number
  ).toLocaleString(inputFormatLang, inputFormatOptions);
  return formattedValue;
}

export const ModalClasses =
  "flex-column justify-content-sm-center justify-content-start mt-5 mt-sm-0";

export function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function secondToStr(secs) {
  function numberEnding(number) {
    return number > 1 ? "s" : "";
  }

  var temp = Math.floor(secs);
  var years = Math.floor(temp / 31536000);
  if (years) {
    return years + " year" + numberEnding(years);
  }
  //TODO: Months! Maybe weeks?
  var days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + " day" + numberEnding(days);
  }
  var hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + " hour" + numberEnding(hours);
  }
  var minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + " minute" + numberEnding(minutes);
  }
  var seconds = temp % 60;
  if (seconds) {
    return seconds + " second" + numberEnding(seconds);
  }
  return "less than a second"; //'just now' //or other string you like;
}

export const getImageByRatio = (imageURL, imageRatio) => {
  // cuelog("updateImageURL==getImageByRatio", imageURL)
  // console.log('imageURL', imageURL)
  const key = "image/upload";
  if (
    imageURL &&
    imageURL?.includes("image/upload") &&
    imageURL.includes("https://res.cloudinary.com")
  ) {
    const updateImageURL = imageURL.replace(key, "image/upload/" + imageRatio);
    const updateImageURLHttps = updateImageURL.replace("http://", "https://");
    console.log("--updateImageURL==", updateImageURLHttps);
    return updateImageURLHttps;
  } else {
    return imageURL;
  }
};
export const getAvatarImage = (imageURL) => {
  return getImageByRatio(imageURL, ImageRatio.Image_150x150);
};

export const getImageBy250x250 = (imageURL) => {
  return getImageByRatio(imageURL, ImageRatio.Image_250x250);
};
export const getImageBy320x180 = (imageURL) => {
  return getImageByRatio(imageURL, ImageRatio.Image_320x180);
};

export const getCardImage = (imageURL) => {
  return getImageByRatio(imageURL, ImageRatio.Image_524x236);
};
export const getCommunityCardImage = (imageURL) => {
  return getImageByRatio(imageURL, ImageRatio.Image_348x228);
};

export const getBannerImage = (imageURL) => {
  return getImageByRatio(imageURL, ImageRatio.Image_856x682);
};

export const getPassPageBanner = (imageURL) => {
  return getImageByRatio(imageURL, ImageRatio.Image_350x197);
};

export const ImageRatio = {
  Image_150x150: "ar_1,h_150,w_150",
  Image_250x250: "ar_1,h_250,w_250",
  Image_320x180: "ar_1.77,h_180,w_320",
  Image_524x236: "ar_1.77,h_236,w_524",
  Image_856x682: "ar_1.77,h_682,w_856",
  Image_348x228: "ar_1.77,h_228,w_348",
  Image_350x197: "h_197,w_350",
};

export function eventTitleInUrl(title) {
  return title ? title.replace(/[^a-zA-Z]/g, "-") : "";
}
