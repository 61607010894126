import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

//import images
import { AuthorContext } from "../../context/AuthorContext";
import { AppListLoader } from "../../shared/appList/AppListLoader";
import { AppList, AppListContext } from "../../shared/appList/AppList";
import { isLogin, kFormatter } from "../../utils/helper";
import { get } from "lodash";
import { AppListNoRecordFound } from "../../shared/appList/AppListNoRecordFound";
import {
  getCommunityCardImage,
  stripHtml,
  toCurrency,
} from "../../utils/app-utils";
import { BuyCommunityBtn } from "../CommunityPage/BuyCommunityBtn";
import DefaultEventImage from "../../assets/images/Community.png";
import RoundedImage from "../../components/RoundedImage";
import AuthContext from "../../context/AuthContext";
import { AppListLoadMore } from "../../shared/appList/AppListLoadMore";
import clevertap from "clevertap-web-sdk";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";

const CommunityTab = () => {
  const { author } = useContext(AuthorContext);
  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xl={12} lg={12}>
          {author?.id ? (
            <AppList
              name={`event_of_${author?.id}`}
              action={
                isLogin()
                  ? `/api:FzfbHYCg/auth_user/${author?.id}/community`
                  : `/api:FzfbHYCg/user/${author?.id}/community`
              }
              paginationType="loadMore"
              initialValues={{ "external[per_page]": 10 }}
            >
              <CommunityList />

              <AppListNoRecordFound message="looks like there are no communities to show here." />
              <AppListLoader />
              <AppListLoadMore />
            </AppList>
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const CommunityList = () => {
  const { authUser } = useContext(AuthContext);
  const { data, refresh } = useContext(AppListContext);

  useEffect(() => {
    if (authUser?.id) {
      refresh();
    }
  }, [authUser?.id]);

  return data.map((item) => <CommunityItem event={item} key={item.id} />);
};

const CommunityItem = ({ event }) => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const speakers = event?.moderators;
  const maxSpeakers = (speakers ? [...speakers].splice(0, 3) : []).filter(
    (u) => u.username
  );
  const host = speakers?.find((speaker) => speaker.id === event.created_by);

  const image = getCommunityCardImage(
    get(event, "cover_media.0.path", DefaultEventImage)
  );
  const profileImage = event?.image_url;
  const haveMoreSpeakers =
    speakers && speakers.length > 3 ? speakers.length - 3 : false;
  const bgColor = get(event, "cover_media.0.color", "rgba(0,0,0,0.6)");

  const link = `/community/${event.id}/${event.title.replace(
    /[^a-zA-Z]/g,
    "-"
  )}`;

  useEffect(() => {
    setTitle(stripHtml(event.title));
  }, [event.title]);

  useEffect(() => {
    setDescription(stripHtml(event.description));
  }, [event.description]);

  // const isStartedDatePassed = new Date(event.start_at) <= new Date();
  // const isPast = isStartedDatePassed && !event.is_live;
  // const isCancelled = event?.cancelled_by > 0;

  return (
    <Card
      className={"event-box " + event.bgColor}
      key={event.id}
      style={{ backgroundColor: "#14181C" }}
      role="button"
      onClick={() => {
        clevertap.event.push(AnalyticsConstant.COMMUNITY_CARD_TAPPED, {
          screen: "BROWSE",
          CommunityId: event?.id,
          CommunityName: event?.title,
          CommunityType: event?.type,
          IsPaid: event?.is_paid,
          IsMember: event?.is_member,
          Price: event?.price,
          CurrentUserIdentity: authUser?.id,
          CurrentUserName: authUser?.full_name,
        });

        navigate(link);
      }}
    >
      <CardBody>
        <div className="d-flex flex-wrap">
          {image ? (
            <div className="cover-image-wrapper mr-16">
              <div className="cues-img-post ml-0">
                <img src={image} className="img-fluid" alt={title} />
              </div>
            </div>
          ) : null}
          <div className="cues-contect">
            <div className="mt-4 mt-lg-0 in">
              <div className="community-content-top">
                <div className="community-title-contaner">
                  <div className="profile-image-wrapper">
                    <RoundedImage
                      radius={64}
                      imageUrl={profileImage}
                      title={event?.title}
                      initial="dark"
                    />
                  </div>
                  <div className="community-title-wrapper">
                    <Link
                      to={link}
                      className="profile-blog-cues text-white truncate-line-2"
                    >
                      {title}
                    </Link>
                    <p className="text-muted mb-0 home-desc">
                      {kFormatter(event.member_count)} members
                    </p>
                  </div>
                </div>
                <p className="home-desc text-muted mt-2 truncate-line-2">
                  {description}
                </p>
              </div>

              <div>
                {event?.interest?.map((subItem, key) => (
                  <span className="badge bg-dark me-3 mb-2 mb-xl-0" key={key}>
                    <span className="emoji">{subItem.emoji}</span>{" "}
                    {subItem.name}
                  </span>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <BuyCommunityBtn
                  communityData={event}
                  showForceJoinBtn={true}
                  className="btn btn-dark navbar-btn btn-rounded community-membership-btn btn-border-color"
                  title={`Membership at ${toCurrency(event.price)}`}
                />
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CommunityTab;
