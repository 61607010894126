import React, { useState } from "react";

export const AuthorContext = React.createContext();

export const AuthorProvider = ({ children }) => {
  const [author, setAuthor] = useState({});

  return (
    <AuthorContext.Provider value={{ setAuthor, author }}>
      {children}
    </AuthorContext.Provider>
  );
};
