import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";

import { AppListContext } from "./AppList";
export const AppListLoader = ({ colspan }) => {
  const { requesting } = useContext(AppListContext);
  return requesting ? (
    <div className="flex-column align-items-center justify-content-center d-flex">
      <Spinner animation="border" role="status" />
    </div>
  ) : null;
};
