import React, { Suspense, useReducer, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import AuthContext from ".";
import { getUser } from "../../api/auth";
import LoginModal from "../../pages/ModelsPage/LoginModal";
import { AppModal } from "../../shared/modal/app-modal-utils";
import { ModalClasses } from "../../utils/app-utils";
import { deleteCookie } from "../../utils/cookie";
import { isLogin } from "../../utils/helper";
import clevertap from "clevertap-web-sdk";
const initialState = {
  user: null,
  requesting: true,
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const [searchParams] = useSearchParams();

  const updateUserData = (user) => {
    dispatch({ type: "UPDATE_AUTH_USER_DATA", payload: { user } });
  };

  const updateRequestStatus = (status) => {
    dispatch({
      type: "UPDATE_PROFILE_REQUEST_STATUS",
      payload: { status },
    });
  };

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    // console.log("state?.user?.id", state?.user?.id);
    if (isLogin() && !state?.user?.id) {
      updateRequestStatus(true);
      getUser()
        .then((response) => {
          updateUserData(response.data);
          clevertap.profile.push({
            Site: {
              Name: response?.data?.full_name,
              Identity: response?.data?.id,
              // MobileNumber: response?.data?.mobile,
              Phone: response?.data?.mobile,
              Email: response?.data?.email,
              // username: response?.data?.username,
              Photo: response?.data?.image_url,
            },
          });
        })
        .catch(() => {
          updateRequestStatus(false);
          deleteCookie("AUTH-TOKEN");
        });
    } else {
      updateRequestStatus(false);
      const loginQuery = searchParams.get("login");
      if (loginQuery) {
        // Open a login popup.
        console.log("Ready to Login/");

        AppModal.open(LoginModal, {
          showFooter: false,
          showCloseBtn: false,
          className: ModalClasses,
          centered: true,
        }).then((response) => {
          const redirectUrl = searchParams.get("redirect_url");
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else if (response?.username) {
            navigate("/" + response?.username, { replace: true });
          }
          console.log("response", response);
        });
      }
    }
  }, [location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        authUser: state.user,
        requesting: state.requesting,
        updateUserData,
        updateRequestStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_AUTH_USER_DATA":
      return { ...state, user: action.payload.user, requesting: false };
    case "UPDATE_PROFILE_REQUEST_STATUS":
      return { ...state, requesting: action.payload.status };

    default:
      return state;
  }
};

export default AuthProvider;
