import Navbar from "./Navbar";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import Footer from "./Footer";

import UpcomingEvent from "./UpcomingEvent";
import { useEffect, useState } from "react";

export const MessageCardWithUpcomingEvent = ({ children }) => {
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const fnc = () => {
      // console.log('Test....')
      if (window.innerWidth > 1024) {
        const footerHeight =
          document?.getElementsByClassName("footer-bg")[0]?.clientHeight;
        const headerHeight = document.getElementById("navbar").clientHeight;
        const bodyHeight = window.innerHeight;
        const _contentHeight = bodyHeight - (footerHeight + headerHeight) - 52;
        setHeight(_contentHeight < 600 ? 600 : _contentHeight);
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener("resize", fnc);
    fnc();

    return () => window.removeEventListener("resize", fnc);
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <section
        className="home-contain pb-5 mt-4"
        style={show ? { height: `${height}px`, overflow: "hidden" } : null}
      >
        <Container>
          <Row>
            <Col md={6} className="d-flex align-items-center">
              <div style={{ marginBottom: "16px" }}>{children}</div>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <div
                className={`${show ? "y-scrollable scroll-black" : ""}`}
                style={show ? { height: `${height}px` } : null}
              >
                <UpcomingEvent />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer showFooterBottomBar={false} />
    </React.Fragment>
  );
};
