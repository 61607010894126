import React from "react";
import { useEffect } from "react";
import { Button, ModalBody } from "react-bootstrap";
import { AppModal } from "../shared/modal/app-modal-utils";
import { eventTitleInUrl, ModalClasses } from "../utils/app-utils";
import { isMobile } from "react-device-detect";
import { useCallback } from "react";

const OpenInApp = ({ screenName, id, title, userName }) => {
  const handleClick = useCallback(() => {
    if (isMobile) {
      window.location.href = generateOpenInAppUrl({
        screenName,
        id,
        title,
        userName,
      });
    }
  }, [screenName, id, title, userName]);

  return isMobile ? (
    <button
      type="button"
      id="header-login-btn"
      className="btn btn-outline-custom navbar-btn btn-rounded w-xl"
      onClick={handleClick}
    >
      open in app
    </button>
  ): null;
};

export const OpenInAppModal = ({ screenName, id, title, userName }) => {
  const handleClick = useCallback(() => {
    window.location.href = generateOpenInAppUrl({
      screenName,
      id,
      title,
      userName,
    });
  }, [screenName, id, title, userName]);
  return (
    <>
      <ModalBody>
        <div className="d-flex justify-content-center">
          <Button onClick={handleClick}>open in app</Button>
        </div>
      </ModalBody>
    </>
  );
};

export const generateOpenInAppUrl = ({ screenName, id, title, userName }) => {
  const oneLinkURL = "https://joincueapp.onelink.me/8eOm";
  const mediaSource = { keys: ["User_invite"], defaultValue: "User_invite" };
  const is_retargeting = { paramKey: "is_retargeting", defaultValue: "true" };
  const af_force_deeplink = {
    paramKey: "af_force_deeplink",
    defaultValue: "true",
  };
  const custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" };

  const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL: oneLinkURL,
    afParameters: {
      mediaSource: mediaSource,
      afCustom: [
        is_retargeting,
        af_force_deeplink,
        custom_ss_ui,
        { paramKey: "screen_name", defaultValue: screenName },
        { paramKey: "id", defaultValue: id ? id : "" },
        { paramKey: "username", defaultValue: userName ? userName : "" },
        {
          paramKey: "title",
          defaultValue: title ? eventTitleInUrl(title) : "",
        },
      ],
    },
  });
  return result.clickURL;
};

export default OpenInApp;
