import React, { useRef, useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, FormCheck, Button, Spinner } from "react-bootstrap";
import classNames from "classnames";
import { mapPropsEvent } from "./formik-bootstrap-utils";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { isArray, isEmpty } from "lodash";
import {
  FaCross,
  FaEye,
  FaEyeSlash,
  FaTrash,
  FaWindowClose,
} from "react-icons/fa";
import { debounce } from "lodash";
import AuthCode from "react-auth-code-input";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";
// import moment from "moment";
// import { ReCaptcha } from "react-recaptcha-v3";
// const SITE_KEY = process.env.REACT_APP_SITE_KEY;

export const AppInput = React.forwardRef(
  (
    {
      className,
      disabled,
      label,
      groupProps,
      labelProps,
      id,
      showInlineError = true,
      name,
      highlightValidField = false,
      shouldDisableOnSubmitting = true,
      children,
      multiple = false,
      validate,
      onValidated,
      fieldProps,
      ...props
    },
    ref
  ) => {
    const { submitCount, isSubmitting, ...formProps } = useFormikContext();

    const [input, { error, touched, ...restMeta }, helper] = useField({
      name,
      validate: validate
        ? debounce(validate.bind(undefined, formProps, name, props), 300, {
            leading: true,
            trailing: false,
          })
        : undefined,
    });
    const isSubmitted = submitCount >= 1;
    const inputProps = {
      isValid: touched && !error && highlightValidField,
      isInvalid: (touched || isSubmitted) && error,
      ...mapPropsEvent(props, input, helper),
      disabled: disabled || (isSubmitting && shouldDisableOnSubmitting),
      name,
      id,
      multiple,
    };

    console.log('fi',name, isSubmitted, touched)

    return (
      <Form.Group
        className={classNames({
          [className]: !!className,
          valid: touched && !error && highlightValidField,
          invalid: (touched || isSubmitted) && error,
        })}
        {...groupProps}
        // controlId={_id}
      >
        <LabelField label={label} {...labelProps} />
        {children ? (
          typeof children === "function" ? (
            children(inputProps, { error, touched }, helper)
          ) : (
            children
          )
        ) : (
          <Form.Control
            {...fieldProps}
            {...inputProps}
            ref={ref}
            value={inputProps.value ? inputProps.value : ""}
          />
        )}
        <ErrorField
          error={error}
          touched={touched}
          isSubmitted={isSubmitted}
          showInlineError={showInlineError}
        />
      </Form.Group>
    );
  }
);

// export const AppDatePicker = React.forwardRef(
//   ({ dateFormat, fieldProps, ...props }, ref) => {
//     return (
//       <AppInput {...props}>
//         {(inputProps) => {
//           const { isValid, isInvalid, disabled } = inputProps;
//           return (
//             <DatePicker
//               dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
//               {...inputProps}
//               ref={ref}
//               selected={inputProps.value}
//               placeholderText={props.placeholder}
//               customInput={
//                 <Form.Control
//                   as="input"
//                   isValid={isValid}
//                   isInvalid={isInvalid}
//                   disabled={disabled}
//                   value={inputProps.value ? inputProps.value : ""}
//                   type="text"
//                   {...fieldProps}
//                   autoComplete="new-password"
//                 />
//               }
//             />
//           );
//         }}
//       </AppInput>
//     );
//   }
// );

export const AppInputGroup = ({
  prepend,
  append,
  inputAs = AppInput,
  mainClass,
  onKeyPress,
  ...props
}) => {
  return React.createElement(inputAs, props, (inputProps) => (
    <InputGroup className={mainClass}>
      {prepend ? prepend : null}
      <Form.Control {...inputProps} onKeyPress={onKeyPress} />
      {append ? append : null}
    </InputGroup>
  ));
};

export const AppInputPassword = (props) => {
  const [isPasswordType, setIsPasswordType] = useState(true);
  return (
    <AppInputGroup
      {...props}
      className="password-field"
      type={isPasswordType ? "password" : "text"}
      append={
        isPasswordType ? (
          <FaEye onClick={() => setIsPasswordType(!isPasswordType)} />
        ) : (
          <FaEyeSlash onClick={() => setIsPasswordType(!isPasswordType)} />
        )
      }
    ></AppInputGroup>
  );
};

export const AppCheck = ({
  showInlineError = true,
  id,
  label,
  name,
  disabled,
  labelProps,
  className,
  type,
  inline,
  highlightValidField = false,
  shouldDisableOnSubmitting,
  custom = true,
  multiple = false,
  checked,
  value,
  inputType,
  ...props
}) => {
  const _inputType = !inputType
    ? type === "radio"
      ? "radio"
      : "checkbox"
    : inputType;
  const [input, { error, touched }] = useField({
    name,
    type: _inputType,
    checked,
    value,
    multiple,
  });
  const {
    submitCount,
    isSubmitting,
    displayName: formName,
  } = useFormikContext();
  const isSubmitted = submitCount >= 1;
  const _id = id ? id : `${formName}_${name}`;
  const isDisabled = disabled || (isSubmitting && shouldDisableOnSubmitting);
  // console.log("input.value", input.value)
  const inputProps = {
    isValid: touched && !error && highlightValidField,
    isInvalid: (touched || isSubmitted) && error,
    ...mapPropsEvent(props, input),
    disabled: isDisabled,
    name,
    id,
    value: checked !== undefined ? value : undefined,
    checked: checked !== undefined ? checked : !!input.value,
    type: _inputType,
  };
  return (
    <Form.Check
      id={_id}
      className={className}
      isValid={touched && !error && highlightValidField}
      isInvalid={(touched || isSubmitted) && error}
      type={type}
      inline={inline}
      checked={inputProps.checked}
      custom={label && custom}
      disabled={isDisabled}
    >
      <FormCheck.Input {...inputProps} />
      {label ? (
        <FormCheck.Label
          html-for={_id}
          className="text-bold-600"
          {...labelProps}
        >
          {label}
        </FormCheck.Label>
      ) : null}
      <ErrorField
        error={error}
        touched={touched}
        isSubmitted={isSubmitted}
        showInlineError={showInlineError}
      />
    </Form.Check>
  );
};

export const AppCheckGroup = ({
  type,
  options = [],
  showInlineError = true,
  name,
  valueKey = "value",
  labelKey = "label",
  heading,
  className,
  highlightValidField = true,
  headingProps,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [input, { error, touched }] = useField(name);

  const { submitCount } = useFormikContext();
  const isSubmitted = submitCount >= 1;
  return (
    <Form.Group
      className={classNames({
        [className]: !!className,
        valid: touched && !error && highlightValidField,
        invalid: (touched || isSubmitted) && error,
      })}
    >
      <LabelField label={heading} {...headingProps} />
      <br />
      {options.map((option) => {
        const value = option[valueKey];
        const label = option[labelKey];
        // console.log("input.value", label, input.value, value === input.value);
        return (
          <AppCheck
            key={`option_${label}`}
            id={`${name}_${label}`}
            label={label}
            value={value}
            checked={value === input.value}
            name={name}
            highlightValidField={highlightValidField}
            multiple={true}
            type={type}
            showInlineError={false}
            {...props}
          />
        );
      })}
      <ErrorField
        error={error}
        touched={touched}
        isSubmitted={isSubmitted}
        showInlineError={showInlineError}
      />
    </Form.Group>
  );
};

export const AppSubmitBtn = ({
  children,
  className,
  label = "Submit",
  waitLabel = "Working...",
  alwaysActive = false,
  ...props
}) => {
  const { isSubmitting, dirty, touched, isValid, submitCount, status } =
    useFormikContext();
  return (
    <Button
      disabled={
        isSubmitting ||
        ((isEmpty(touched) || !isValid) &&
          submitCount === 0 &&
          !dirty &&
          !alwaysActive) ||
        status === "busy"
      }
      className={`glow position-relative ${className}`}
      type="submit"
      variant="primary"
      {...props}
    >
      {isSubmitting ? (
        <span>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span>{waitLabel}</span>
        </span>
      ) : null}
      {!isSubmitting ? children || label : null}
    </Button>
  );
};
export const ErrorField = ({
  error,
  touched,
  isSubmitted,
  showInlineError = true,
}) =>
  error && (touched || isSubmitted) && showInlineError ? (
    <Form.Control.Feedback className="d-block" type="invalid">
      {error}
    </Form.Control.Feedback>
  ) : null;
export const LabelField = ({ label, ...props }) =>
  label ? (
    <Form.Label className="text-bold-600" {...props}>
      {label}
    </Form.Label>
  ) : null;

export const AppOtpField = (props) => {
  return (
    <AppInput
      fieldProps={{
        as: AuthCode,
        className: "form-control form-control-lg text-center",
        allowedCharacters: "numeric",
        length: 4,
        inputClassName: "form-control form-control-lg text-center verification",
      }}
      {...props}
    />
  );
};

// export const AppDateRangePicker = ({
//   dateFormat = "DD/MM/YYYY",
//   fieldProps,
//   inputGroupClass = "highlighted-input-group",
//   ...props
// }) => {
//   return (
//     <AppInput {...props}>
//       {(inputProps) => {
//         const { isValid, isInvalid, disabled, value } = inputProps;
//         console.log("value", value);

//         const [start, end] = value ? value : ["", ""];

//         const parseValue = () => {
//           if (start && end) {
//             return `${start.format(dateFormat)} - ${end.format(dateFormat)}`;
//           }
//           return "";
//         };

//         return (
//           <InputGroup className={`date-picker-group ${inputGroupClass}`}>
//             <DateRangePicker
//               initialSettings={{
//                 autoUpdateInput: false,
//                 ranges: {
//                   Today: [moment().toDate(), moment().toDate()],
//                   Yesterday: [
//                     moment().subtract(1, "days").toDate(),
//                     moment().subtract(1, "days").toDate(),
//                   ],
//                   "Last 7 Days": [
//                     moment().subtract(6, "days").toDate(),
//                     moment().toDate(),
//                   ],
//                   "Last 30 Days": [
//                     moment().subtract(29, "days").toDate(),
//                     moment().toDate(),
//                   ],
//                   "This Month": [
//                     moment().startOf("month").toDate(),
//                     moment().endOf("month").toDate(),
//                   ],
//                   "Last Month": [
//                     moment().subtract(1, "month").startOf("month").toDate(),
//                     moment().subtract(1, "month").endOf("month").toDate(),
//                   ],
//                 },
//               }}
//               onApply={(event, picker) =>
//                 inputProps.onChange([picker.startDate, picker.endDate])
//               }
//             >
//               <Form.Control
//                 as="input"
//                 isValid={isValid}
//                 isInvalid={isInvalid}
//                 disabled={disabled}
//                 readOnly={true}
//                 value={parseValue()}
//                 title={parseValue()}
//                 type="text"
//                 {...fieldProps}
//                 autocomplete="new-password"
//               />
//             </DateRangePicker>
//             {start ? (
//               <InputGroup.Append>
//                 <Button
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     inputProps.onChange(["", ""]);
//                   }}
//                   variant="link"
//                 >
//                   <FaWindowClose />
//                 </Button>
//               </InputGroup.Append>
//             ) : null}
//           </InputGroup>
//         );
//       }}
//     </AppInput>
//   );
// };

// export const AppRecaptcha = ({ action }) => {
//   const recaptcha = useRef(null);

//   const [enableCaptcha] = useField({ name: "isReCaptchaEnable" });
//   const [field, { error, touched }] = useField({ name: "reCaptchaToken" });

//   const { submitCount } = useFormikContext();
//   const isSubmitted = submitCount >= 1;

//   const verifyCallback = (token) => {
//     console.log("token", token);
//     field.onChange({ currentTarget: { value: token, name: field.name } });
//   };

//   useEffect(() => {
//     enableCaptcha.onChange({
//       currentTarget: {
//         value: SITE_KEY ? "yes" : "no",
//         name: enableCaptcha.name,
//       },
//     });
//   }, []);

//   useEffect(() => {
//     recaptcha.current.execute();
//   }, [submitCount]);

//   return SITE_KEY ? (
//     <>
//       <ReCaptcha
//         ref={recaptcha}
//         sitekey={SITE_KEY}
//         action={action}
//         verifyCallback={verifyCallback}
//       />
//       <ErrorField
//         error={error}
//         touched={touched}
//         isSubmitted={isSubmitted}
//         showInlineError={true}
//       />
//     </>
//   ) : null;
// };
