import React from "react";
import { Card, CardBody } from "reactstrap";

const community = [
  "The terms and conditions of this community are solely controlled by the owner(s). Cue will not be responsible for any conflict that may arise due to the same.",
  "The views, content, references, and orientation of this community are controlled by the owner (s). Cue holds no responsibility for the completeness, correctness and authenticity of the content or the views expressed in the community.",
  "Once you have purchased the membership of the community it cannot be refunded. For any issues regarding the purchase please connect with the community owner(s).",
  "All communities and its members are required to follow the Community guidelines to continue using the platform. Please refer to our Community guidelines for more information.",
  "For any queries/issues please reach out to us at support@joincueclub.com",
];

const event = [
  "To make the most of the event please join on time so that you don’t miss out on anything.",
  "Please ensure a proper internet connectivity to enjoy the event.",
  "This is an online streaming event and if due to internet issue or any external factor the creator is unable to conduct the session they will get in touch with you to reschedule the event.",
  "The event orientation and content is completely controlled by the creator. Cue will not be responsible for the completeness, correctness and authenticity of the content.",
  "Once purchased the tickets cannot be refunded unless the event has been cancelled by the creator itself. In case of event rescheduled by the creator no refund would be processed.",
  "For detailed information please refer to our Terms & Conditions",
  "For any queries please reach out to us at support@joincueclub.com",
];

export const TermConditions = ({ conditionFor = "event" }) => {
  const lists = conditionFor === "event" ? event : community;
  return (
    <div className="mt-4 pt-2">
      <p className="home-heading text-lowercase">Terms & Conditions</p>

      <Card>
        <CardBody>
          <ul className="mb-0 ps-3">
            {lists.map((item) => (
              <li key={item} className="home-desc text-muted">
                {item}
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>
    </div>
  );
};
