import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

export const SecureRoutes = ({ children }) => {
  const { authUser, requesting } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!requesting && !authUser?.id) {
      navigate("/");
    }
  }, [requesting, authUser?.id]);

  return !requesting && authUser?.id ? children : null;
};
