import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCommunity } from "../api/communityApi";
import { getEventById } from "../api/event";
import { getUserByUsername } from "../api/user";
import { FullPageLoader } from "../components/FullPageLoader";
import AuthContext from "../context/AuthContext";
import { AuthorContext } from "../context/AuthorContext";
import { EventContext } from "../context/EventContext";
import EventPage from "./EventPage";
import { LoginNeededPage } from "./LoginNeededPage";
import NoMatchPage from "./NoMatchPage";
import { PrivateEventPage } from "./PrivateEventPage";
import ProfilePage from "./ProfilePage";

const DynamicPage = () => {
  const { pathname } = useLocation();
  const [isVerified, setVerify] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [page, setPage] = useState(null);
  const [busy, setBusy] = useState(true);
  const { setEvent } = useContext(EventContext);
  const { setAuthor } = useContext(AuthorContext);
  const { authUser, requesting } = useContext(AuthContext);

  const verifyDynamicPage = async (pathname, requesting) => {
    console.log("requesting", requesting);
    if (requesting) {
      return;
    }

    setBusy(true);
    setVerify(false);
    const segments = pathname.split("/").filter((path) => !!path);
    // console.log("segments", segments);
    try {
      if (segments.length === 1) {
        // get User Information
        const { data: author } = await getUserByUsername(segments[0]);
        setAuthor(author);
        setPage({ pageName: "author" });
        setVerify(true);
      } else if (segments.length === 3) {
        const [{ data: author }, { data: event }] = await Promise.all([
          getUserByUsername(segments[0]),
          getEventById(segments[1]),
        ]);
        if (event.created_by === author.id) {
          setAuthor(author);
          setEvent(event);
          setPage({ pageName: "event" });
          setVerify(true);
        } else {
          setVerify(false);
        }
      } else {
        setAuthor({});
        setEvent({});
        setVerify(false);
        console.log("404");
      }
    } catch (error) {
      setAuthor({});
      setEvent({});
      setVerify(false);
      console.log("error", error.response.data);
      const errorCode = error?.response?.data?.code;
      setErrorType(errorCode);
    }
    setTimeout(() => {
      setBusy(false);
    }, 0);
  };

  useEffect(() => {
    console.log('Iam verify..', requesting)
    verifyDynamicPage(pathname, requesting);
  }, [pathname, requesting, authUser?.id]);

  console.log("errorType", errorType);

  // console.log('!authUser?.id', !authUser?.id)
  if (busy || requesting) {
    return <FullPageLoader />;
  } else if (!busy && isVerified && page?.pageName === "event") {
    return <EventPage />;
  } else if (!busy && isVerified && page?.pageName === "author") {
    return <ProfilePage />;
  } else if (!busy && errorType) {
    if (errorType === "ERROR_CODE_NOT_FOUND") {
      return <NoMatchPage />;
    } else if (!authUser?.id) {
      return <LoginNeededPage />;
    } else if (errorType === "ERROR_CODE_ACCESS_DENIED") {
      return <PrivateEventPage />;
    } else {
      return <NoMatchPage />;
    }
  } else {
    return <NoMatchPage />;
  }
};

// const renderComponets = {
//   Loader: FullPageLoader,
//   NoFound: NoMatchPage,
//   PrivateEvent: PrivateEventPage,
//   ProfilePage: ProfilePage,
//   EventPage: EventPage,
// };

export default DynamicPage;
