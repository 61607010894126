import React, { useContext, useEffect } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { AppListContext } from "./AppList";

export const AppListLoadMore = ({
  className,
  waitLabel = "Loading...",
  children,
  label = "Load More",
}) => {
  const {
    requesting,
    next,
    currentPage,
    pages,
    data,
    info: nextPage,
  } = useContext(AppListContext);

  //   useEffect(() => {
  //     const fnc = function () {
  //       if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
  //         // you're at the bottom of the page
  //         if (!requesting) next();
  //       }
  //     };
  //     window.addEventListener("scroll", fnc);
  //     return function () {
  //       window.removeEventListener("scroll", fnc);
  //     };
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  if (!nextPage || !data.length) return null;
  return (
    <Row className="my-4 pt-2">
      <Col lg={12}>
        <div className="text-center">
          <button
            disabled={requesting}
            onClick={next}
            type="button"
            className="btn btn-outline-custom navbar-btn btn-rounded w-xl"
          >
            {requesting ? (
              <span>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>{waitLabel}</span>
              </span>
            ) : null}
            {!requesting ? children || label : null}
          </button>
        </div>
      </Col>
    </Row>
  );
};
