import React, { useCallback, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
} from "reactstrap";

//import images
// import logo from "../assets/images/Logo.png";
import logo from "../assets/images/cue-club-live-social.svg";
import dropdownArrow from "../assets/images/dropdown-arrow.png";
import MobileNumbeModal from "../pages/ModelsPage/MobileNumbeModal";
import { getShortName, isLogin } from "../utils/helper";
import { AppModal } from "../shared/modal/app-modal-utils";
import LoginModal from "../pages/ModelsPage/LoginModal";
import { ModalClasses } from "../utils/app-utils";
import AuthContext from "../context/AuthContext";
import { deleteCookie } from "../utils/cookie";
import RoundedImage from "./RoundedImage";
import clevertap from "clevertap-web-sdk";

const Navbar = ({ OpenInAppComponent }) => {
  const navigate = useNavigate();

  const [isOpenOffCanvas, setIsOpenOffCanvas] = useState(false);
  const { authUser, updateUserData } = useContext(AuthContext);
  const toggleOffCanvas = () => {
    setIsOpenOffCanvas(!isOpenOffCanvas);
  };

  const [isDropdown, setIsDropdown] = useState(false);
  const toggleDropdown = () => {
    setIsDropdown(!isDropdown);
  };

  const _isLogin = isLogin();

  const handleLogin = useCallback(() => {
    AppModal.open(LoginModal, {
      showFooter: false,
      showCloseBtn: false,
      className: ModalClasses,
      centered: true,
    });
  }, []);

  return (
    <React.Fragment>
      <div id="navbar">
        <nav
          className="navbar navbar-expand-lg navbar-custom sticky sticky-dark"
          id="main-menu"
        >
          <Container>
            <Link className="navbar-brand logo text-uppercase" to="/">
              <img src={logo} alt="" />
            </Link>
            {!_isLogin ? (
              <div className="nav-button ms-auto">
                <ul className="nav navbar-nav navbar-right">
                  {OpenInAppComponent ? (
                    <li className="open-in-app-wrapper">
                      {OpenInAppComponent}
                    </li>
                  ) : null}
                  <li>
                    <button
                      type="button"
                      id="header-login-btn"
                      className="btn btn-outline-custom navbar-btn btn-rounded w-xl"
                      onClick={handleLogin}
                    >
                      log in
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="nav-button ms-auto d-flex">
                {OpenInAppComponent ? (
                  <div className="open-in-app-wrapper">
                    {OpenInAppComponent}
                  </div>
                ) : null}
                <Dropdown
                  isOpen={isDropdown}
                  toggle={toggleDropdown}
                  className="d-inline-block"
                >
                  <DropdownToggle
                    tag="a"
                    href="#"
                    className="d-flex align-items-center dropdown-user"
                    id="user-dropdown"
                  >
                    <div className="avatar-sm">
                      <RoundedImage
                        imageUrl={authUser?.image_url}
                        title={authUser?.full_name}
                        radius={34}
                        className="d-none d-md-flex d-lg-flex d-xl-flex"
                      />
                      <RoundedImage
                        imageUrl={authUser?.image_url}
                        title={authUser?.full_name}
                        onClick={toggleOffCanvas}
                        className="d-flex                                          d-sm-none"
                        radius={34}
                      />
                      {/* <div
                        className="avatar-title rounded-circle bg-custom text-dark f-12"
                        onClick={toggleOffCanvas}
                      >
                        {getShortName(authUser?.full_name)}
                      </div> */}
                    </div>
                    <span className="ms-3 d-none d-md-inline-block">
                      <span
                        className="text-custom fw-normal"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/${authUser.username}`);
                        }}
                      >
                        {authUser?.full_name}{" "}
                      </span>
                      <img src={dropdownArrow} className="ms-2" alt="" />
                    </span>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end pt-0">
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        navigate(`/${authUser.username}`);
                      }}
                    >
                      {authUser?.mobile}
                    </DropdownItem>
                    <hr className="dropdown-divider" />
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        deleteCookie("AUTH-TOKEN");
                        clevertap.logout();
                        updateUserData(null);
                        navigate("/");
                      }}
                    >
                      log out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </Container>
        </nav>
      </div>

      <Offcanvas
        isOpen={isOpenOffCanvas}
        direction="bottom"
        className="offcanvas-bottom d-block d-md-none login-offcanvas"
        tabIndex="-1"
        toggle={toggleOffCanvas}
      >
        <OffcanvasBody className="small">
          <div className="text-center pt-0">
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                navigate(`/${authUser.username}`);
              }}
            >
              {authUser?.mobile}
            </a>
            <hr className="dropdown-divider" />
            <span
              onClick={() => {
                deleteCookie("AUTH-TOKEN");
                updateUserData(null);
                toggleOffCanvas();
              }}
              className="dropdown-item"
              role="button"
            >
              log out
            </span>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default Navbar;
