import React, { useContext, useEffect, useState } from "react";
import { getEventById } from "../api/event";
import AuthContext from "./AuthContext";

export const EventContext = React.createContext();

export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState({});

  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    const fnc = async () => {
      if (event?.id) {
        const response = await getEventById(event?.id);
        setEvent(response.data);
      }
    };

    fnc();
  }, [authUser?.id]);

  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  );
};
