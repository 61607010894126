import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

//import images
import ico_facebook from "../../assets/images/icon/ico_facebook.svg";
import ico_twitter from "../../assets/images/icon/ico_twitter.svg";
import chain from "../../assets/images/icon/icon_link-chain.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CommunityContext } from "../../context/CommunityContext";
import { InterestListOnCard } from "../../components/CommunityInterest";
import { BuyCommunityBtn } from "./BuyCommunityBtn";
import { stripHtml } from "../../utils/app-utils";
import RoundedImage from "../../components/RoundedImage";
import AuthContext from "../../context/AuthContext";

const BuyCommunityMembership = () => {
  const { community, fetchCommunity } = useContext(CommunityContext);
  const { authUser } = useContext(AuthContext);
  const url = window.location.toString();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    setTitle(stripHtml(community.title));
  }, [community.title]);

  useEffect(() => {
    setDescription(stripHtml(community.description));
  }, [community.description]);

  const creator = community?.moderators?.find(
    (v) => v.id === community?.created_by
  );

  const shareMessage =
    community?.created_by === authUser?.id
      ? `hey! check out this community "${community.title}" I have created on cue club`
      : `Hey! check this community "${community.title}" by ${creator?.full_name} on cue club`;

  return (
    <React.Fragment>
      <div className="card-sidebar d-none d-md-block">
        <Card>
          <CardBody>
            <div>
              <div className="community-side-img-wrapper">
                <RoundedImage
                  imageUrl={community.image_url}
                  title={title}
                  radius={80}
                  initial="dark"
                />
              </div>

              <div className="mt-3">
                <h4 className="home-title mb-0 fw-normal text-truncate text-lowercase">
                  {title}
                </h4>
                <span className="member_count">
                  {community?.member_count} members
                </span>
              </div>
              <div className="community-side-desc">{description}</div>

              <InterestListOnCard interest={community.interest} />

              <div className="text-center mt-4 mb-2">
                <BuyCommunityBtn
                  afterJoin={fetchCommunity}
                  isDetailPage={true}
                />
              </div>
              {community.is_member ? (
                <div className="is-member">
                  <h4>
                    You are a member, use mobile app to access the channel.
                  </h4>
                </div>
              ) : null}
            </div>
          </CardBody>

          <div className="card-footer">
            <div className="text-center p-2 p-lg-3">
              <ul className="list-inline social-img mb-0 mt-2 ">
                <li className="list-inline-item">
                  <a
                    data-original-title={community.title}
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${shareMessage}`}
                    target="_blank"
                    title="Facebook"
                  >
                    <img src={ico_facebook} alt="" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={`https://twitter.com/share?text=${shareMessage}&url=${url} + "&hashtags=cue"`}
                    target="_blank"
                    title="Twitter"
                  >
                    <img src={ico_twitter} alt="" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <CopyToClipboard
                    text={url}
                    role="button"
                    onCopy={() => alert("Link copied")}
                  >
                    <img src={chain} alt="" />
                  </CopyToClipboard>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default BuyCommunityMembership;
