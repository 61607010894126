import { get } from "lodash";
import React, { useContext } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { getCommunity, requestToJoinCommunity } from "../../api/communityApi";
import { CommunityContext } from "../../context/CommunityContext";
import { RazorPayContext } from "../../context/RazorPayContext";
import { AppModal } from "../../shared/modal/app-modal-utils";
import { ModalClasses, toCurrency } from "../../utils/app-utils";
import { isLogin } from "../../utils/helper";
import LoginModal from "../ModelsPage/LoginModal";
import { useAlert } from "react-alert";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const BuyCommunityBtn = ({
  communityData,
  title,
  className,
  afterJoin,
  showForceJoinBtn = false,
  isDetailPage,
}) => {
  const contextdata = useContext(CommunityContext);
  const [requesting, setRequesting] = useState(false);
  const [joined, setJoin] = useState(false);
  const _community = contextdata?.community;
  const { buyCommunity, isBusy } = useContext(RazorPayContext);
  const community = communityData ? communityData : _community;
  const status = get(community, "user_subscription_status.status");
  const [searchParams] = useSearchParams();

  const _alert = useAlert();

  const handleJoinCommunity = useCallback(
    async (e) => {
      // console.log("Iamhere...");
      e?.stopPropagation();
      try {
        if (!isLogin()) {
          console.log("Iam in");
          await AppModal.open(
            LoginModal,
            {
              showFooter: false,
              showCloseBtn: false,
              className: ModalClasses,
              centered: true,
            },
            { event: community }
          );
        }

        const { data: _community } = await getCommunity(community.id);

        if (_community.is_member) {
          setJoin(true);
          _alert.info("you are already a member.");
          return;
        }

        setRequesting(true);
        await requestToJoinCommunity(community.id);
        setJoin(true);
        if (typeof afterJoin === "function") {
          afterJoin();
        }
      } catch (error) {
        console.log("Error", error);
        if (error?.message) {
          _alert.error(error?.message);
        }
      }
      setRequesting(false);
    },
    [community.id]
  );

  // console.log("requesting", requesting, community.is_member, joined);

  useEffect(() => {
    const openRazorpay = searchParams.get("open_razorpay");
    if (
      community.is_paid &&
      !community.is_member &&
      openRazorpay &&
      isDetailPage
    ) {
      buyCommunity(undefined, community);
    }
  }, [
    community.is_paid && !community.is_member,
    handleJoinCommunity,
    isDetailPage,
  ]);

  return (
    <>
      {community.is_paid && !community.is_member ? (
        <button
          type="button"
          disabled={isBusy}
          className={`${
            className
              ? className
              : "btn btn-custom btn-lg btn-rounded by-pass-btn"
          } community-action-btn`}
          onClick={(e) => {
            buyCommunity(e, community);
          }}
        >
          {title ? title : `Membership ${toCurrency(community?.price)}`}
        </button>
      ) : null}

      {!community.is_paid &&
      (!community.is_member || showForceJoinBtn) &&
      (!joined || showForceJoinBtn) ? (
        <button
          type="button"
          disabled={
            requesting ||
            community.is_member ||
            joined ||
            community.is_requested
          }
          className={`${
            className
              ? className
              : "btn btn-custom btn-lg btn-rounded by-pass-btn"
          } free-join-btn community-action-btn no-disable
              `}
          onClick={handleJoinCommunity}
        >
          {community.is_member || joined
            ? "you're a member"
            : community.is_requested
            ? "requested"
            : "join"}
        </button>
      ) : null}
    </>
  );
};
