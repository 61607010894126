import { isArray } from "lodash";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getLang, getMasterData } from "../../api/commonApi";
import AuthContext from "../AuthContext";
import MasterDataContext from "./MasterDataContext";

const MasterDataProvider = ({ children }) => {
  const [bestSuitedIcons, setBestSuitedIcons] = useState({});
  const [languageIcons, setLanguageIcons] = useState({});
  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    getMasterData().then((response) => {
      //   console.log("response", response);
      if (isArray(response.data)) {
        const _data = {};
        response.data.map((d) => {
          _data[d.id] = d;
        });
        setBestSuitedIcons(_data);
      }
    });
  }, []);

  useEffect(() => {
    getLang().then((response) => {
      console.log("response", response);
      if (isArray(response.data)) {
        const _data = {};
        response.data.map((d) => {
          _data[d.code] = d;
        });
        setLanguageIcons(_data);
      }
    });
  }, [authUser?.id]);

  return (
    <MasterDataContext.Provider value={{ bestSuitedIcons, languageIcons }}>
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataProvider;
