import Navbar from "./Navbar";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import Footer from "./Footer";
import UpcomingEvent from "./UpcomingEvent";
import Login from "../assets/images/login.png";
import NotFound from "../assets/images/404.png";
import Private from "../assets/images/locked.png";
const images = {
  Login,
  NotFound,
  Private,
};

export const MessageCard = ({ children,  type = "Login" }) => {
  return (
    <React.Fragment>
      <Navbar />
      <section className="home-contain pb-5 mt-4">
        <Container>
          <Row>
            <Col md={6} className="d-flex align-items-center">{children}</Col>
            <Col md={6}>
              <div className="upcoming-event-calendar">
                <Row>
                  <div className="col-md-12">
                    <img
                      src={images[type]}
                      className="img-fluid"
                      alt="no upcoming event found"
                    />
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer showFooterBottomBar={false} />
    </React.Fragment>
  );
};
