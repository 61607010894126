import axios from "axios";
import request from "../config/request";

export const getCountryCode = () => {
  return axios.get(
    "https://xwpo-c4qw-zxn1.m2.xano.io/api:q570Id4i/master/country-code",
    {
      headers: {
        accept: "application/json",
      },
    }
  );
};



export const getMasterData = () => {
  return request.get('api:q570Id4i/master/data/best_suited_for');
}

export const getLang = () => {
  return request.get('api:q570Id4i/master/app-lang')
}