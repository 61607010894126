import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

//import images
import badge from "../../assets/images/badge-sm.png";
import emojiStar from "../../assets/images/icon/emoji-star.svg";
import ico_facebook from "../../assets/images/icon/ico_dark_facebook.svg";
import ico_twitter from "../../assets/images/icon/ico_dark_twitter.svg";
import chain from "../../assets/images/icon/ico_dark_link-chain.svg";
import facebook from "../../assets/images/social/dark-facebook.png";
import twitter from "../../assets/images/social/dark-twitter.png";
import link from "../../assets/images/social/dark-link.png";
import { EventContext } from "../../context/EventContext";
import { displayDate, displayTime } from "../../utils/helper";
import {
  eventTitleInUrl,
  getPassPageBanner,
  toCurrency,
} from "../../utils/app-utils";
import RoundedImage from "../../components/RoundedImage";
import CopyToClipboard from "react-copy-to-clipboard";
import AuthContext from "../../context/AuthContext";
import UserListCicle, { SpeakerNames } from "../../components/UserListCircle";
import { get } from "lodash";

const Section = ({ transId }) => {
  const { event } = useContext(EventContext);
  const { authUser } = useContext(AuthContext);
  // const image =
  //   event && event?.cover_media && event?.cover_media[0].path
  //     ? event.cover_media[0].path
  //     : null;

  const image = get(event, "cover_media[0].path", null);

  const speakers = event?.speakers;
  const maxSpeakers = speakers ? [...speakers].splice(0, 2) : [];
  const navigate = useNavigate();
  const url = window.location.toString();
  // console.log("eventeventeventevent", event);

  const creator = speakers?.find((v) => v.id === event?.created_by);

  const shareMessage =
    event?.created_by === authUser?.id
      ? `check this event "${event?.title}" I am hosting on cue club `
      : `check this event "${event?.title}" by ${creator?.full_name} on cue club`;

  const eventUrl = `${window.location.origin}/${creator?.username}/${
    event?.id
  }/${eventTitleInUrl(event?.title)}`;

  return event?.id ? (
    <React.Fragment>
      <Row className="mt-4">
        <Col lg={12}>
          <Card className="card-border overflow-hidden">
            <CardBody className="p-0">
              <Row className="">
                <Col lg={8} md={7} className="d-flex align-items-center">
                  <div className="pass-card-wrapper">
                    <Row className="align-items-center">
                      <div className="col-auto">
                        <div className="pass-banner-img pass-banner-img-new">
                          <img
                            src={getPassPageBanner(image)}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-flex-1">
                        <div className="mt-3 mt-lg-3 mt-lg-0">
                          <Link to="#" className="time-tag mb-0">
                            {displayDate(event?.start_at)} &nbsp; &nbsp; l
                            &nbsp; &nbsp; {displayTime(event?.start_at)}
                          </Link>
                        </div>
                        <h4 className="home-title mb-0 mt-3 mt-lg-3 fw-normal pass-title">
                          {event?.title}
                        </h4>
                        <div className="d-flex mt-3 mt-lg-4 align-items-center">
                          <UserListCicle
                            users={maxSpeakers}
                            max={maxSpeakers.length}
                            radius={64}
                            showMoreUserHint={false}
                          />
                          <div className="flex-grow-1 ms-4">
                            <p className="mb-0 home-desc">
                              <SpeakerNames speakers={maxSpeakers} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col lg={4} md={5} className="pass-effact">
                  <div className="p-2 p-lg-4">
                    <div className="d-flex justify-content-between  align-items-center">
                      <div className="text-center">
                        <img
                          src={emojiStar}
                          alt=""
                          className="img-fluid pass-star-img"
                        />
                      </div>
                      <ul className="list-inline social-img mb-0 mt-2 mt-lg-3">
                        <li className="list-inline-item">
                          <a
                            data-original-title={event?.title}
                            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                            target="_blank"
                            title="Facebook"
                          >
                            <img src={ico_facebook} alt="" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href={`https://twitter.com/share?text=${shareMessage}&url=${url} + "&hashtags=cue"`}
                            target="_blank"
                            title="Twitter"
                          >
                            <img src={ico_twitter} alt="" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <CopyToClipboard
                            text={url}
                            role="button"
                            onCopy={() => alert("Link copied")}
                          >
                            <img src={chain} alt="" />
                          </CopyToClipboard>
                        </li>
                      </ul>
                    </div>

                    <div className="text-dark mt-3">
                      <h4 className="transaction-title mb-0">
                        transaction successful
                      </h4>
                      {transId ? <h6>{transId}</h6> : null}
                      <h5 className="transaction-desc fw-normal mt-2">
                        to join this event login mobile app using the same
                        number or visit -
                        <a href={eventUrl} className="fw-medium">
                          {eventUrl}
                        </a>
                      </h5>

                      <div className="transaction-border my-3"></div>

                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <p className="transaction-amount mb-0">Paid amount</p>
                        <p className="transaction-amount mb-0">
                          {toCurrency(event?.price)}
                        </p>
                      </div>

                      {/* <ul className="list-inline social-img text-center mt-4 mb-0 d-block d-sm-none">
                        <li className="list-inline-item">
                          <Link to="#">
                            <img src={facebook} alt="" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#">
                            <img src={twitter} alt="" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#">
                            <img src={link} alt="" />
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  ) : null;
};

export default Section;
