import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import rightArrow from "../../assets/images/icon/right-arrow.svg";

//import images
import badge from "../../assets/images/badge-sm.png";
import { AppList, AppListContext } from "../../shared/appList/AppList";
import { AuthorContext } from "../../context/AuthorContext";
import { displayDate, displayTime, isLogin } from "../../utils/helper";
import { get } from "lodash";
import { AppListLoader } from "../../shared/appList/AppListLoader";
import { AppListNoRecordFound } from "../../shared/appList/AppListNoRecordFound";
import DefaultEventImage from "../../assets/images/Event.png";
import { getCardImage, stripHtml } from "../../utils/app-utils";
import RoundedImage from "../../components/RoundedImage";
import UserListCicle from "../../components/UserListCircle";
import AuthContext from "../../context/AuthContext";
import { AppListLoadMore } from "../../shared/appList/AppListLoadMore";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";
import clevertap from "clevertap-web-sdk";

const EventTab = () => {
  const { author } = useContext(AuthorContext);
  const action = isLogin()
    ? `/api:_dz3UmOR/user/event/${author.id}`
    : "/api:FzfbHYCg/user/event/";

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {author?.id ? (
            <EventListContainer
              initialValues={{
                ...(!isLogin()
                  ? { user_id: author?.id, "external[per_page]": 10 }
                  : { "external[per_page]": 10 }),
                "filter[type]": "UPCOMING",
              }}
              action={action}
            />
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export const EventListContainer = ({
  name,
  action,
  initialValues,
  callback,
}) => {
  return (
    <AppList
      name={name || action}
      action={action}
      initialValues={initialValues}
      paginationType="loadMore"
      callback={callback}
    >
      <Row>
        <EventList />
      </Row>
      <AppListNoRecordFound message="looks like there are no events to show here." />
      <AppListLoader />
      <AppListLoadMore />
    </AppList>
  );
};

const EventList = () => {
  const { authUser } = useContext(AuthContext);
  const { data, refresh } = useContext(AppListContext);

  useEffect(() => {
    if (authUser?.id) {
      refresh();
    }
  }, [authUser?.id]);

  return data.map((item) => (
    <EventItem event={item} key={item.id.toString()} />
  ));
};

export const EventItem = ({ event }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const _image = get(event, "cover_media.0.path", null);
  const image = _image ? getCardImage(_image) : DefaultEventImage;
  const { authUser } = useContext(AuthContext);
  const speakers = event?.speakers ? event?.speakers : [];

  const maxSpeakers2 = (speakers ? [...speakers].splice(0, 5) : []).filter(
    (u) => u.username
  );
  const host =
    speakers && speakers?.find((speaker) => speaker.id === event.created_by);

  const bgColor = get(event, "cover_media.0.color", "#B9B9B9");
  const link =
    host && host?.username
      ? `/${host.username}/${event.id}/${event.title.replace(
          /[^a-zA-Z]/g,
          "-"
        )}`
      : "";
  useEffect(() => {
    setTitle(stripHtml(event.title));
  }, [event.title]);

  const isStartedDatePassed = new Date(event.start_at) <= new Date();
  const isPast = isStartedDatePassed && !event.is_live;
  const isCancelled = event?.cancelled_by > 0;
  return (
    <Col
      md={6}
      className="mb-4 "
      role="button"
      onClick={() => {
        clevertap.event.push(AnalyticsConstant.EVENT_CARD_TAPPED, {
          Entity: AnalyticsConstant.Event,
          Id: `${AnalyticsConstant.Event}:${event?.id}`,
          Name: event?.title,
          Type: event?.type,
          IsPaid: event?.is_paid,
          IsMember: event?.is_member,
          Price: event?.price,
          CurrentUserIdentity: authUser?.id,
          CurrentUserName: authUser?.full_name,
        });
        navigate(link);
      }}
    >
      <div>
        <Card
          className="bg-cyan event-box h-100 overflow-hidden"
          // style={{
          //   backgroundColor: isCancelled || isPast ? "#B9B9B9" : bgColor,
          // }}
        >
          <div
            className="card-overlay"
            style={{ opacity: isCancelled || isPast ? 0.4 : 0.1 }}
          />

          <CardBody
            data-color={bgColor}
            className="position-relative p-0"
            style={{
              backgroundColor: isCancelled || isPast ? "#F3F3F3" : bgColor,
            }}
          >
            {image ? (
              <div
                className="event-box-image-wrapper position-relative "
                style={{ zIndex: 2 }}
              >
                <img src={image} alt="" className={isPast ? "grayscale" : ""} />
              </div>
            ) : null}
            <div
              className="position-relative "
              style={{
                zIndex: 2,
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <div className="mt-16">
                <Link to="#" className="time-tag mb-0">
                  {displayDate(event?.start_at)} &nbsp; &nbsp; l &nbsp; &nbsp;{" "}
                  {displayTime(event?.start_at)}
                </Link>
              </div>

              <div className="mt-2 mt-md-3 text-truncate">
                <Link to={link} className="profile-blog-post text-white ">
                  {title}
                </Link>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-2 mt-md-3 min-h-48">
                <div className="col-auto">
                  <UserListCicle
                    users={speakers}
                    max={5}
                    radius={48}
                    showMoreUserHint={true}
                  />
                </div>
                {speakers.length < 5 ? (
                  <div className="flex-1 ml-8 mr-4">
                    <p className="mb-0 home-desc truncate-line-2">
                      {maxSpeakers2?.map((speaker, index) => (
                        <span key={speaker.id}>
                          {index > 0 && index === maxSpeakers2.length - 1
                            ? "and "
                            : null}
                          {speaker.full_name}
                          {index + 2 < maxSpeakers2.length ? ", " : " "}
                        </span>
                      ))}
                    </p>
                  </div>
                ) : null}
                <div className="flex-shrink-0 ">
                  <Link to={link}>
                    <img src={rightArrow} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default EventTab;
