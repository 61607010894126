import React, { useContext } from "react";
import { CommunityContext } from "../../context/CommunityContext";
import { get } from "lodash";
import { Banner } from "../../components/Banner";
import DefaultEventImage from "../../assets/images/Community.png";

const CommunityBanner = React.forwardRef((props, ref) => {
  const { community } = useContext(CommunityContext);
  const banners = get(community, "cover_media", []);

  return (
    <div className="banner-imager-wrapper" ref={ref}>
      <Banner banners={banners} defaultImage={DefaultEventImage} />
    </div>
  );
});

export default CommunityBanner;
