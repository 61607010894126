import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

//import images
import badge from "../../assets/images/badge-sm.png";
import emojiStar from "../../assets/images/icon/emoji-star.svg";
import ico_facebook from "../../assets/images/icon/ico_dark_facebook.svg";
import ico_twitter from "../../assets/images/icon/ico_dark_twitter.svg";
import chain from "../../assets/images/icon/ico_dark_link-chain.svg";
import facebook from "../../assets/images/social/dark-facebook.png";
import twitter from "../../assets/images/social/dark-twitter.png";
import link from "../../assets/images/social/dark-link.png";
import { toCurrency } from "../../utils/app-utils";
import { CommunityContext } from "../../context/CommunityContext";
import { CommunityInterest } from "../../components/CommunityInterest";
import RoundedImage from "../../components/RoundedImage";
import CopyToClipboard from "react-copy-to-clipboard";
import { Banner } from "../../components/Banner";
import { get } from "lodash";
import DefaultEventImage from "../../assets/images/Community.png";
import AuthContext from "../../context/AuthContext";

const CummunitySection = () => {
  const { community } = useContext(CommunityContext);
  const { authUser } = useContext(AuthContext);
  const image = community?.image_url;

  const speakers = community?.moderators;
  const navigate = useNavigate();
  const url = window.location.toString();
  const banners = get(community, "cover_media", []);

  const creator = speakers?.find((v) => v.id === community?.created_by);
  const shareMessage =
    community?.created_by === authUser?.id
      ? `hey! check out this community "${community.title}" I have created on cue club`
      : `Hey! check this community "${community.title}" by ${creator?.full_name} on cue club`;

  return (
    <React.Fragment>
      <Row className="mt-4">
        <Col lg={12}>
          <Card className="card-border overflow-hidden">
            <CardBody className="p-0">
              <Row className="align-items-center">
                <Col lg={8} md={7}>
                  <div className="p-3 p-lg-4">
                    <Row className="align-items-center">
                      <div className="col-auto">
                        <div className="pass-banner-img">
                          <Banner
                            banners={banners}
                            defaultImage={DefaultEventImage}
                          />
                        </div>
                      </div>
                      <div className="col-flex-1">
                        <div className="community-side-img-wrapper">
                          <RoundedImage
                            imageUrl={community.image_url}
                            title={community.title}
                            radius={80}
                            initial="dark"
                          />
                        </div>

                        {/* <CommunityInterest interest={community.interest} /> */}

                        <h4 className="home-title mb-0 mt-3 mt-lg-3 fw-normal">
                          {community?.title}
                        </h4>
                        <span className="member_count">
                          {community?.member_count} members
                        </span>
                      </div>
                    </Row>

                    {/* <div className="d-flex mt-3 mt-lg-4 align-items-center">
                      {speakers?.map((speaker, index) => (
                        <div
                          key={speaker.id}
                          className={`flex-shrink-0 ${
                            index > 0 ? "img-ml-4" : ""
                          }`}
                          onClick={() => navigate(`/${speaker.username}`)}
                        >
                          <RoundedImage
                            title={speaker.full_name}
                            imageUrl={speaker?.image_url}
                            isVerified={speaker?.is_verified}
                            imageClass="pass-user-img img-w-40"
                            radius={40}
                          />
                          {speaker?.is_verified ? (
                            <div className="verified-icon">
                              <img
                                src={badge}
                                className="img-fluid icon-sm"
                                alt=""
                              />
                            </div>
                          ) : null}
                        </div>
                      ))}

                      <div className="flex-grow-1 ms-4">
                        <p className="mb-0 home-desc">
                          {speakers?.map((speaker, index) => (
                            <span>
                              {index + 1 === speakers.length ? "And " : null}
                              {speaker.full_name}{" "}
                            </span>
                          ))}
                        </p>
                      </div>
                    </div> */}
                  </div>
                </Col>
                <Col lg={4} md={5} className="pass-effact">
                  <div className="p-2 p-lg-4">
                    <div className="d-flex justify-content-between  align-items-center">
                      <div className="text-center">
                        <img
                          src={emojiStar}
                          alt=""
                          className="img-fluid pass-star-img"
                        />
                      </div>
                      <ul className="list-inline social-img mb-0 mt-2 mt-lg-3">
                        <li className="list-inline-item">
                          <a
                            data-original-title={community.title}
                            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                            target="_blank"
                            title="Facebook"
                          >
                            <img src={ico_facebook} alt="" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href={`https://twitter.com/share?text=${shareMessage}&url=${url} + "&hashtags=cue"`}
                            target="_blank"
                            title="Twitter"
                          >
                            <img src={ico_twitter} alt="" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <CopyToClipboard
                            text={url}
                            role="button"
                            onCopy={() => alert("Link copied")}
                          >
                            <img src={chain} alt="" />
                          </CopyToClipboard>
                        </li>
                      </ul>
                    </div>

                    <div className="text-dark mt-3">
                      <h4 className="transaction-title mb-0">
                        transaction successful
                      </h4>

                      <h5 className="transaction-desc fw-normal mt-2">
                        you are now a member of this community, to join please
                        download the mobile app using the same number
                      </h5>

                      <div className="transaction-border my-3"></div>

                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <p className="transaction-amount mb-0">Paid amount</p>
                        <p className="transaction-amount mb-0">
                          {toCurrency(community?.price)}
                        </p>
                      </div>

                      {/* <ul className="list-inline social-img text-center mt-4 mb-0 d-block d-sm-none">
                        <li className="list-inline-item">
                          <Link to="#">
                            <img src={facebook} alt="" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#">
                            <img src={twitter} alt="" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#">
                            <img src={link} alt="" />
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CummunitySection;
