import React, { useCallback, useContext, useState } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import LoginModal from "../pages/ModelsPage/LoginModal";
import RetryPaymentModal from "../pages/ModelsPage/RetryPaymentModal";
import { AppModal } from "../shared/modal/app-modal-utils";
import { ModalClasses } from "../utils/app-utils";
import { isLogin } from "../utils/helper";
import { byCommunityMembership, doPayment } from "../utils/payment-utils";
import AuthContext from "./AuthContext";
import { EventContext } from "./EventContext";
import { useAlert } from "react-alert";
import { get } from "lodash";
import { getEventById } from "../api/event";
import { getCommunity } from "../api/communityApi";
import { AnalyticsConstant } from "../constant/AnalyticsConstant";
import clevertap from "clevertap-web-sdk";

export const RazorPayContext = React.createContext();

export const RazorPayProvider = ({ children }) => {
  const { event } = useContext(EventContext);
  const { authUser } = useContext(AuthContext);
  const RazorPay = useRazorpay();
  const navigate = useNavigate();

  const [isBusy, setBusy] = useState(false);

  const _alert = useAlert();

  const requestForPayment = useCallback(
    async (user, myEvent, isCommunity) => {
      const _event = myEvent ? myEvent : event;

      const pushclevertapEvent = (cevent) => {
        const clevertapData = {
          Id: `${
            isCommunity ? AnalyticsConstant.Community : AnalyticsConstant.Event
          }:${_event?.id}`,
          Name: _event?.title,
          Type: _event?.type,
          IsPaid: _event?.is_paid,
          IsMember: _event?.is_member,
          Price: _event?.price,
          Interest: JSON.stringify(_event?.interest),
          Language: _event?.language,
          CurrentUserIdentity: user?.id,
          CurrentUserName: user?.full_name,
        };

        clevertap.event.push(cevent, clevertapData);
      };

      pushclevertapEvent(
        isCommunity
          ? AnalyticsConstant.COMMUNITY_PAYMENT_INITIATED
          : AnalyticsConstant.EVENT_PAYMENT_INITIATED
      );

      // console.log("_event", _event);
      try {
        if (isCommunity) {
          await byCommunityMembership(
            RazorPay,
            user,
            _event.id,
            get(_event, "community_plan.0.id"),
            () => setBusy(false),
            () => setBusy(true),
            () => setBusy(false)
          );
          // _alert.success("Payment is Done.");
        } else {
          await doPayment(
            RazorPay,
            user,
            _event.id,
            () => setBusy(false),
            () => setBusy(true),
            () => setBusy(false)
          );
        }
        pushclevertapEvent(
          isCommunity
            ? AnalyticsConstant.COMMUNITY_PAYMENT_SUCCESSFUL
            : AnalyticsConstant.EVENT_PAYMENT_SUCCESSFUL
        );

        navigate(
          isCommunity
            ? `community/membership/${_event.id}`
            : `event/${_event.id}/pass`
        );
      } catch (error) {
        pushclevertapEvent(
          isCommunity
            ? AnalyticsConstant.COMMUNITY_PAYMENT_FAILED
            : AnalyticsConstant.EVENT_PAYMENT_FAILED
        );

        if (error.code === "payment_failed") {
          AppModal.open(
            RetryPaymentModal,
            {
              showFooter: false,
              showCloseBtn: false,
              className: ModalClasses,
              centered: true,
            },
            { user, event: _event, isCommunity }
          );
        } else if (error.code === "sold_out") {
          // alert(error.message);
          _alert.error(error.message);
        } else if (error.code === "fail_to_create_order") {
          // alert(error.message);
          _alert.error(error.message);
        } else {
          // alert(error.message);
        }
      }

      setBusy(false);
    },
    [RazorPay, event]
  );

  const makePayment = useCallback(
    async (e, myEvent) => {
      try {
        setBusy(true);
        e?.stopPropagation();
        if (isLogin()) {
          requestForPayment(authUser, myEvent);
          return false;
        }

        const userInfo = await AppModal.open(
          LoginModal,
          {
            showFooter: false,
            showCloseBtn: false,
            className: ModalClasses,
            centered: true,
          },
          { event: myEvent ? myEvent : event }
        );

        const response = await getEventById(myEvent?.id);

        if (response?.data?.has_paid) {
          _alert.success("you have already paid for this event.");
          setBusy(false);
          return;
        }
        // Requesting For Payment
        requestForPayment(userInfo, myEvent);
      } catch (error) {
        setBusy(false);
      }
    },
    [event, authUser]
  );

  const buyCommunity = useCallback(
    async (e, community) => {
      try {
        setBusy(true);
        e?.stopPropagation();
        if (isLogin()) {
          requestForPayment(authUser, community, true);
          return false;
        }

        const userInfo = await AppModal.open(
          LoginModal,
          {
            showFooter: false,
            showCloseBtn: false,
            className: ModalClasses,
            centered: true,
          },
          { event: community, isCommunity: true }
        );

        const response = await getCommunity(community?.id);
        if (response?.data?.is_member) {
          _alert.success("you are already a member for this community.");
          setBusy(false);
          return;
        }

        // Requesting For Payment
        requestForPayment(userInfo, community, true);
      } catch (error) {
        setBusy(false);
      }
    },
    [event, authUser]
  );
  return (
    <RazorPayContext.Provider value={{ makePayment, buyCommunity, isBusy }}>
      {children}
    </RazorPayContext.Provider>
  );
};
