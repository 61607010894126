import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MessageCardWithUpcomingEvent } from "./MessageCardWithUpcomingEvent";

const DeepLink = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showContent, setContent] = useState(false);

  useEffect(() => {
    const screenName = searchParams.get("screen_name");
    const username = searchParams.get("username");
    const login = searchParams.get("login");
    const showUpcomingEvent = searchParams.get("showUpcomingEvent");
    const title = searchParams.get("title");
    const id = searchParams.get("id");
    const _title = title ? title.replace(/[^a-zA-Z]/g, "-") : "---";

    if (screenName === "feed") {
      navigate(`/${username}/${id}/${_title}`);
    } else if (screenName === "profile") {
      navigate(`/${username}`);
    } else if (screenName === "community") {
      navigate(`/community/${id}/${_title}`);
    } else if (login) {
      setContent(true);
    } else if (showUpcomingEvent) {
      setContent(true);
    } else {
      window.location.href = "/p/";
    }
  }, []);

  return showContent ? (
    <MessageCardWithUpcomingEvent>
      <>
        <h1 className="home-main-title">
          hello! <br /> We hope you're enjoying <br /> your time on cue! 
        </h1>
        <p className="home-main-para">
          home to interesting conversations with <br /> creators and communities
          you admire.
        </p>
      </>
    </MessageCardWithUpcomingEvent>
  ) : null;
};

export default DeepLink;
