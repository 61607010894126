import React, { useEffect, useMemo, useState } from "react";
import { ModalBody } from "reactstrap";
import closeIcon from "../../assets/images/icon/close-btn.svg";
import searchIcon from "../../assets/images/icon/search.svg";
import { getCountryCode } from "../../api/commonApi";

const CountryCodeModal = ({ close }) => {
  const [search, setSearch] = useState("");
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fnc = async () => {
      const response = await getCountryCode();
      // console.log("response", JSON.parse(response.data));
      const _countries =
        typeof response.data === "string"
          ? JSON.parse(response.data)
          : response.data;

      setCountries(_countries);
    };

    fnc();
  }, []);

  const countryCodesList = useMemo(() => {
    if (!search) {
      return countries;
    } else {
      return countries.filter(
        (cc) =>
          cc.name.toLowerCase().includes(search.toLowerCase()) ||
          cc.dial_code.toLowerCase().includes(search.toLowerCase())
      );
    }
  }, [search, countries]);
  return (
    <ModalBody>
      <ModalBody>
        <div className="modal-search">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <input
                type="text"
                className="form-control modal-input form-control-lg"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img src={searchIcon} className="img-fluid search-icon" alt="" />
            </div>
            <div className="flex-shrink-0 ps-3">
              <span role="button" onClick={() => close("close")}>
                <img src={closeIcon} className="img-fluid" alt="" />
              </span>
            </div>
          </div>

          <div className="mt-4">
            <ul className="list-inline search-list mb-0">
              {countryCodesList.map((cc) => {
                return (
                  <li key={cc.code} onClick={() => close({ item: cc, searchText: search })}>
                    <span role="button" className="text-light">
                      {cc.dial_code} &nbsp; &nbsp; {cc.name}
                    </span>
                  </li>
                );
              })}
              {/* <li>
                <a to="#" className="text-light">
                  +91 &nbsp; &nbsp; India
                </a>
              </li>
              <li>
                <a to="#" className="text-light">
                  +91 &nbsp; &nbsp; India
                </a>
              </li>
              <li>
                <a to="#" className="text-light">
                  +91 &nbsp; &nbsp; India
                </a>
              </li>
              <li>
                <a to="#" className="text-light">
                  +91 &nbsp; &nbsp; India
                </a>
              </li>
              <li>
                <a to="#" className="text-light">
                  +91 &nbsp; &nbsp; India
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </ModalBody>
    </ModalBody>
  );
};

export default CountryCodeModal;
