import React from "react";
import Routes from "./Routes/index";
import {
  transitions,
  positions,
  types,
  Provider as AlertProvider,
} from "react-alert";

//import Custom Style scss
import "./assets/scss/themes.scss";
import AlertTemplate from "react-alert-template-basic";
import clevertap from "clevertap-web-sdk";


const options = {
  timeout: 5000,
  type: types.SUCCESS,
  position: positions.TOP_RIGHT,
  offset: "30px",
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 99999999999,
  },
};
//R5K-8WK-856Z
clevertap.init(
  "test" === process.env.REACT_APP_ENV ? "TEST-K5K-8WK-856Z" : "R5K-8WK-856Z"
);

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <React.Fragment>
        <Routes />
      </React.Fragment>
    </AlertProvider>
  );
}

export default App;
