import request from "../config/request";

export const requestToGetOtp = (mobile, countryCode) => {
  return request.post("/api:FJSLfV81/auth/request-otp", {
    mobile,
    country_code: countryCode,
  });
};

export const verifyOtp = (token, otp) => {
  return request.post("/api:FJSLfV81/auth/validate_otp", {
    token,
    otp,
  });
};

export const getUser = () => {
  return request.get("/api:FJSLfV81/auth/me");
};
