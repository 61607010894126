import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import About from "./About";
import ByPassSection from "./ByPassSection";
import Section from "./Section";

//import images
import { EventContext } from "../../context/EventContext";
import { displayDate, displayTime } from "../../utils/helper";
import { EventPageMetaTag } from "../../components/EventPageMetaTag";
import {
  BestSuitedForTag,
  InterestTags,
  LanguageTag,
} from "../../components/CommunityInterest";
import clevertap from "clevertap-web-sdk";
import { AnalyticsConstant } from "../../constant/AnalyticsConstant";
import AuthContext from "../../context/AuthContext";
import DownloadAppBanner from "../../components/DownloadAppBanner";
import OpenInApp from "../../components/OpenInApp";
import { AuthorContext } from "../../context/AuthorContext";

const EventPage = () => {
  const { event, setEvent } = useContext(EventContext);
  const { authUser, requesting } = useContext(AuthContext);
  const { author } = useContext(AuthorContext);
  useEffect(() => {
    return () => {
      setEvent(null);
    };
  }, []);

  useEffect(() => {
    if (event?.id && !requesting) {
      clevertap.event.push(AnalyticsConstant.EVENT_DETAIL_VIEWED, {
        Screen: "Event_Detail",
        Entity: AnalyticsConstant.Event,
        Id: `${AnalyticsConstant.Event}:${event?.id}`,
        Name: event?.title,
        Type: event?.type,
        IsPaid: event?.is_paid,
        IsMember: event?.is_member,
        Price: event?.price,
        CurrentUserIdentity: authUser?.id,
        CurrentUserName: authUser?.full_name,
        Interest: event?.interest ? JSON.stringify(event?.interest) : "",
      });
    }
  }, [
    event?.id,
    event?.title,
    event?.type,
    event?.is_paid,
    event?.price,
    authUser?.id,
    authUser?.full_name,
    requesting,
  ]);

  console.log("authUser", authUser);

  return (
    <React.Fragment>
      <DownloadAppBanner />
      <EventPageMetaTag />
      <Navbar
        isLogin={true}
        OpenInAppComponent={
          event?.id && author?.username ? (
            <OpenInApp
              screenName="feed"
              title={event?.title}
              userName={author?.username}
              id={event?.id}
            />
          ) : null
        }
      />

      <section className="home-contain pb-5">
        <Container>
          <Row>
            <Col lg={8} md={7}>
              <div className="banner-box">
                <Section />
                <div className="mt-4">
                  <Link to="#" className="time-tag mb-0">
                    {displayDate(event?.start_at)} &nbsp; &nbsp; l &nbsp; &nbsp;{" "}
                    {displayTime(event?.start_at)}
                  </Link>
                </div>

                <h4 className="home-title mb-0 mt-3 mt-md-4 fw-normal text-lowercase">
                  {event?.title}
                </h4>
                {/* <CommunityInterest interest={event?.interest} /> */}
                {/* <BestSuitedFor
                  bestSuitedFor={event?.best_suited_for}
                  className="mt-3 mt-md-4"
                  childClassName="badge bg-light me-3"
                /> */}

                <div className="mt-3 mt-md-4">
                  <InterestTags
                    childClassName="badge bg-light me-3 mb-1"
                    interests={event?.interest}
                  />
                  <BestSuitedForTag
                    childClassName="badge bg-light me-3 mb-1"
                    bestSuitedFor={event?.best_suited_for}
                  />
                  <LanguageTag
                    childClassName="badge bg-light me-3 mb-1"
                    language={event?.language}
                  />
                </div>
                <About />
              </div>
            </Col>
            <ByPassSection />
          </Row>
        </Container>
      </section>
      <Footer showFooterBottomBar={true} />
    </React.Fragment>
  );
};

export default EventPage;
