import React from "react";
import { useContext } from "react";
import { RazorPayContext } from "../context/RazorPayContext";
import { FullPageLoader } from "./FullPageLoader";

export const PaymentFullPageLoader = () => {
  const { isBusy } = useContext(RazorPayContext);

  console.log("isBusyisBusyisBusy", isBusy);
  return isBusy ? <FullPageLoader /> : null;
};
