import React from "react";
import ReactDOM from "react-dom/client";
// import "./assets/static/css/cue-creator.webflow.css";
// import "./assets/static/css/custom.css";
// import "./assets/static/css/normalize.css";
// import "./assets/static/css/webflow.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppListConfig } from "./shared/appList";
import request from "./config/request";

AppListConfig.merge({
  axios: request,
  dataKey: "items",
  perPageSize: 25,
  pageKeyName: "external[page]",
  infoKey: "nextPage",
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
