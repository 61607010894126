import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

//import images
import mobileApp from "../../assets/images/mobile-img.png";
import googleplay from "../../assets/images/googleplay.png";
import appstore from "../../assets/images/appstore.png";

const Download = () => {
  return (
    <React.Fragment>
      <div className=" download-section">
        <p className="home-heading text-lowercase">download cue app</p>
        <Card>
          <CardBody>
            <Row className="download-app-row">
              <Col >
                <h3>home to good conversations</h3>
                <p className="home-desc text-muted mb-0 parse">
                  pass will be avaiable on cue app. login app using the same
                  number and find cue under events. happy cueing!
                </p>
              </Col>
              <Col >
                <div className="mt-3">
                  <a
                    href="https://cueclub.onelink.me/OpLb/jr1iz0s5"
                    target="_blank"
                    className="me-0 me-lg-2"
                  >
                    <img
                      src={googleplay}
                      className="img-fluid mb-2 mb-sm-0"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://cueclub.onelink.me/OpLb/jr1iz0s5"
                    target="_blank"
                  >
                    <img
                      src={appstore}
                      className="img-fluid mb-2 mb-sm-0"
                      alt=""
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      {/* <section className="download-section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={11}>
              <Card className="download-box">
                <CardBody>
                  <Row>
                    <Col lg={5} className="order-2 order-lg-1">
                      <div className="download-img">
                        <img
                          src={mobileApp}
                          className="img-fluid"
                          alt=""
                          style={{ maxHeight: "708px" }}
                        />
                      </div>
                    </Col>
                    <Col lg={6} className="order-1">
                      <div className="my-0 py-0 my-lg-5 py-lg-3 text-sm-start text-center">
                        <h2 className="download-title fw-normal">
                          download cue app! home to interesting conversations
                          with creators and communities
                        </h2>
                        <p className="download-desc mt-3">
                          Pass will be avaiable on cue app. Login app using the
                          same number and find cue under calendar. happy cueing!
                        </p>

                        <div className="mt-4 pt-3">
                          <a
                            href="https://cueclub.onelink.me/OpLb/jr1iz0s5"
                            target="_blank"
                            className="me-0 me-lg-2"
                          >
                            <img
                              src={googleplay}
                              className="img-fluid mb-2 mb-sm-0"
                              alt=""
                            />
                          </a>
                          <a
                            href="https://cueclub.onelink.me/OpLb/jr1iz0s5"
                            target="_blank"
                          >
                            <img
                              src={appstore}
                              className="img-fluid mb-2 mb-sm-0"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section> */}
    </React.Fragment>
  );
};

export default Download;
