import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

// import images
import closeIcon from "../../assets/images/icon/close-btn.svg";
import alert from "../../assets/images/icon/alert.svg";
import { displayDate, displayTime } from "../../utils/helper";
import { toCurrency } from "../../utils/app-utils";
import { RazorPayContext } from "../../context/RazorPayContext";
import { EventContext } from "../../context/EventContext";
import clevertap from "clevertap-web-sdk";
import { AnalyticsConstant } from "../../constant/CleverTapActionConstant";

const RetryPaymentModal = ({ close, event, isCommunity, user }) => {
  const { makePayment, buyCommunity } = useContext(RazorPayContext);

  const retryPaymentEvent = () => {
    clevertap.event.push(
      isCommunity
        ? AnalyticsConstant.COMMUNITY_PAYMENT_RETRY
        : AnalyticsConstant.EVENT_PAYMENT_RETRY,
      {
        Id: `${
          isCommunity ? AnalyticsConstant.community : AnalyticsConstant.Event
        }:${event?.id}`,
        Name: event?.title,
        Type: event?.type,
        IsPaid: event?.is_paid,
        IsMember: event?.is_member,
        Price: event?.price,
        Interest: event?.interest,
        Language: event?.language,
        CurrentUserIdentity: user?.id,
        CurrentUserName: user?.full_name,
      }
    );
  };

  return (
    <ModalBody>
      <span role="button" className="float-end" onClick={() => close("close")}>
        <img src={closeIcon} className="img-fluid" alt="" />
      </span>

      <div className="text-center px-4">
        <div className=" alert-icon-top">
          <img src={alert} className="img-fluid" alt="" />
        </div>

        <div className="px-4 pt-3 mt-4">
          <p className="mb-2 transaction-heading">transaction unsuccessful</p>
          <p className="text-muted transaction-desc">
            any amount deducted will be refunded in 7-10 working days
          </p>
        </div>
      </div>

      <div className="trans-unsucce-bg text-dark mt-3">
        <div className="p-3 p-md-4">
          {!isCommunity ? (
            <div>
              <Link to="#" className="time-tag mb-0 fw-300">
                {displayDate(event?.start_at)} &nbsp; l &nbsp;{" "}
                {displayTime(event?.start_at)}
              </Link>
            </div>
          ) : null}
          <p className="transaction-title my-2 my-md-3">{event.title}</p>

          <div className="transaction-border my-2 my-md-3"></div>

          <div className="d-flex justify-content-between align-items-center mt-2 mt-md-3 mb-5">
            <p className="transaction-amount mb-0">price</p>
            <p className="transaction-amount mb-0">{toCurrency(event.price)}</p>
          </div>
        </div>
      </div>

      <div className="mt-1 text-center">
        <button
          onClick={(e) => {
            close("success");
            retryPaymentEvent();
            isCommunity ? buyCommunity(e, event) : makePayment(e, event);
          }}
          type="button"
          className="btn btn-custom btn-lg btn-rounded"
        >
          retry payment
        </button>
      </div>
    </ModalBody>
  );
};

export default RetryPaymentModal;
