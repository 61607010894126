import request from "../config/request";
import { isLogin } from "../utils/helper";

export const getEventById = (id) => {
  const apiEndPoint = `api:${isLogin() ? "PPo7qDNa" : "FzfbHYCg"}/event/${id}`;
  return request.get(apiEndPoint);
};

export const getUserEvents = (userId) => {
  request.get(`/api:FzfbHYCg/user/event/`, {
    params: { user_id: userId },
  });
};

export const getEventCalendar = () => {
  return request.get("api:_dz3UmOR/user-calendar");
};

export const addListener = (eventId, userId) => {
  return request.post("/api:2RHZbDLH/event/add_listener", {
    event_id: eventId,
    user_id: userId,
  });
};
